<template>
  <MainLayout :title="$t('166')" back-to="/" :has-notification-icon="false">
    <template #header-mobile-right>
      <div>
        <b-dropdown v-if="unread" position="is-bottom-left" aria-role="menu">
          <b-button slot="trigger" size="is-small" class="has-margin-left" role="button">
            <span class="has-text-small">{{ $t('168') }}</span>
          </b-button>
          <b-dropdown-item aria-role="menuitem" @click="readAll">{{ $t('170') }}</b-dropdown-item>
        </b-dropdown>
      </div>
    </template>
    <template v-slot:content>
      <div v-if="!loading && notifications">
        <p class="has-text-centered is-meta has-margin-bottom">
          {{ notifications.length }}
          {{ notifications.length > 1 ? $t('166').toLowerCase() : $t('167').toLowerCase() }}
        </p>
        <div v-if="unread">
          <p class="has-text-centered has-margin-bottom has-text-weight-bold has-text-primary">
            {{ unread }} {{ $t('169') }}{{ unread > 1 ? 's' : '' }}
          </p>
          <p class="has-margin has-text-centered is-hidden-mobile">
            <a class="has-text-small" @click.prevent="readAll">{{ $t('170') }}</a>
          </p>
        </div>
        <ListNotifications
          v-if="notifications.length"
          :list="notifications"
          @is-ready="loading = false"
          @reload="getNotifications"
        />
        <div v-else class="has-margin-top is-meta has-text-centered">{{ $t('173') }}.</div>
      </div>
      <ContentLoader v-if="loading" type="list" class="has-margin-top-large" />
    </template>
  </MainLayout>
</template>

<script>
import MainLayout from '@/components/MainLayout'
import ContentLoader from '@/components/utilities/ContentLoader'
import BaseService from '@/services/BaseService'
import ListNotifications from '@/components/lists/ListNotifications'

export default {
  components: {
    MainLayout,
    ListNotifications,
    ContentLoader,
  },
  data() {
    return {
      loading: false,
      notifications: [],
      unread: Number(this.$store.state.notifications.unread),
    }
  },

  mounted() {
    this.getNotifications()
  },

  methods: {
    async getNotifications() {
      this.loading = true

      const entries = await BaseService.getAllNotifications()
      this.notifications = Object.freeze(entries.notificacoes)

      const unread = entries.notificacoes.filter(item => {
        return item.lida === false
      })

      // Aproveita a chamada e garante que o state é atualizado
      this.$store.state.notifications.loaded = true
      this.$store.state.notifications.unread = unread.length

      this.unread = unread.length

      this.loading = false
    },
    async readAll() {
      await BaseService.markAllNotificationsAsRead()
      this.getNotifications()
    },
  },
}
</script>
