import Vue from 'vue'
import capitalize from 'lodash/capitalize'
import numeral from 'numeral'
import dayjs from 'dayjs'

// Para usar nos métodos:
//   this.$options.filters.

Vue.filter('currency', value => {
  let val = (value / 1).toFixed(2).replace('.', ',')
  return (
    val
      .toString()
      .toLocaleString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, '.') + ' €'
  )
})

Vue.filter('breakIfHasBrTag', function(value) {
  return value.replace(/<br\s*[/]?>/gi, ' / ')
})

Vue.filter('formatNumber', function(value) {
  return numeral(value).format('0.0 b')
})

Vue.filter('formatPercentage', function(value) {
  return numeral(value).format('0')
})

Vue.filter('formatDate', function(value) {
  if (value) {
    return dayjs(String(value)).format('DD/MM/YYYY')
  }
})

Vue.filter('formatDateTime', function(value) {
  if (value) {
    return dayjs(String(value)).format('DD/MM/YYYY H:mm')
  }
})

Vue.filter('shortDateDaysLeft', function(deadline) {
  if (deadline) {
    const dayjsToday = dayjs()
    const dayjsDeadline = dayjs(deadline)
    return dayjsDeadline.diff(dayjsToday, 'days')
  }
})

Vue.filter('shortDateHoursLeft', function(deadline) {
  if (deadline) {
    const dayjsToday = dayjs()
    const dayjsDeadline = dayjs(deadline)
    return dayjsDeadline.diff(dayjsToday, 'hours')
  }
})

Vue.filter('formatDeadline', function(deadline) {
  if (deadline) {
    const dayjsToday = dayjs()
    const dayjsDeadline = dayjs(deadline + ' 23:59:59')

    if (dayjsToday > dayjsDeadline) {
      return deadline
    }

    const daysLeft = dayjsDeadline.diff(dayjsToday, 'days')

    // Hoje
    if (dayjsToday == dayjsDeadline) {
      return this.$t('188')
    } else if (daysLeft == 1) {
      return this.$t('189')
    } else {
      return this.$t('190') + ' ' + daysLeft + ' ' + this.$t('191')
    }
  }
})

Vue.filter('capitalize', function(value) {
  if (value) {
    return capitalize(String(value))
  }
})

Vue.filter('getExtension', function(value) {
  var regex = /(?:\.([^.]+))?$/
  return regex.exec(value)[1]
})
