<template>
  <MainLayout :title="$t('82') + ' #' + $route.params.key" back-to="/">
    <template v-slot:content>
      <div v-if="!loading && activity">
        <p class="has-text-centered is-meta">
          {{ activity.length }}
          {{ activity.length > 1 ? $t('83').toLowerCase() : $t('84').toLowerCase() }}
        </p>
        <ListCredentialDetails v-if="activity.length" :list="activity" />
        <div v-else class="has-margin-top is-meta has-text-centered">{{ $t('86') }}.</div>
      </div>
      <ContentLoader v-if="loading" type="list" class="has-margin-top-large" />
    </template>
  </MainLayout>
</template>

<script>
import MainLayout from '@/components/MainLayout'
import ContentLoader from '@/components/utilities/ContentLoader'
import BaseService from '@/services/BaseService'
import ListCredentialDetails from '@/components/lists/ListCredentialDetails'

export default {
  components: {
    MainLayout,
    ListCredentialDetails,
    ContentLoader,
  },
  data() {
    return {
      loading: false,
      activity: [],
    }
  },

  mounted() {
    this.getCredentialDetails()
  },

  methods: {
    async getCredentialDetails() {
      this.loading = true
      const data = await BaseService.getCredentialDetails(this.$route.params.key)
      this.activity = Object.freeze(data.utilizacoes)
      this.loading = false
    },
  },
}
</script>
