<template>
  <div v-if="alreadyLoaded" class="notification-icon">
    <a href="/notifications" class="is-flex is-flex-align-center" @click.prevent="$router.push('/notifications')">
      <faicon icon="bell"></faicon>
      <span v-if="unreadNotifications" class="notification-badge">{{ unreadNotifications }}</span>
    </a>
  </div>
</template>

<script>
export default {
  computed: {
    unreadNotifications() {
      return this.$store.state.notifications.unread
    },
    alreadyLoaded() {
      return this.$store.state.notifications.loaded
    },
  },
}
</script>
