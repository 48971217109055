<template>
  <MainLayout :title="$t('255')" back-to="/buy">
    <template v-slot:breadcrumb>
      <Breadcrumb :items="breadcrumb" />
    </template>
    <template v-slot:content>
      <hr class="with-arrow to-up" />
      <div class="has-text-centered has-text-small">{{ $t('280') }}</div>
      <div v-if="!loading && entries" class="has-margin-top-medium">
        <ListProducts
          v-if="entries.length"
          :list="entries"
          :uso-social="usoSocial"
          @set-modal-content="setModalContent"
        />
        <div v-else class="has-margin-top-large is-meta has-text-centered">{{ $t('256') }}.</div>
      </div>
      <ContentLoader v-if="loading" type="list" class="has-margin-top" />
    </template>
    <template v-slot:modals>
      <ModalAddToCart
        :show.sync="isAddToCartModalActive"
        :product="modalProduct"
        :qt-max-ref="qtMaxRef"
        :uso-social="usoSocial"
        @close="isAddToCartModalActive = false"
      />
    </template>
  </MainLayout>
</template>

<script>
import MainLayout from '@/components/MainLayout'
import Breadcrumb from '@/components/utilities/Breadcrumb'
import ContentLoader from '@/components/utilities/ContentLoader'
import BaseService from '@/services/BaseService'
import ListProducts from '@/components/lists/ListProducts'
import ModalAddToCart from '@/components/modals/ModalAddToCart'

export default {
  components: {
    MainLayout,
    Breadcrumb,
    ContentLoader,
    ListProducts,
    ModalAddToCart,
  },
  data() {
    return {
      loading: false,
      entries: [],
      qtMaxRef: null,
      usoSocial: null,
      breadcrumb: this.$store.getters.getBreadcrumb,
      modalProduct: null,
      isAddToCartModalActive: false,
    }
  },
  created() {
    if (!this.breadcrumb.length) {
      this.$router.push('/buy')
    }
  },
  mounted() {
    if (this.$route.query.location_id && this.$route.query.meal_id) {
      this.getEntries(this.$route.query.location_id, this.$route.query.meal_id)
    }
  },
  methods: {
    async getEntries(locationId, mealId) {
      this.loading = true
      const result = await BaseService.getBuyProducts(locationId, mealId)
      this.entries = Object.freeze(result.dados)
      this.qtMaxRef = Number(result.qt_max_ref)
      this.usoSocial = result.uso_social
      this.loading = false
    },
    setModalContent(item) {
      this.isAddToCartModalActive = true
      this.modalProduct = item
    },
  },
}
</script>
