import { SnackbarProgrammatic as Snackbar } from 'buefy'

import AuthService from '@/services/AuthService'
import router from '@/router'
import localAccounts from '@/utilities/localAccounts'

export default {
  // Define o idioma localmente
  setLanguage({ commit }, language) {
    localStorage.setItem('settings.language', language)
    commit('setLanguage', language)
  },

  // Login
  login({ commit }, { email, password }) {
    return new Promise((resolve, reject) => {
      AuthService.login(email, password)
        .then(response => {
          if (!response) {
            return
          }
          commit('setUser', {
            email: email,
            password: password,
          })
          resolve(response)
        })
        .catch(err => {
          reject(err)
        })
    })
  },

  // Login
  loginWithPinToken({ commit }, { pin, token }) {
    return new Promise((resolve, reject) => {
      AuthService.loginWithToken(pin, token)
        .then(response => {
          if (!response) {
            return
          }
          commit('setUserEmail', localStorage.getItem('session.email'))
          resolve(response)
        })
        .catch(err => {
          reject(err)
        })
    })
  },

  // Set Pin (obtém token)
  loginSetPin({ commit }, pin) {
    return new Promise((resolve, reject) => {
      AuthService.loginSetPin(this.state.session.email, this.state.session.password, pin)
        .then(response => {
          if (!response) {
            return
          }

          if (response.data && response.data.token) {
            localStorage.setItem('session.pinToken', response.data.token)
            localStorage.setItem('session.email', this.state.session.email)

            localAccounts.updateLocalAccounts({
              email: this.state.session.email,
              pinToken: response.data.token,
            })

            commit('setUserEmail', this.state.session.email)
            commit('setPinToken', response.data.token)
            commit('clearLoginUserData') // Limpa dados que não devem ficar na sessão
            resolve(response)
          } else {
            // console.log('No token in the server response')
            reject('No token')
          }
        })
        .catch(err => {
          reject(err)
        })
    })
  },

  clearStateLogin({ commit }) {
    commit('clearStateLogin')
  },

  // Logout
  remoteLogout() {
    return new Promise(async resolve => {
      try {
        await AuthService.logout()
        resolve('ok')
      } catch (e) {
        // console.error(e)
        resolve('error')
      }
    })
  },

  async logout({ commit }) {
    commit('logout')
    document.cookie = 'auth_tkt_sas=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;'
  },

  // Logout
  async fullLogout({ commit }) {
    commit('logout')
    localStorage.removeItem('session.pinToken')
    localStorage.removeItem('session.email')
    //ADDED:
    localStorage.removeItem('session.token')
    document.cookie = 'auth_tkt_sas=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;'
  },

  // Guarda o crédito em carteira
  setCredit({ commit }, credit) {
    commit('setCredit', credit)
  },

  setUserInfo({ commit }, userInfo) {
    commit('setUserInfo', userInfo)
  },

  setUserSummary({ commit }, userSummary) {
    commit('setUserSummary', userSummary)
  },

  // Mantem o estado da APi controlado,
  // para apresentar mensagem de erro, caso necessário
  setApiStatus({ commit }, isActive) {
    // Valida de se o estado da ligação é o mesmo que registado no store
    if (this.state.api.isActive != isActive) {
      commit('setApiStatus', isActive)

      // Confirma que o utilizador ainda não foi notificado
      if (!isActive) {
        Snackbar.open({
          message: this.$t('183'),
          type: 'is-warning',
          position: 'is-top-right',
          actionText: this.$t(229),
          indefinite: true,
          onAction: () => {
            router.go()
          },
        })
      }
    }
  },

  clearBreadcrumb({ commit }) {
    commit('clearBreadcrumb')
  },

  addBreadcrumbItem({ commit }, item) {
    commit('addBreadcrumbItem', item)
  },

  removeBreadcrumbItem({ commit }, position) {
    commit('removeBreadcrumbItem', position)
  },

  clearCart({ commit }) {
    commit('clearCart')

    localStorage.removeItem('cart')
    localStorage.removeItem('cartDate')
  },

  addCartItem({ commit }, item) {
    //remove donation
    localStorage.removeItem('donation')
    localStorage.removeItem('donation_value')

    commit('addCartItem', item)

    localStorage.setItem('cart', JSON.stringify(this.state.cart))
    localStorage.setItem('cartDate', this.state.cartDate)
  },

  removeCartItem({ commit }, item) {
    commit('removeCartItem', item)

    localStorage.setItem('cart', JSON.stringify(this.state.cart))
  },

  setCartSocialItem({ commit }) {
    commit('setCartSocialItem')

    localStorage.setItem('cart', JSON.stringify(this.state.cart))
  },
}
