<template>
  <div>
    <RecordTicket v-for="item in list" :key="item.id_doc" :item="item" @reload="reload" />
  </div>
</template>

<script>
import RecordTicket from '@/components/records/RecordTicket'

export default {
  components: {
    RecordTicket,
  },
  props: {
    list: Array,
  },
  methods: {
    reload() {
      this.$emit('reload')
    },
  },
}
</script>
