<template>
  <BasicLayout :title="$t('30')" :has-back-to="false">
    <template slot="content">
      <h1 class="page-title">{{ $t('30') }}</h1>

      <div v-if="!error && account" class="has-text-centered has-margin-bottom">
        <div v-if="accounts.length > 1">
          <b-dropdown v-model="account" aria-role="list">
            <button slot="trigger" class="button is-text" type="button">
              <template v-if="account">
                <b-icon icon="user-circle"></b-icon>
                <span
                  ><v-clamp autoresize :max-lines="1">{{ account.email }}</v-clamp></span
                >
              </template>
              <b-icon icon="chevron-down" size="is-small"></b-icon>
            </button>

            <b-dropdown-item
              v-for="localAccount in accounts"
              :key="localAccount.email"
              :value="localAccount"
              aria-role="listitem"
            >
              <div class="media">
                <div class="media-content">
                  <h3>
                    <v-clamp autoresize :max-lines="1">{{ localAccount.email }}</v-clamp>
                  </h3>
                </div>
              </div>
            </b-dropdown-item>
          </b-dropdown>
        </div>
        <div v-else class="button is-text is-flex">
          <b-icon icon="user-circle"></b-icon>
          <span class="has-margin-left-tiny">{{ account.email }}</span>
        </div>
      </div>

      <!-- Errors -->
      <div v-if="error" class="width-small has-text-centered notification is-black has-text-warning has-padding-small">
        <div v-if="error == $t('14')">
          {{ $t('8') }}<br />
          <small>{{ $t('16') }}</small>
        </div>
        <div v-else>
          {{ error }}
        </div>
      </div>

      <PinPanel
        :reset.sync="resetPinPanel"
        @update="setPin"
        @cancel="cancel"
        @clear="clearPin"
        @key-pressed="keyPressed"
      />
    </template>
  </BasicLayout>
</template>

<script>
import BasicLayout from '@/components/BasicLayout'
import PinPanel from '@/components/utilities/PinPanel'

import localAccounts from '@/utilities/localAccounts'

export default {
  components: {
    BasicLayout,
    PinPanel,
  },
  data() {
    return {
      pin: '',
      pinInvalid: false,
      resetPinPanel: false,
      error: false,
      clearErrorAfter: false,
      account: localAccounts.getLocalAccounts()[0] || this.$store.getters.getLocalSession,
      accounts: localAccounts.getLocalAccounts(),
    }
  },
  methods: {
    clearPin() {
      this.pin = ''
      this.error = false
      this.resetPinPanel = true
    },
    cancel() {
      //change behaviour of cancel button, if the pin is invalid (forcing the user to enter with password)
      //always clear the pin
      if (this.account && this.account.email) {
        //console.log('forget local account')
        localAccounts.forgetLocalAccount(this.account.email)
      }
      this.doLogout()
    },
    keyPressed() {
      if (this.clearErrorAfter) {
        this.error = false
        this.clearErrorAfter = false
      }
    },
    doLogout() {
      //console.log('Doing full logout')
      this.$store.dispatch('fullLogout').then(() => {
        this.$store.dispatch('clearCart')
        this.$store.dispatch('clearBreadcrumb')
        this.$router.push('/login')
      })
    },
    setPin(pin) {
      if (!this.account.pinToken) {
        //console.log('account without pin token')
        this.$router.push('/login')
      } else {
        //console.log('login by token 2')
        this.$store
          .dispatch('loginWithPinToken', {
            pin: pin,
            token: this.account.pinToken,
          })
          .then(response => {
            // console.log('response ok', response)
            // Valida se é necessário obrigar a alterar a password
            if (response.data.mustChangePWD && response.data.mustChangePWD == 'T') {
              //console.log('changePWD')
              //Say to user he needs to change password
              this.$buefy.dialog.alert({
                indefinite: true,
                message: this.$t('291'),
                type: 'is-black',
                position: 'is-bottom-left',
                actionText: 'OK',
                queue: false,
              })
              this.doLogout()
            } else if (response.data.result && response.data.result.toLowerCase() === 'ok') {
              // console.log('ok')
              this.$router.push('/')

              // Atualiza o token do utilizador pelo novo valor
              if (response.data.upd) {
                this.account.pinToken = response.data.upd
                localStorage.setItem('session.pinToken', response.data.upd)
                this.$store.dispatch('setPinToken', response.data.upd)
                //Update session token in local accounts
                localAccounts.updateTokenLocalAccount(this.account.email, response.data.upd)
              }

              // Garante que a última conta é guardada na primeira posição da lista de contas locais
              localAccounts.updateLocalAccounts(this.account)

              // Make sure to clear cart
              this.$store.dispatch('clearCart')
              this.$store.dispatch('clearBreadcrumb')
            } else if (response.data.reset && (response.data.reset === true || response.data.reset === 'T')) {
              // console.log('reset pin')
              //Say to user he needs to change pin
              this.$buefy.dialog.alert({
                indefinite: true,
                message: this.$t('290'),
                type: 'is-black',
                position: 'is-bottom-left',
                actionText: 'OK',
                queue: false,
              })

              //Fazer reset ao pin
              if (this.account && this.account.email) {
                localAccounts.forgetLocalAccount(this.account.email)
              }

              //Logout user
              this.doLogout()
            } else {
              // console.log('ivalid pin')
              //Invalid PIN code
              this.$buefy.dialog.alert({
                indefinite: true,
                message: this.$t('289'),
                type: 'is-black',
                position: 'is-bottom-left',
                actionText: 'OK',
                queue: false,
              })
              this.pinInvalid = true
            }
          })
          .catch(err => {
            // console.log('error', err)
            this.error = err
            this.clearErrorAfter = true
          })
      }
    },
  },
}
</script>
