<template>
  <MainLayout :title="$t('145')" back-to="/account" :has-notification-icon="false">
    <template v-if="userInfo" v-slot:content>
      <form @submit.prevent="doSave" @keyup.enter="doSave">
        <h3 class="has-margin-top-large has-margin-bottom is-size-3 has-text-weight-bold">
          {{ $t('143') }}
        </h3>

        <b-field label="Email" class="is-size-2 has-margin-top">
          <span class="is-size-4">{{ userInfo.e_mail[0] }}</span>
        </b-field>

        <h3 class="has-margin-top-large has-margin-bottom is-size-3 has-text-weight-bold">
          {{ $t('145') }}
        </h3>

        <b-field :label="$t('153')">
          <b-input v-model="userInfo.nickname" size="is-medium" type="text" name="name" />
        </b-field>

        <b-field :label="$t('279')">
          <span class="has-padding">{{ userInfo.nr_aluno }}</span>
        </b-field>

        <b-field :label="$t('162')">
          <b-datepicker v-model="userInfo.data_nasc" :placeholder="$t('180')" name="data_nasc" trap-focus>
          </b-datepicker>
        </b-field>

        <b-field :label="$t('159')">
          <CountriesSelect :input="userInfo.nacionalidade" @update="updateNationality" />
        </b-field>

        <b-field :label="$t('154')">
          <span class="has-padding">{{ userInfo.nif }}</span>
        </b-field>

        <b-field :label="$t('155')">
          <span class="has-padding">{{ userInfo.pais_nif }}</span>
        </b-field>

        <h3 class="has-margin-top-large has-margin-bottom is-size-3 has-text-weight-bold">
          {{ $t('146') }}
        </h3>

        <b-field
          :label="$t('156')"
          :type="{ 'is-danger': $v.userInfo.morada.$error }"
          :message="$v.userInfo.morada.$error ? $t('44') : ''"
        >
          <b-input v-model="userInfo.morada" type="text" name="morada" />
        </b-field>

        <b-field grouped class="is-marginless">
          <b-field :label="$t('157')">
            <b-input
              v-model="userInfo.cod_postal"
              size="is-width-small"
              maxlength="8"
              type="text"
              placeholder="0000-000"
              name="cod_postal"
            />
          </b-field>
          <b-field :label="$t('158')" expanded>
            <b-input v-model="userInfo.localidade" type="text" name="localidade" />
          </b-field>
        </b-field>

        <b-field grouped>
          <b-field>
            + 351
          </b-field>
          <b-field :label="$t('160')" expanded>
            <b-input v-model="userInfo.contacto" type="number" maxlength="25" name="contacto" />
          </b-field>
        </b-field>

        <b-field class="has-margin-bottom has-margin-top-large">
          <b-button expanded type="is-primary" :loading="loading" @click="doSave">{{ $t('176') }}</b-button>
        </b-field>
      </form>
    </template>
  </MainLayout>
</template>

<script>
import { required, minLength } from 'vuelidate/lib/validators'

import MainLayout from '@/components/MainLayout'
import BaseService from '@/services/BaseService'
import CountriesSelect from '@/components/utilities/CountriesSelect'

export default {
  components: {
    CountriesSelect,
    MainLayout,
  },
  data() {
    return {
      loadingSave: false,
      isUploadingPhoto: false,
      loading: false,
      photo: null,
    }
  },

  computed: {
    userInfo() {
      return this.$store.getters.getUserInfo
    },
  },

  validations: {
    userInfo: {
      morada: {
        required,
        min: minLength(2),
      },
      cod_postal: {
        required,
        min: minLength(4),
      },
      localidade: {
        required,
        min: minLength(4),
      },
    },
  },

  methods: {
    updateNationality(country) {
      this.userInfo.nacionalidade = country
    },

    async getUserInfo() {
      this.loading = true
      const userInfo = await BaseService.getUserInfo()
      this.$store.dispatch('setUserInfo', userInfo)
      this.loading = false
    },

    async doSave() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.loadingSave = true
        BaseService.updateUserInfo(this.userInfo)
          .then(() => {
            this.$buefy.snackbar.open({
              message: this.$t('161'),
              type: 'is-primary',
              position: 'is-bottom-right',
              duration: 5000,
            })
            this.loadingSave = false
          })
          .catch(err => {
            console.log(err)
            this.loadingSave = false
          })
      }
    },
  },
}
</script>
