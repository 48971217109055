<template>
  <MainLayout :title="$t('103')" back-to="/recharge">
    <template v-slot:content>
      <p class="has-text-centered has-margin-top-large is-meta">{{ $t('129') }}</p>

      <div class="columns is-mobile is-multiline">
        <!-- <div class="column is-half-mobile is-one-quarter-desktop">
          <a
            class="is-flex is-flex-justify-center has-text-centered flex-column"
            @click="isSantanderModalActive = true"
          >
            <span class="page-icon is-large has-margin-bottom-small">
              <span class="icon">
                <icon name="icon-santander"></icon>
              </span>
            </span>
            <span>{{ $t('125') }}</span>
          </a>
        </div> -->
        <div class="column is-half-mobile is-one-third-desktop">
          <a
            href="/recharge/mbway"
            class="is-flex is-flex-justify-center flex-column has-text-centered"
            @click.stop.prevent="$router.push('/recharge/mbway')"
          >
            <span class="page-icon is-large has-margin-bottom-small">
              <span class="icon">
                <icon name="icon-mbway-o"></icon>
              </span>
            </span>
            <span>MBWay</span>
          </a>
        </div>
        <div class="column is-half-mobile is-one-third-desktop">
          <a
            href="/recharge/mb"
            class="is-flex is-flex-justify-center flex-column has-text-centered"
            @click.stop.prevent="$router.push('/recharge/mb')"
          >
            <span class="page-icon is-medium has-margin-bottom-small">
              <span class="icon">
                <icon name="icon-mb-o"></icon>
              </span>
            </span>
            <span>Multibanco</span>
          </a>
        </div>
        <div v-if="false" class="column is-half-mobile is-one-third-desktop">
          <a
            href="/recharge/cc"
            class="is-flex is-flex-justify-center flex-column has-text-centered"
            @click.stop.prevent="$router.push('/recharge/cc')"
          >
            <span class="page-icon is-medium has-margin-bottom-small">
              <span class="icon">
                <faicon icon="credit-card"></faicon>
              </span>
            </span>
            <span>{{ $t('124') }}</span>
          </a>
          <div class="is-flex is-flex-align-center is-flex-justify-center has-margin-top-small">
            <img :src="assetsPath + 'img/mastercard.gif'" class="img-mastercard" />
            <img :src="assetsPath + 'img/visa.png'" class="img-visa" />
          </div>
        </div>
      </div>
    </template>
    <template v-slot:modals>
      <ModalSantander :show.sync="isSantanderModalActive" @close="isSantanderModalActive = false" />
    </template>
  </MainLayout>
</template>

<script>
import MainLayout from '@/components/MainLayout'
import ModalSantander from '@/components/modals/ModalSantander'

export default {
  components: {
    MainLayout,
    ModalSantander,
  },
  data() {
    return {
      loading: false,
      isSantanderModalActive: false,
      assetsPath: process.env.VUE_APP_ROUTER_BASE,
    }
  },
}
</script>
