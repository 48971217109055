/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icon-cc': {
    width: 16,
    height: 16,
    viewBox: '0 0 21 16',
    data: '<path pid="0" d="M18 16H3a3 3 0 01-3-3V3a3 3 0 013-3h15a3 3 0 013 3v10a3 3 0 01-3 3zM7.938 3.968c.468 0 .862.06 1.182.181.321.121.647.317.979.588l-.589.69c-.475-.385-.976-.577-1.504-.577-.648 0-1.17.249-1.566.747-.396.497-.594 1.297-.594 2.397 0 1.071.196 1.861.588 2.37.392.509.912.763 1.561.763.332 0 .622-.056.871-.169.249-.114.512-.272.791-.476l.532.679c-.241.249-.55.456-.927.622a3.202 3.202 0 01-1.301.249 3.1 3.1 0 01-1.691-.469c-.494-.313-.878-.773-1.153-1.38-.276-.607-.413-1.337-.413-2.189 0-.852.143-1.581.43-2.188.286-.607.674-1.065 1.164-1.374a3.015 3.015 0 011.64-.464zm6.108 0c.467 0 .861.06 1.181.181.321.121.647.317.979.588l-.588.69c-.475-.385-.977-.577-1.505-.577-.648 0-1.17.249-1.566.747-.396.497-.594 1.297-.594 2.397 0 1.071.196 1.861.588 2.37.392.509.913.763 1.561.763.332 0 .622-.056.871-.169a4.61 4.61 0 00.792-.476l.531.679c-.241.249-.55.456-.927.622a3.202 3.202 0 01-1.301.249 3.1 3.1 0 01-1.691-.469c-.493-.313-.878-.773-1.153-1.38-.275-.607-.413-1.337-.413-2.189 0-.852.143-1.581.43-2.188.286-.607.675-1.065 1.165-1.374a3.01 3.01 0 011.64-.464z" _fill="#466552"/>'
  }
})
