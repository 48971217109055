import Vue from 'vue'
import VueI18n from 'vue-i18n'

import IdleVue from 'idle-vue'
import VClamp from 'vue-clamp'

import App from '@/App.vue'
import '@/registerServiceWorker'
import messages from '@/i18n'
import router from '@/router'
import store from '@/store'

import SvgIcon from 'vue-svgicon'
import Buefy from 'buefy'
import Vuelidate from 'vuelidate'
import Nprogress from 'nprogress'

import '@/filters'

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import { faAlignLeft } from '@fortawesome/free-solid-svg-icons/faAlignLeft'
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons/faAngleLeft'
import { faAngleRight } from '@fortawesome/free-solid-svg-icons/faAngleRight'
import { faArrowAltCircleLeft } from '@fortawesome/free-solid-svg-icons/faArrowAltCircleLeft'
import { faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons/faArrowCircleLeft'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons/faArrowLeft'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons/faArrowRight'
import { faBell } from '@fortawesome/free-solid-svg-icons/faBell'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons/faCheckCircle'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons/faChevronDown'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight'
import { faCube } from '@fortawesome/free-solid-svg-icons/faCube'
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons/faEllipsisH'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons/faEnvelope'
import { faEuroSign } from '@fortawesome/free-solid-svg-icons/faEuroSign'
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons/faExclamationCircle'
import { faEye } from '@fortawesome/free-solid-svg-icons/faEye'
import { faEyeSlash } from '@fortawesome/free-solid-svg-icons/faEyeSlash'
import { faFileDownload } from '@fortawesome/free-solid-svg-icons/faFileDownload'
import { faFrown } from '@fortawesome/free-solid-svg-icons/faFrown'
import { faGlobeEurope } from '@fortawesome/free-solid-svg-icons/faGlobeEurope'
import { faGrin } from '@fortawesome/free-solid-svg-icons/faGrin'
import { faHome } from '@fortawesome/free-solid-svg-icons/faHome'
import { faHourglassHalf } from '@fortawesome/free-solid-svg-icons/faHourglassHalf'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons/faInfoCircle'
import { faLock } from '@fortawesome/free-solid-svg-icons/faLock'
import { faLongArrowAltLeft } from '@fortawesome/free-solid-svg-icons/faLongArrowAltLeft'
import { faMinus } from '@fortawesome/free-solid-svg-icons/faMinus'
import { faMobile } from '@fortawesome/free-solid-svg-icons/faMobile'
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus'
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons/faQuestionCircle'
import { faReceipt } from '@fortawesome/free-solid-svg-icons/faReceipt'
import { faSadTear } from '@fortawesome/free-solid-svg-icons/faSadTear'
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch'
import { faSmile } from '@fortawesome/free-solid-svg-icons/faSmile'
import { faStarOfLife } from '@fortawesome/free-solid-svg-icons/faStarOfLife'
import { faTicketAlt } from '@fortawesome/free-solid-svg-icons/faTicketAlt'
import { faUserCircle } from '@fortawesome/free-solid-svg-icons/faUserCircle'
import { faCreditCard } from '@fortawesome/free-solid-svg-icons/faCreditCard'
import { faBars } from '@fortawesome/free-solid-svg-icons/faBars'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons/faChevronLeft'
import { faFilePdf } from '@fortawesome/free-solid-svg-icons/faFilePdf'
import { faCircle } from '@fortawesome/free-solid-svg-icons/faCircle'
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons/faShoppingCart'
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck'
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons/faSignOutAlt'

library.add(
  faFilePdf,
  faBars,
  faChevronLeft,
  faAlignLeft,
  faCreditCard,
  faAngleLeft,
  faAngleRight,
  faArrowAltCircleLeft,
  faArrowCircleLeft,
  faArrowLeft,
  faArrowRight,
  faBell,
  faCheckCircle,
  faChevronDown,
  faChevronRight,
  faCube,
  faEllipsisH,
  faEnvelope,
  faEuroSign,
  faExclamationCircle,
  faEye,
  faEyeSlash,
  faFileDownload,
  faFrown,
  faGlobeEurope,
  faGrin,
  faHome,
  faHourglassHalf,
  faInfoCircle,
  faInfoCircle,
  faLock,
  faLongArrowAltLeft,
  faMinus,
  faMobile,
  faPlus,
  faQuestionCircle,
  faReceipt,
  faSadTear,
  faSearch,
  faSmile,
  faStarOfLife,
  faTicketAlt,
  faUserCircle,
  faCircle,
  faShoppingCart,
  faCheck,
  faSignOutAlt
)
Vue.component('faicon', FontAwesomeIcon)
Vue.component('v-clamp', VClamp)

Vue.use(Buefy, {
  defaultIconComponent: 'faicon',
  defaultIconPack: 'fas',
})

Vue.use(VueI18n)
Vue.use(SvgIcon, { tagName: 'icon' })
Vue.use(Vuelidate)
Vue.use(Nprogress)

const eventsHub = new Vue()

Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  store,
  idleTime: 120000, // 1.5min
  startAtIdle: false,
})

Nprogress.configure({ showSpinner: false, minimum: 0.1 })

Vue.config.productionTip = false

// Inicializar i18n
let language = localStorage.getItem('settings.language')
if (!language) store.dispatch('setLanguage', 'pt')
const i18n = new VueI18n({
  locale: language || 'pt',
  fallbackLocale: 'pt',
  messages,
})

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
