<template>
  <MainLayout :title="$t('253')" back-to="/buy">
    <template v-slot:breadcrumb>
      <Breadcrumb :items="breadcrumb" />
    </template>
    <template v-slot:content>
      <hr class="with-arrow to-up" />
      <div v-if="!loading && entries" class="has-margin-top">
        <ListMeals v-if="entries.length" :list="entries" />
        <div v-else class="has-margin-top-large is-meta has-text-centered">{{ $t('254') }}.</div>
      </div>
      <ContentLoader v-if="loading" type="list" class="has-margin-top" />
    </template>
  </MainLayout>
</template>

<script>
import MainLayout from '@/components/MainLayout'
import Breadcrumb from '@/components/utilities/Breadcrumb'
import ContentLoader from '@/components/utilities/ContentLoader'
import BaseService from '@/services/BaseService'
import ListMeals from '@/components/lists/ListMeals'

export default {
  components: {
    MainLayout,
    Breadcrumb,
    ContentLoader,
    ListMeals,
  },
  data() {
    return {
      loading: false,
      entries: [],
      breadcrumb: this.$store.getters.getBreadcrumb,
    }
  },
  mounted() {
    if (this.$route.query.location_id) {
      this.getEntries(this.$route.query.location_id)
    } else {
      this.$router.push('/buy')
    }
  },
  methods: {
    async getEntries(locationId) {
      this.loading = true
      const entries = await BaseService.getBuyMeals(locationId)
      this.entries = Object.freeze(entries)
      this.loading = false
    },
  },
}
</script>
