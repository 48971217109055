<template>
  <MainLayout :title="$t('102')" short-title="Referências MB">
    <template v-slot:content>
      <div class="page-icon euro-sign">
        <span class="icon">
          <faicon icon="euro-sign"></faicon>
        </span>
      </div>
      <div class="has-margin-bottom-medium has-text-centered">
        <button class="button is-primary" @click="$router.push('/recharge/methods')">{{ $t('103') }}</button>
      </div>
      <div class="has-margin-top-large">
        <div class="buttons is-flex is-flex-justify-center is-text-centered is-outlined">
          <b-button
            size="is-small"
            :type="activeFilter == 'all' ? 'is-primary' : 'is-text'"
            @click="getEntries('all')"
            >{{ $t('104') }}</b-button
          >
          <b-button size="is-small" :type="activeFilter == 'mb' ? 'is-primary' : 'is-text'" @click="getEntries('mb')">{{
            $t('105')
          }}</b-button>
          <b-button
            size="is-small"
            :type="activeFilter == 'mbway' ? 'is-primary' : 'is-text'"
            @click="getEntries('mbway')"
            >{{ $t('117') }}</b-button
          >
          <b-button size="is-small" :type="activeFilter == 'cc' ? 'is-primary' : 'is-text'" @click="getEntries('cc')">{{
            $t('123')
          }}</b-button>
        </div>
      </div>
      <div v-if="!loading && entries" class="has-margin-top">
        <p v-if="entries.length" class="has-text-centered is-meta has-margin-bottom">
          {{ entries.length }} {{ entries.length > 1 ? $t('102') : $t('103') }}
        </p>
        <ListRecharges v-if="entries.length" :list="entries" @reload-entries="getEntries('all')" />
        <div v-else class="has-margin-top-large is-meta has-text-centered">{{ $t('128') }}.</div>
      </div>
      <ContentLoader v-if="loading" type="list" class="has-margin-top" />
    </template>
  </MainLayout>
</template>

<script>
import MainLayout from '@/components/MainLayout'
import ContentLoader from '@/components/utilities/ContentLoader'
import BaseService from '@/services/BaseService'
import ListRecharges from '@/components/lists/ListRecharges'

export default {
  components: {
    MainLayout,
    ListRecharges,
    ContentLoader,
  },
  data() {
    return {
      loading: false,
      limit: 25,
      entries: [],
      activeFilter: 'all',
      error: null,
    }
  },
  mounted() {
    this.getEntries('all')
  },

  methods: {
    async getEntries(type) {
      this.loading = true

      try {
        const entries = await BaseService.getRechargeHistory(type, this.limit)
        this.entries = Object.freeze(entries)
        this.activeFilter = type
      } catch (e) {
        this.error = true
        this.$buefy.snackbar.open({
          duration: 5000,
          message: this.$t('43'),
          type: 'is-danger',
          position: 'is-bottom-left',
          actionText: 'OK',
          queue: false,
        })
      }

      this.loading = false
    },
  },
}
</script>
