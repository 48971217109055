<template>
  <div>
    <div class="record" :class="{ disabled: item.estado.anulado, 'is-link': isActiveAndReadyToUse }" @click="getQRCode">
      <div class="record-icon">
        <faicon
          icon="ticket-alt"
          size="sm"
          class="has-minus-45-rotation"
          :class="{ 'has-text-primary': item.estado.ativo }"
        ></faicon>
      </div>
      <div class="record-content">
        <div class="record-title is-flex is-flex-space-between">
          <span class="has-margin-right has-text-weight-medium">
            <v-clamp autoresize :max-lines="1">{{ item.desc_ua }}</v-clamp></span
          >
          <span class="is-meta"># {{ item.id_doc }}</span>
        </div>
        <div class="record-meta is-flex is-flex-space-between">
          <div v-if="!item.estado.validado && item.estado.ativo">{{ $t('58') }} {{ item.data_uso }}</div>
          <div v-if="item.estado.anulado">{{ $t('73') }}</div>
          <div v-if="item.estado.fora_prazo">
            {{ $t('74') }} <span class="has-text-muted has-margin-left">{{ item.data_uso }}</span>
          </div>
          <div v-if="item.estado.validado">{{ $t('75') }}</div>
          <div>{{ item.desc_tipo_ref }}</div>
        </div>
        <div v-if="item.estado.validado && item.validador" class="record-meta">
          {{ item.validador.desc_validador }} / {{ item.validador.data_validacao }}
        </div>
      </div>
      <div v-if="isActiveAndReadyToUse" class="record-icon-right qrcode">
        <icon name="icon-qrcode"></icon>
      </div>
    </div>
    <b-modal
      :active.sync="isOpened"
      :width="640"
      scroll="keep"
      full-screen
      :can-cancel="false"
      class="modal-hero modal-qrcode"
    >
      <div class="panel">
        <div class="title">Ticket # {{ item.id_doc }}</div>
        <div v-for="product in products" :key="product" class="lead has-text-centered bold has-margin-top">
          {{ product }}
        </div>
        <div class="has-text-centered is-meta">{{ $t('58') }} {{ item.data_uso }}</div>

        <div class="qrcode has-margin-top">
          <b-loading :active.sync="isLoading"></b-loading>
          <div v-if="!isLoading && qrCode">
            <div @click="getQRCode">
              <img :src="qrCode" />
            </div>
            <div class="is-meta has-text-tiny has-text-centered">{{ qrCodeMd5 }}</div>
            <div class="instructions has-text-centered">{{ $t('69') }}.</div>
            <div class="instructions has-text-centered">{{ $t('302') }}.</div>
          </div>
        </div>
        <div class="buttons">
          <b-button type="is-black" expanded @click="closeModal">{{ $t('175') }}</b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import md5 from 'md5'
import BaseService from '@/services/BaseService'

export default {
  props: {
    item: {
      type: Object,
    },
  },

  data() {
    return {
      isLoading: true,
      qrCode: false,
      qrCodeMd5: null,
      isOpened: false,
      products: null,
    }
  },

  computed: {
    isActiveAndReadyToUse() {
      return !this.item.estado.validado && this.item.estado.ativo
    },
  },

  methods: {
    closeModal() {
      this.isOpened = false
      this.$emit('reload')
    },

    async getQRCode() {
      if (!this.isActiveAndReadyToUse) {
        return
      }

      this.isLoading = true

      try {
        const ticket = await BaseService.getTicketQrCode(this.item.id_doc)
        this.qrCode = 'data:image/png;base64, ' + ticket.qrcode
        this.products = ticket.lstProdutos
        this.isOpened = true
        this.qrCodeMd5 = md5(this.qrCode).substring(0, 10)
      } catch (e) {
        this.$buefy.snackbar.open({
          duration: 5000,
          message: e,
          type: 'is-danger',
          position: 'is-bottom-left',
          actionText: 'OK',
          queue: false,
        })
      }

      this.isLoading = false
    },
  },
}
</script>
