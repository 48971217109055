<template>
  <div class="notification-icon cart-icon">
    <a
      href="/cart"
      class="is-flex is-flex-align-center"
      :class="{
        'has-background-black has-text-white has-text-small is-rounded has-padding-tiny-top-small-sides': productCount,
      }"
      @click.prevent="$router.push('/cart')"
    >
      <icon name="icon-tabuleiro" class="icon" :class="{ 'is-small': productCount }" />
      <span v-if="productCount" class="notification-badge" :class="{ 'is-medium': productCount }">{{
        productCount
      }}</span>
      <span v-if="productCount" class="has-margin-left-tiny">PAGAR</span>
    </a>
  </div>
</template>

<script>
export default {
  computed: {
    productCount() {
      return this.$store.getters.getCartCount
    },
  },
  created() {
    const cartDate = this.$store.getters.getCartDate
    // Make the cart expire after 15 minutes of its creation
    if (cartDate && Date.now() - cartDate > 900000) {
      this.$store.dispatch('clearCart')
    }
  },
}
</script>
