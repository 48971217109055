<template>
  <div>
    <div class="qrcode-button">
      <button @click="getQRCode">
        <div v-if="userInfo" class="avatar-container">
          <img v-if="userInfo.foto" :src="'data:image/jpeg;base64,' + userInfo.foto" />
          <faicon v-else icon="user-circle" />
          <span class="inner">
            <icon name="icon-qrcode"></icon>
          </span>
        </div>
      </button>
      <div v-if="userInfo && userInfo.id" class="has-text-centered">
        <div class="is-size-2 has-margin-bottom">{{ $t('57') }}, {{ userNickname }}</div>
        <div class="has-text-tiny">{{ $t('68') }}</div>
        <div class="has-text-primary is-size-1 has-text-weight-bold	">{{ userInfo.id }}</div>
      </div>
    </div>
    <b-modal
      :active.sync="isOpened"
      :width="640"
      scroll="keep"
      full-screen
      :can-cancel="false"
      class="modal-hero modal-qrcode"
    >
      <div class="panel">
        <div class="title">{{ $t('68') }}</div>
        <div class="qrcode">
          <b-loading :active.sync="isLoading"></b-loading>
          <div v-if="!isLoading && qrCode">
            <div @click="getQRCode">
              <img :src="qrCode" />
            </div>
            <div class="is-meta has-text-tiny has-text-centered">{{ qrCodeMd5 }}</div>
            <div class="instructions has-text-centered">{{ $t('69') }}.</div>
            <div class="instructions has-text-centered">{{ $t('302') }}.</div>
          </div>
        </div>
        <div class="buttons">
          <b-button type="is-black" expanded @click="isOpened = false">{{ $t('175') }}</b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import md5 from 'md5'
import first from 'lodash/first'
import BaseService from '@/services/BaseService'

export default {
  props: {
    open: Boolean,
  },

  data() {
    return {
      isLoading: true,
      isOpened: this.open || false,
      qrCode: false,
    }
  },

  computed: {
    user() {
      return this.$store.getters.getUser
    },
    userInfo() {
      return this.$store.getters.getUserSummary
    },
    userNickname() {
      if (this.userInfo.nickname) {
        return this.userInfo.nickname
      } else {
        return this.userInfo.nome ? first(this.userInfo.nome.split(' ')) : null
      }
    },
  },

  watch: {
    open(value) {
      this.isOpened = value
    },
  },

  methods: {
    closeModal() {
      this.isOpened = false
    },

    async getQRCode() {
      this.isLoading = true
      this.isOpened = true
      this.qrCode = await BaseService.getPersonalQRCode()
      this.qrCodeMd5 = md5(this.qrCode).substring(0, 10)
      this.isLoading = false
    },
  },
}
</script>
