<template>
  <BasicLayout title="Código de validação" back-to="/login">
    <template slot="content">
      <h1>{{ $t('101') }}</h1>

      <div class="page-icon is-medium">
        <span class="icon">
          <faicon icon="envelope"></faicon>
        </span>
      </div>

      <!-- Erros -->
      <div
        v-if="userTypeDetails"
        class="width-small has-text-centered notification is-black has-text-warning has-padding-small"
      >
        <div v-if="userTypeDetails.code == 1">
          {{ $t('3') }}
        </div>
        <div v-if="userTypeDetails.code == 2">
          {{ $t('4') }}
        </div>
        <div v-if="userTypeDetails.code == 4">
          {{ $t('5') }}
        </div>
        <div v-if="userTypeDetails.code == -1">
          {{ userTypeDetails.desc }}
        </div>
      </div>

      <p v-if="!userTypeDetails" class="width-small has-text-small has-text-centered">
        {{ $t('2') }}
      </p>

      <p v-if="!userTypeDetails" class="lead width-small has-text-centered">
        {{ $t('1') }}
      </p>

      <form
        class="width-small has-text-centered has-margin-top-medium"
        @keypress.enter="doConfirm"
        @submit.prevent="doConfirm"
      >
        <b-field :label="$t('101')" label-for="full_name" class="has-margin-bottom">
          <b-input
            v-model="token"
            class="has-text-centered is-code"
            maxlength="5"
            :placeholder="$t('100')"
            type="input"
          ></b-input>
        </b-field>
        <b-field v-if="userTypeDetails">
          <b-button type="is-text" size="is-small" @click="getNewCode">{{ $t('268') }}</b-button>
        </b-field>
      </form>
      <b-field class="has-text-right has-margin-top-medium">
        <b-button type="is-link" @click="doConfirm">{{ $t('60') }} <faicon icon="arrow-right"></faicon></b-button>
      </b-field>
    </template>
  </BasicLayout>
</template>

<script>
import BasicLayout from '@/components/BasicLayout'
import BaseService from '@/services/BaseService'

export default {
  components: {
    BasicLayout,
  },
  data() {
    return {
      token: null,
      userTypeDetails: null,
    }
  },
  computed: {
    registerEmail() {
      return this.$store.state.register.email
    },
  },
  mounted() {
    this.getToken()
  },
  methods: {
    async getToken() {
      try {
        await BaseService.getRegisterToken(this.registerEmail)
      } catch (error) {
        console.log(error)
        this.$router.push('/login')
      }
    },
    async getNewCode() {
      this.token = null
      this.userTypeDetails = null
      this.getToken()
    },
    async doConfirm() {
      this.userTypeDetails = await BaseService.getUserType(this.registerEmail, this.token)

      this.$store.state.register.token = this.token

      // Novo cliente
      if (this.userTypeDetails.code == 3) {
        this.$router.push('/register')
      }

      // Cliente registado e válido
      else if (this.userTypeDetails.code == 5 || this.userTypeDetails.code == 6) {
        this.$store.state.register.emailConfirmed = true
        this.$router.push('/login')
      }
    },
  },
}
</script>
