<template>
  <MainLayout :title="title" :short-title="title" :has-title="false" :has-back-to="false">
    <template #content>
      <CreditsPanel />
      <QRCodePanel class="has-margin-top" />
      <div class="panel has-margin-top">
        <div class="panel-body">
          <b-tabs v-model="activeTab" :animated="false" @input="getDataByTab">
            <b-tab-item :label="$t('70')">
              <div class="tab-inner-nav buttons single-row is-outlined">
                <b-button
                  size="is-small"
                  :type="activeTicketsFilter == 'active' ? 'is-primary' : 'is-text'"
                  @click="getTickets('active')"
                  >{{ $t('72') }}</b-button
                >
                <b-button
                  size="is-small"
                  :type="activeTicketsFilter == 'validated' ? 'is-primary' : 'is-text'"
                  @click="getTickets('validated')"
                  >{{ $t('71') }}</b-button
                >
                <b-button
                  size="is-small"
                  :type="activeTicketsFilter == 'canceled' ? 'is-primary' : 'is-text'"
                  @click="getTickets('canceled')"
                  >{{ $t('73') }}</b-button
                >
                <b-button
                  size="is-small"
                  :type="activeTicketsFilter == 'expired' ? 'is-primary' : 'is-text'"
                  @click="getTickets('expired')"
                  >{{ $t('74') }}</b-button
                >
              </div>
              <ListTickets v-if="tickets.length" :list="tickets" @reload="getTickets" />
              <p v-else-if="!loadingTickets && !tickets.length" class="is-meta has-text-centered has-margin-top-large">
                {{ $t('76') }}.
              </p>
              <ContentLoader v-else type="list" />
            </b-tab-item>
            <b-tab-item :label="$t('79')">
              <div v-if="movements.length">
                <ListMovements :list="movements" @is-ready="loadingMoviments = false" />
                <div
                  v-if="!loadingMoviments && movements.length < totalMovements"
                  class="has-margin-top has-text-centered"
                >
                  <button class="button is-small is-text" @click="getMovements(false)">
                    {{ $t('81') }}
                  </button>
                </div>
              </div>
              <p v-else-if="!loadingMoviments && !movements.length" class="is-meta has-text-centered has-margin-top">
                {{ $t('80') }}.
              </p>
              <ContentLoader v-if="loadingMoviments" type="list" />
            </b-tab-item>
            <b-tab-item v-if="credentials.length > 0" :label="$t('63')">
              <ListCredentials v-if="!loadingCredentials && credentials.length" :list="credentials" />
              <p
                v-else-if="!loadingCredentials && !credentials.length"
                class="is-meta has-text-centered has-margin-top"
              >
                {{ $t('86') }}.
              </p>
              <ContentLoader v-else type="list" />
            </b-tab-item>
          </b-tabs>
        </div>
      </div>
    </template>
    <template #sidebar>
      <div>
        <div v-if="isAdBoxMobileAddToHomeActive">
          <div class="is-flex">
            <div>
              <faicon icon="mobile" size="3x" class="has-margin-right" />
            </div>
            <div>
              <h4>{{ $t('97') }}</h4>
              <p>
                <a @click="isAddToHomeModalActive = true">
                  {{ $t('98') }}
                </a>
              </p>
              <p>
                <a class="has-text-tiny has-text-muted" @click="closeMobileInfo">
                  {{ $t('99') }}
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template #modals>
      <ModalAddToHome :show.sync="isAddToHomeModalActive" @close="isAddToHomeModalActive = false"></ModalAddToHome>
      <ModalDownloading :show.sync="isDownloadingFile" :percentage.sync="$store.state.isDownloading" />
      <ModalRGPD :show.sync="isModalRGPDActive" :user="userInfo" @accepted="updateUserInfo" />
    </template>
  </MainLayout>
</template>

<script>
import isEmpty from 'lodash/isEmpty'
import MainLayout from '@/components/MainLayout'
import BaseService from '@/services/BaseService'
import QRCodePanel from '@/components/utilities/QRCodePanel'
import CreditsPanel from '@/components/utilities/CreditsPanel'
import ModalDownloading from '@/components/modals/ModalDownloading'
import ListMovements from '@/components/lists/ListMovements'
import ListTickets from '@/components/lists/ListTickets'
import ListCredentials from '@/components/lists/ListCredentials'
import ContentLoader from '@/components/utilities/ContentLoader'
import ModalAddToHome from '@/components/modals/ModalAddToHome'
import ModalRGPD from '@/components/modals/ModalRGPD'

export default {
  components: {
    MainLayout,
    QRCodePanel,
    ModalDownloading,
    ListMovements,
    ListCredentials,
    ListTickets,
    ContentLoader,
    CreditsPanel,
    ModalAddToHome,
    ModalRGPD,
  },
  data() {
    return {
      loading: false,
      loadingMoviments: false,
      loadingTickets: false,
      loadingCredentials: false,
      loadingInvoices: false,
      activeTab: null,
      movements: [],
      totalMovements: 0,
      credentials: [],
      tickets: [],
      activeTicketsFilter: 'active',
      invoices: [],
      title: 'Início',
      isIntroModalActive: false,
      isAddToHomeModalActive: false,
      isAdBoxMobileAddToHomeActive: false,
      isModalRGPDActive: false,
    }
  },

  computed: {
    isDownloadingFile() {
      return this.$store.state.isDownloading > 0 && this.$store.state.isDownloading < 100
    },
    userSummary() {
      return this.$store.getters.getUserSummary
    },
    userInfo() {
      return !isEmpty(this.$store.getters.getUserInfo) ? this.$store.getters.getUserInfo : {}
    },
  },

  watch: {
    userInfo(info) {
      if (info && !info.data_cons_rgpd) {
        setTimeout(() => {
          this.isModalRGPDActive = true
        }, 250)
      }
    },
  },

  mounted() {
    this.getMovements(true)
    this.getCredentials()
    this.getTickets('active')
    this.checkMobileInfo()
    this.getUserInfo()
  },

  methods: {
    updateUserInfo(data) {
      const user = Object.assign(this.userInfo, data)
      BaseService.updateUserInfo(user)
        .then(() => {
          console.info('Dados pessoais atualizados')
          this.getUserInfo()
          this.loadingSave = false
        })
        .catch(err => {
          console.log(err)
          this.loadingSave = false
        })
    },

    checkMobileInfo() {
      if (!localStorage.getItem('adbox:mobile-add-to-home')) {
        this.isAdBoxMobileAddToHomeActive = true
      } else {
        this.isAdBoxMobileAddToHomeActive = false
      }
    },

    async getUserInfo() {
      const userInfo = await BaseService.getUserInfo()
      this.$store.dispatch('setUserInfo', userInfo)
    },

    closeMobileInfo() {
      localStorage.setItem('adbox:mobile-add-to-home', 'hidden')
      this.isAdBoxMobileAddToHomeActive = false
    },

    checkModalIntro() {
      if (!localStorage.getItem('info.intro')) {
        localStorage.setItem('info.intro', true)
        this.isIntroModalActive = true
      }
    },

    getDataByTab() {
      if (!this.activeTab) {
        this.title = 'Tickets'
        this.getTickets('active')
      } else if (this.activeTab == 1) {
        this.title = 'Movimentos'
        this.getMovements(true)
      } else {
        this.title = 'Credenciais'
        this.getCredentials()
      }
    },

    async getTickets(type) {
      this.tickets = await BaseService.getTickets(type)
      this.activeTicketsFilter = type
    },

    async getMovements(latestOnly = true) {
      // Caso existam resultados já carregados,
      // obtém novos dados de forma transparente (ou pelo menos parcialmente)
      if (!this.movements.length) {
        this.loadingMoviments = true
      }

      const data = await BaseService.getMovements(latestOnly)
      this.movements = Object.freeze(data.movimentos)
      this.totalMovements = data.total_movimentos

      // Movimentos carregados
      this.loadingMoviments = false
    },

    async getCredentials() {
      if (!this.credentials.length) {
        this.loadingCredentials = true
      }

      const entries = await BaseService.getCredentials()
      this.credentials = Object.freeze(entries)

      // Movimentos carregados
      this.loadingCredentials = false
    },

    async getInvoices() {
      if (!this.invoices.length) {
        this.loadingInvoices = true
      }

      const entries = await BaseService.getInvoices()
      this.invoices = Object.freeze(entries)

      // Dados carregados
      this.loadingInvoices = false
    },
  },
}
</script>
