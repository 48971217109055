<template>
  <MainLayout :title="$t('87')">
    <template v-slot:content>
      <div class="page-icon is-medium">
        <span class="icon">
          <icon name="icon-transfer"></icon>
        </span>
      </div>

      <CreditsPanel />

      <form v-if="userSummary && userSummary.saldo" class="width-small has-margin-top-large has-text-centered">
        <b-field :label="$t('88')">
          <b-numberinput
            v-model="amount"
            step="0.05"
            min="1.0"
            :max="$store.getters.getCredit"
            :editable="true"
          ></b-numberinput>
        </b-field>

        <div v-if="userSummary && userSummary.saldo && favorites && favorites.length">
          <b-field :label="$t('92')" class="has-margin-top-large">
            <b-select v-model="selectedFavorite" expanded :placeholder="$t('93') + ' ...'">
              <option value="other">{{ $t('94') }}</option>
              <option v-for="person in favorites" :key="person.id" :value="person">
                {{ person.nome }}
              </option>
            </b-select>
          </b-field>
          <div v-if="!isCustomTransfer && selectedFavorite">
            <b-button class="button is-small is-meta" @click="deleteFavorite">{{ $t('95') }}</b-button>
          </div>
        </div>

        <div class="has-margin-top-large">
          <div v-if="!loading && isCustomTransfer">
            <b-field :label="$t('96')">
              <b-input v-model="numberTo" class="has-text-centered" type="text" maxlength="25"></b-input>
            </b-field>
            <b-field :label="$t('68')" class="has-margin-top-large">
              <b-input v-model="personalIdTo" class="has-text-centered" type="number" maxlength="10"></b-input>
            </b-field>
          </div>
          <b-field
            v-if="
              (selectedFavorite && selectedFavorite != 'other') ||
                (selectedFavorite == 'other ' && numberTo && personalIdTo) ||
                (numberTo && personalIdTo)
            "
            class="has-margin-top-large"
          >
            <b-button type="is-primary" expanded :loading="loading" @click="doTransfer">{{ $t('87') }}</b-button>
          </b-field>
        </div>
      </form>
    </template>
    <template #modals>
      <b-modal
        :active="isTransferedModalActive"
        :width="500"
        scroll="keep"
        custom-class="hide-close"
        has-modal-card
        trap-focus
        aria-role="dialog"
        aria-modal
        @close="isTransferedModalActive = false"
      >
        <div class="modal-card has-margin" style="width: auto">
          <section class="modal-card-body">
            <h3>{{ $t('89') }}.</h3>
            <div class="is-flex-space-center has-margin-top">
              <span class="is-meta">{{ $t('90') }}</span
              >{{ numberTo }} / {{ personalIdTo }}
            </div>
            <div class="is-flex-space-center">
              <span class="is-meta">{{ $t('91') }}</span
              >{{ amount | currency }}
            </div>
            <div class="is-pulled-right has-margin-top">
              <button class="button is-text" @click="afterTransfer">{{ $t('175') }}</button>
            </div>
          </section>
        </div>
      </b-modal>
    </template>
  </MainLayout>
</template>

<script>
import MainLayout from '@/components/MainLayout'
import BaseService from '@/services/BaseService'
import CreditsPanel from '@/components/utilities/CreditsPanel'

export default {
  components: {
    MainLayout,
    CreditsPanel,
  },
  data() {
    return {
      loading: false,
      addNew: false,
      amount: 2.4,
      numberTo: null, // 90963
      personalIdTo: null, // 201526532
      favorites: [],
      selectedFavorite: null,
      isTransferedModalActive: false,
    }
  },
  computed: {
    userSummary() {
      return this.$store.getters.getUserSummary
    },
    isCustomTransfer() {
      return this.selectedFavorite === 'other' || !this.favorites.length
    },
  },
  mounted() {
    this.getFavorites()
  },

  methods: {
    // Realiza a transferência
    doTransfer() {
      this.loading = true

      // Caso esteja selecionado um favorito e não seja o valor nulo,
      // define as variaveis principais automaticamente
      if (this.selectedFavorite && !this.isCustomTransfer) {
        this.personalIdTo = this.selectedFavorite.id
        this.numberTo = this.selectedFavorite.nr_aluno
      }

      BaseService.transferCredit(this.amount, this.personalIdTo, this.numberTo)
        .then(response => {
          if (response.result == this.$t(222)) {
            this.$buefy.snackbar.open({
              message: response.result,
              type: 'is-danger',
              position: 'is-bottom-right',
              duration: 5000,
            })
          } else {
            // Na modal de confirmação devemos confirmar
            // se o utilizador pretende guardar a conta como favorita
            this.isTransferedModalActive = true
          }
          this.loading = false
        })
        .catch(err => {
          this.$buefy.snackbar.open({
            message: err,
            type: 'is-danger',
            position: 'is-bottom-right',
            duration: 5000,
          })
          this.loading = false
        })
    },

    // Valida se a conta já é um favorito
    // Caso não seja, questionar o utilizador se pretende guardar
    afterTransfer() {
      this.isTransferedModalActive = false

      const favoriteExists = this.favorites.filter(fav => {
        return fav.nr_aluno == this.numberTo && fav.id == this.personalIdTo
      })

      if (!favoriteExists.length) {
        this.$buefy.dialog.confirm({
          message: this.$t(223),
          onConfirm: () =>
            this.$buefy.dialog.prompt({
              message: this.$t(224),
              inputAttrs: {
                placeholder: this.$t(225),
                maxlength: 50,
              },
              trapFocus: true,
              onConfirm: value => this.saveFavorite(this.numberTo, this.personalIdTo, value),
            }),
        })
      }

      // Limpa models
      else {
        this.numberTo = null
        this.personalIdTo = null
      }
    },

    saveFavorite(employeeNumber, personalId, name) {
      BaseService.addFavorite(employeeNumber, personalId, name).then(() => {
        this.getFavorites()
      })

      // Limpa models
      this.numberTo = null
      this.personalIdTo = null
    },

    deleteFavorite() {
      this.$buefy.dialog.confirm({
        message: this.$t(226),
        onConfirm: () => {
          BaseService.deleteFavorite(this.selectedFavorite.nr_aluno, this.selectedFavorite.id).then(() => {
            this.$buefy.snackbar.open({
              message: this.$t(227),
              type: 'is-primary',
              position: 'is-bottom-right',
              duration: 5000,
            })
            this.getFavorites()
          })

          // Limpa models
          this.numberTo = null
          this.personalIdTo = null
        },
      })
    },

    async getFavorites() {
      this.loading = true
      this.favorites = await BaseService.getFavorites()
      this.loading = false
    },
  },
}
</script>
