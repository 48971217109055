<template>
  <div>
    <a
      v-for="item in list"
      :key="item.cod"
      :href="'/buy/meals?location_id=' + item.cod"
      class="record"
      @click="updateBreadcrumb(item.desc)"
      @click.stop.prevent="$router.push('/buy/meals?location_id=' + item.cod)"
    >
      <div class="record-icon">
        <div class="page-icon no-margin">
          <span class="icon is-large has-color-black">
            <img v-if="item.img" :src="'data:image/jpeg;base64,' + item.img" />
            <icon v-else name="icon-food-unit" class="is-small" />
          </span>
        </div>
      </div>
      <div class="record-content">
        <div class="record-title is-flex-space-center">
          <span class="has-margin-left has-margin-right has-text-weight-medium">
            <v-clamp autoresize :max-lines="2">{{ item.desc }}</v-clamp>
          </span>
          <span class="has-text-weight-medium">
            <faicon icon="chevron-right" />
          </span>
        </div>
      </div>
    </a>
  </div>
</template>

<script>
import dayjs from 'dayjs'

export default {
  props: {
    list: Array,
  },
  beforeCreate() {
    dayjs.locale(this.$i18n.locale)
  },
  methods: {
    updateBreadcrumb(text) {
      this.$store.dispatch('clearBreadcrumb')

      this.$store.dispatch('addBreadcrumbItem', {
        position: 0,
        url: '#',
        text: dayjs()
          .format('D MMMM')
          .toLowerCase(),
      })

      this.$store.dispatch('addBreadcrumbItem', {
        position: 1,
        url: this.$router.currentRoute.fullPath,
        text: text,
      })
    },
  },
}
</script>
