<template>
  <RecycleScroller
    v-slot="{ item }"
    :items="list"
    :item-size="100"
    :buffer="100"
    key-field="referencia"
    class="scroller"
  >
    <div class="record">
      <div class="record-icon has-text-centered">
        <div v-if="item.valor_pago" class="has-text-small has-margin-top-small">{{ item.valor_pago }} €</div>
        <div v-if="item.data_operacao" class="is-meta has-margin-top-small">{{ item.data_operacao }}</div>
      </div>
      <div class="record-content has-margin-left-small">
        <div class="is-flex-space-center">
          <div class="has-text-centered">
            <div class="is-meta">{{ $t('179') }}</div>
            <div>
              <span v-if="item.valor_pago" class="has-text-primary">{{ $t('126') }}</span>
              <span v-else class="has-text-danger has-text-weight-medium">{{ $t('127') }}</span>
            </div>
          </div>
          <div class="has-text-centered">
            <div class="is-meta">{{ $t('114') }}</div>
            <div>{{ item.entidade }}</div>
          </div>
          <div class="has-text-centered">
            <div class="is-meta">{{ $t('113') }}</div>
            <div>{{ item.referencia }}</div>
          </div>
          <div class="has-text-centered">
            <div class="is-meta">{{ $t('91') }}</div>
            <div>{{ item.valor_pagar }} €</div>
          </div>
        </div>
        <div class="is-flex is-flex-space-between has-text-small has-margin-top is-meta">
          <div>{{ $t('115') }} {{ item.data_criacao }}</div>
          <div v-if="!item.oculta">
            <a class="is-text" @click.prevent="hideEntry(item)">{{
              $t('178').toLowerCase() + ' ' + $t('113').toLowerCase()
            }}</a>
          </div>
        </div>
      </div>
    </div>
  </RecycleScroller>
</template>

<script>
import { RecycleScroller } from 'vue-virtual-scroller'
import BaseService from '@/services/BaseService'

export default {
  components: {
    RecycleScroller,
  },
  props: {
    list: Array,
  },
  methods: {
    callReloadEntries() {
      this.$emit('reload-entries')
    },
    async hideEntry(item) {
      const updated = await BaseService.setMbEntryAsHidden(item)
      if (updated && !updated.erro) {
        this.callReloadEntries()
      }
    },
  },
}
</script>
