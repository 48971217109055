/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icon-return': {
    width: 35,
    height: 16,
    viewBox: '0 0 35 16',
    data: '<path pid="0" d="M15 2c0-1.1.9-2 2-2h15a1 1 0 011 1v1H17v1h17a1 1 0 011 1v10a2 2 0 01-2 2H17a2 2 0 01-2-2V2zm16.5 9a1.5 1.5 0 100-3 1.5 1.5 0 000 3zM0 6.162v-.694h6.937V2l4.162 4.162-4.162 4.162V6.856h-5.55v6.243H0V6.162z" _fill="#466552"/>'
  }
})
