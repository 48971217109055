<template>
  <div class="language-nav">
    <b-dropdown v-model="selectedLanguage" aria-role="list" position="is-bottom-left">
      <button slot="trigger" class="button" type="button">
        <faicon icon="globe-europe" />
        <faicon icon="chevron-down" size="xs" />
      </button>
      <b-dropdown-item value="pt" aria-role="listitem">
        <span>Português</span>
      </b-dropdown-item>
      <b-dropdown-item value="en" aria-role="listitem">
        <span>English</span>
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script>
import store from '@/store'

export default {
  name: 'LanguageNav',
  data() {
    return {
      selectedLanguage: store.state.language || this.$i18n.locale,
    }
  },
  watch: {
    selectedLanguage() {
      // Atualiza o idioma no store
      store.dispatch('setLanguage', this.selectedLanguage)
      this.$i18n.locale = this.selectedLanguage

      // Garante que os dados são obtidos em inglês
      // Para isso, para simplificar, recarregamos a páginas,
      // já que o p_lang está a ser definido em todas as chamadas
      this.$router.go()
    },
  },
}
</script>
