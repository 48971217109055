<template>
  <MainLayout :title="$t('27')">
    <template v-slot:content>
      <div class="has-text-centered">
        <div v-if="!loading && userInfo" class="profile-picture">
          <div class="is-size-2 has-margin-top-medium has-margin-bottom">{{ userInfo.nome }}</div>
          <b-field class="file">
            <b-upload
              v-model="photo"
              class="has-text-centered reset"
              drag-drop
              accept="image/png,image/gif,image/jpeg,image/webp,image/heif,image/heic"
              @input="doUploadPhoto"
            >
              <div class="avatar-container" :class="{ 'is-loading': isUploadingPhoto }">
                <div>
                  <img v-if="userInfo.foto" :src="'data:image/jpeg;base64,' + userInfo.foto" />
                  <faicon v-else icon="user-circle" />
                </div>
              </div>
              <a class="button is-small is-text has-margin-top-small">
                <span v-if="isUploadingPhoto" class="is-meta">{{ $t('185') }}...</span>
                <span v-else>{{ $t('144') }}</span>
              </a>
            </b-upload>
          </b-field>
        </div>

        <p class="has-margin-bottom is-meta">{{ user }}</p>
      </div>

      <nav v-if="userInfo" class="nav-mobile has-margin-top-large">
        <ul>
          <li>
            <a class="is-flex is-flex-align-center is-flex-space-between" @click="$router.push('/account/personal')"
              >{{ $t('145') }} <faicon icon="chevron-right"></faicon
            ></a>
          </li>
          <li>
            <p class="is-flex is-flex-space-between">
              <span>{{ $t('149') }}</span>
              <b-switch v-model="userInfo.data_fat_eletronica" class="is-marginless" @input="updateSwitch"></b-switch>
            </p>
          </li>
          <li>
            <p class="is-flex is-flex-space-between">
              <span>{{ $t('150') }}</span>
              <b-switch
                v-model="userInfo.data_faturas_consumidor_final"
                class="is-marginless"
                @input="updateSwitch"
              ></b-switch>
            </p>
            <p class="is-meta has-text-tiny has-margin-top-small">
              {{ $t('151') }}
            </p>
          </li>
          <!-- <li>
            <p class="is-flex is-flex-space-between">
              <span>{{ $t('148') }}</span>
              <b-switch v-model="userInfo.data_perm_santander" class="is-marginless" @input="updateSwitch"></b-switch>
            </p>
          </li> -->
        </ul>
      </nav>
      <div class="has-margin-top-large has-text-centered">
        <div class="is-flex is-flex-justify-center">
          <div>
            <b-button type="is-text" class="has-text-danger" icon-right="sign-out-alt" @click.prevent="doLogout">{{
              $t('52')
            }}</b-button>
            <div class="is-width-small has-text-tiny has-text-muted has-margin-top">{{ $t('53') }}</div>
          </div>
          <div v-if="!isInstitutionalEmail">
            <b-button type="is-text" class="has-margin-left has-text-dark" @click="setPassword">{{
              $t('281')
            }}</b-button>
          </div>
        </div>
      </div>
    </template>
    <template #modals>
      <ModalSetPassword :show.sync="showSetPasswordModal" @close="showSetPasswordModal = $event" />
    </template>
  </MainLayout>
</template>

<script>
import BaseService from '@/services/BaseService'
import MainLayout from '@/components/MainLayout'
import AuthService from '@/services/AuthService'
import ModalSetPassword from '@/components/modals/ModalSetPassword'

export default {
  components: {
    MainLayout,
    ModalSetPassword,
  },
  data() {
    return {
      loading: false,
      isUploadingPhoto: false,
      photo: null,
      showSetPasswordModal: false,
    }
  },
  computed: {
    user() {
      return this.$store.getters.getUser
    },
    userInfo() {
      return this.$store.getters.getUserInfo
    },
    isInstitutionalEmail() {
      return AuthService.helpers.isInstitutionalEmail(this.user)
    },
  },
  mounted() {
    this.getUserInfo()
  },
  methods: {
    async getUserInfo(force = false) {
      if (!this.userInfo || force) {
        const userInfo = await BaseService.getUserInfo()
        this.$store.dispatch('setUserInfo', userInfo)
      }
    },

    doUploadPhoto() {
      if (this.photo) {
        // Limite de 20MB definido pela API
        if (this.photo.size >= 20971520) {
          this.$buefy.snackbar.open({
            message: this.$t('186'),
            type: 'is-warning',
            position: 'is-bottom-right',
            duration: 5000,
          })
        } else {
          this.isUploadingPhoto = true
          BaseService.updateUserPhoto(this.photo)
            .then(() => {
              this.getUserInfo(true)
              this.$buefy.snackbar.open({
                message: this.$t('187'),
                type: 'is-primary',
                position: 'is-bottom-right',
                duration: 5000,
              })
              setTimeout(() => {
                this.isUploadingPhoto = false
              }, 500)
            })
            .catch(err => {
              this.$buefy.snackbar.open({
                message: err,
                type: 'is-danger',
                position: 'is-bottom-right',
                duration: 5000,
              })
              setTimeout(() => {
                this.isUploadingPhoto = false
              }, 500)
            })
        }
      }
    },

    updateUserInfo() {
      BaseService.updateUserInfo(this.userInfo)
        .then(response => {
          if (!response) {
            return
          }
        })
        .catch(err => {
          console.log(err)
        })
    },

    updateSwitch() {
      this.updateUserInfo()
    },

    doLogout() {
      this.$buefy.dialog.confirm({
        message: this.$t('184'),
        onConfirm: () => {
          this.$store.dispatch('fullLogout')
          this.$router.push('/login')
        },
      })
    },

    setPassword() {
      this.showSetPasswordModal = !this.showSetPasswordModal
    },
  },
}
</script>
