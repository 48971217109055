<template>
  <div>
    <b-modal
      :active.sync="isActive"
      :width="640"
      scroll="keep"
      has-modal-card
      trap-focus
      aria-role="dialog"
      aria-modal
      :can-cancel="true"
      :on-cancel="closeModal"
    >
      <div class="modal-card has-text-centered" style="width: auto">
        <section class="modal-card-body">
          <h1>{{ $t('125') }}</h1>
          <div class="has-margin-top has-margin-bottom">
            {{ $t('135') }}
          </div>
          <div>
            <a
              href="https://www.santander.pt/pt_PT/Particulares/Servicos/App-Santander.html"
              target="_blank"
              class="button is-primary"
              >{{ $t('98') }}</a
            >
          </div>
          <div class="has-margin-top">
            <button class="button is-text" @click="closeModal">{{ $t('175') }}</button>
          </div>
        </section>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  props: {
    show: Boolean,
  },
  data() {
    return {
      isActive: this.show || false,
    }
  },

  watch: {
    show(value) {
      this.isActive = value
    },
  },

  methods: {
    closeModal() {
      this.$emit('close')
      this.isActive = false
    },
  },
}
</script>
