<template>
  <MainLayout :title="$t('296')" back-to="/">
    <template #content>
      <div v-if="code" class="has-text-centered has-margin-medium">
        <div
          class="has-text-centered has-background-primary is-size-1 has-text-white is-inline-block has-text-weight-bold code"
        >
          {{ code }}
        </div>
        <div class="has-text-small has-margin-medium">
          {{ $t('298') }}
        </div>
        <div class="has-text-small">
          {{ $t('301') }}
        </div>
      </div>
      <div v-else class="page-icon is-medium">
        <span class="icon">
          <svg
            version="1.1"
            class="fill-current image is-48x48"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 16.571 16.571"
            style="enable-background:new 0 0 16.571 16.571;"
            xml:space="preserve"
          >
            <g>
              <g>
                <circle cx="10.235" cy="1.945" r="0.852" />
                <circle cx="12.816" cy="1.945" r="0.852" />
                <path
                  d="M14.111,0H2.46C1.9,0,1.443,0.447,1.443,0.997l0.059,14.465c0,0.611,0.498,1.109,1.109,1.109
			h11.347c0.612,0,1.11-0.498,1.11-1.109l0.059-14.465C15.128,0.447,14.672,0,14.111,0z M14.329,15.463
			c0,0.203-0.166,0.369-0.37,0.369H2.612c-0.204,0-0.37-0.166-0.37-0.369V4.114c0-0.144,0.083-0.272,0.215-0.334h11.657
			c0.133,0.062,0.215,0.19,0.215,0.334V15.463z M14.388,2.84c0,0.115-0.084,0.216-0.206,0.247H2.389
			C2.268,3.057,2.183,2.956,2.183,2.84V0.997c0-0.142,0.124-0.257,0.277-0.257h11.651c0.152,0,0.276,0.115,0.276,0.257V2.84H14.388z
			"
                />
                <path
                  d="M8.285,4.826c-2.74,0-4.962,2.222-4.962,4.962s2.222,4.962,4.962,4.962s4.963-2.222,4.963-4.962
			C13.248,7.048,11.026,4.826,8.285,4.826z M12.271,9.958c0,2.201-1.784,3.984-3.985,3.984c-2.2,0-3.984-1.783-3.984-3.984
			s0.635-1.263,3.984,0.806C11.635,12.831,12.271,7.756,12.271,9.958z"
                />
              </g>
            </g>
          </svg>
        </span>
      </div>
      <div v-if="code" class="has-text-centered has-text-medium has-margin-large" style="position: relative;">
        <svg :height="radius * 2" :width="radius * 2">
          <circle
            stroke="#6DAA8A"
            :stroke-dasharray="circumference + ' ' + circumference"
            :style="{ strokeDashoffset: strokeDashoffset }"
            :stroke-width="stroke"
            fill="transparent"
            :r="normalizedRadius"
            :cx="radius"
            :cy="radius"
          />

          <circle
            stroke="#6DAA8A"
            :stroke-width="1"
            fill="transparent"
            :r="normalizedRadius - stroke / 2"
            :cx="radius"
            :cy="radius"
          />
          <circle
            stroke="#6DAA8A"
            :stroke-width="1"
            fill="transparent"
            :r="normalizedRadius + stroke / 2"
            :cx="radius"
            :cy="radius"
          />
        </svg>
        <span class="has-text-medium has-text-weight-bold time-left">{{ formatedTimeLeft }}</span>
      </div>

      <div class="has-margin-medium has-text-centered">
        <b-button
          :type="!code ? 'is-primary' : 'is-light'"
          :size="!code ? 'is-medium' : 'is-small'"
          class="button"
          :loading="loading"
          @click="generateCode"
          >{{ $t(code ? '299' : '297') }}</b-button
        >
      </div>

      <div v-if="!code" class="has-text-centered has-text-medium has-margin-large">
        {{ $t('300') }}
      </div>
    </template>
  </MainLayout>
</template>

<script>
import MainLayout from '@/components/MainLayout'
import BaseService from '@/services/BaseService'

export default {
  components: {
    MainLayout,
  },

  data() {
    return {
      loading: false,
      code: null,
      countdownInterval: null,
      validMinutes: 5,
      validMiliseconds: 5 * 60000,
      timeLeft: 0,
      progress: 0,

      // Circle properties
      radius: 100,
      stroke: 8,
    }
  },

  computed: {
    strokeDashoffset() {
      return this.circumference - (this.progress / 100) * this.circumference
    },

    normalizedRadius() {
      return this.radius - this.stroke * 2
    },

    circumference() {
      return this.normalizedRadius * 2 * Math.PI
    },

    formatedTimeLeft() {
      return new Date(this.timeLeft * 1000).toISOString().substring(14, 19)
    },
  },

  mounted() {
    // Get code from localStorage and check if is valid
    let washingMachineCode = localStorage.getItem('settings.washingMachineCode')
    if (washingMachineCode) {
      washingMachineCode = JSON.parse(washingMachineCode)

      const validSeconds = this.validMinutes * 60
      const finalTimeMil = washingMachineCode.createdAt + this.validMiliseconds // miliseconds

      if (Date.now() < finalTimeMil) {
        this.code = washingMachineCode.code
        this.timeLeft = (finalTimeMil - Date.now()) / 1000 // seconds
        this.progress = (100 * (validSeconds - this.timeLeft)) / validSeconds

        this.startCountdown(washingMachineCode.createdAt)
      } else {
        localStorage.removeItem('settings.washingMachineCode')
      }
    }
  },

  beforeDestroy() {
    if (this.countdownInterval) {
      clearTimeout(this.countdownInterval)
    }
  },

  methods: {
    async generateCode() {
      this.timeLeft = 0
      this.progress = 0
      this.clearCode()
      this.loading = true

      try {
        const washingMachineCode = {
          createdAt: Date.now(),
        }

        const response = await BaseService.washingMachineCode()
        this.code = response.data.code
        washingMachineCode['code'] = this.code

        this.timeLeft = this.validMinutes * 60 // seconds
        this.startCountdown(washingMachineCode.createdAt)

        localStorage.setItem('settings.washingMachineCode', JSON.stringify(washingMachineCode))
      } catch (error) {
        console.error(error)
        this.$buefy.snackbar.open({
          duration: 5000,
          message: this.$t('43'),
          type: 'is-danger',
          position: 'is-bottom-left',
          actionText: 'OK',
          queue: false,
        })
      }

      this.loading = false
    },

    clearCode() {
      this.code = null
      clearInterval(this.countdownInterval)
      localStorage.removeItem('settings.washingMachineCode')
    },

    startCountdown(startTime) {
      const step = 100 / 60 / this.validMinutes // seconds
      this.countdownInterval = setInterval(() => {
        if (new Date().getTime() - startTime < this.validMiliseconds) {
          this.progress += step
          this.timeLeft -= 1
        } else {
          this.progress = 100
          this.clearCode()
        }
      }, 1000)
    },
  },
}
</script>

<style lang="scss" scoped>
.code {
  margin: 0 auto;
  padding: 10px 15px;
  border-radius: 15px;
  letter-spacing: 2px;
}
circle {
  transition: stroke-dashoffset 0.35s;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
}
.time-left {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  font-size: 2.5rem;
  color: #6daa8a;
}
</style>
