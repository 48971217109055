<template>
  <div>
    <content-loader
      v-if="type == 'list'"
      :height="200"
      :width="400"
      :speed="1"
      primary-color="#f3f3f3"
      secondary-color="#ecebeb"
    >
      <rect x="0" y="0" rx="0" ry="0" width="400" height="25" />
      <rect x="0" y="40" rx="0" ry="0" width="400" height="25" />
      <rect x="0" y="80" rx="0" ry="0" width="400" height="25" />
      <rect x="0" y="120" rx="0" ry="0" width="400" height="25" />
      <rect x="0" y="160" rx="0" ry="0" width="400" height="25" />
      <rect x="0" y="200" rx="0" ry="0" width="400" height="25" />
      <rect x="0" y="240" rx="0" ry="0" width="400" height="25" />
      <rect x="0" y="280" rx="0" ry="0" width="400" height="25" />
      <rect x="0" y="320" rx="0" ry="0" width="400" height="25" />
      <rect x="0" y="360" rx="0" ry="0" width="400" height="25" />
      <rect x="0" y="400" rx="0" ry="0" width="400" height="25" />
    </content-loader>
    <content-loader
      v-else-if="type == 'credit'"
      :height="40"
      :width="140"
      :speed="1"
      primary-color="#f3f3f3"
      secondary-color="#ecebeb"
    >
      <rect x="43.14" y="5.61" rx="0" ry="0" width="92.66" height="29" />
      <circle cx="18.9" cy="20.37" r="15.76" />
    </content-loader>
  </div>
</template>

<script>
import { ContentLoader } from 'vue-content-loader'
export default {
  components: {
    ContentLoader,
  },
  props: {
    type: String,
  },
}
</script>
