<template>
  <div class="hidden">
    <b-dropdown aria-role="list" position="is-bottom-left">
      <button slot="trigger" class="button" type="button">
        <span>
          <faicon icon="user-circle" />
        </span>
        <faicon icon="chevron-down" size="xs" />
      </button>
      <b-dropdown-item custom aria-role="menuitem">
        <div class="has-text-weight-bold">
          Valentim Branquinho
        </div>
        <div class="has-text-tiny">valentim@framework.pt</div>
      </b-dropdown-item>
      <hr class="dropdown-divider" />
      <b-dropdown-item has-link aria-role="menuitem">
        <router-link to="/account" tag="a">
          Conta
        </router-link>
        <router-link to="/support" tag="a">
          Suporte
        </router-link>
        <router-link to="/logout" tag="a">Terminar</router-link>
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script>
export default {
  props: {
    hideAvatar: Boolean,
  },
  data() {
    return {}
  },
}
</script>
