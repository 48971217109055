<template>
  <DynamicScroller
    :items="list"
    :min-item-size="60"
    :buffer="200"
    key-field="nr_movimento"
    class="scroller"
    @visible="isReady"
  >
    <template v-slot="{ item, index, active }">
      <DynamicScrollerItem :item="item" :active="active" :size-dependencies="[item.id_doc]" :data-index="index">
        <RecordMovement :item="item" />
      </DynamicScrollerItem>
    </template>
  </DynamicScroller>
</template>

<script>
import { DynamicScroller } from 'vue-virtual-scroller'
import { DynamicScrollerItem } from 'vue-virtual-scroller'
import RecordMovement from '@/components/records/RecordMovement'

export default {
  components: {
    DynamicScroller,
    DynamicScrollerItem,
    RecordMovement,
  },
  props: {
    list: Array,
  },
  methods: {
    isReady() {
      this.$emit('is-ready')
    },
  },
}
</script>
