<template>
  <div>
    <router-link :to="to" tag="button" class="button-back"> <faicon icon="chevron-left" /> {{ label }} </router-link>
  </div>
</template>

<script>
export default {
  props: {
    to: String,
    label: String,
  },
}
</script>
