<template>
  <div class="header">
    <div class="inner-mobile">
      <div class="header-left">
        <slot name="mobile-left">
          <div
            v-if="isStaging === true"
            class="tag is-position-absolute is-black has-text-warning is-small has-margin-right"
          >
            STAGING
          </div>

          <b-button v-if="hasBackTo" type="is-text" class="app-title" @click="goBack">
            <faicon v-if="backTo" icon="chevron-left"></faicon>
          </b-button>

          <div v-if="userSummary && !(hasBackTo && backTo)" class="credits-content">
            <span class="credits-icon has-margin-right-small">
              <faicon :icon="userSummary.saldo > 0 ? 'smile' : 'frown'"></faicon>
            </span>
            <span v-if="$route.path != '/'" class="credits-value">{{ userSummary.saldo | currency }}</span>
          </div>
        </slot>
      </div>

      <div class="header-center start-hidden hidden">
        <slot name="mobile-center">
          <div v-if="shortTitle || title">
            <v-clamp autoresize :max-lines="2">{{ shortTitle || title }}</v-clamp>
          </div>
        </slot>
      </div>

      <div class="header-right">
        <slot name="mobile-right">
          <NotificationIcon v-if="hasNotificationIcon && $route.path != '/account'" class="has-margin-right-medium" />
          <CartIcon v-if="hasCartIcon && $route.path != '/account'" />
          <LanguageNav class="has-margin-left-medium" />
        </slot>
      </div>
    </div>

    <div class="inner-desktop">
      <div class="header-left">
        <div class="has-text-weight-bold is-size-2">{{ $t('33') }}</div>
      </div>

      <div class="header-center">
        <div v-cloak class="brand-icon">
          <img :src="assetsPath + 'img/minerva1290.png'" />
        </div>
      </div>

      <div class="header-right">
        <div class="nav-languages has-margin-right">
          <LanguageNav />
        </div>

        <NotificationIcon v-if="hasNotificationIcon" />

        <CartIcon v-if="hasCartIcon" class="has-margin-left" />

        <div v-if="userInfo" class="avatar has-margin-left">
          <div>
            <img v-if="userInfo.foto" :src="'data:image/jpeg;base64,' + userInfo.foto" />
            <faicon v-else icon="user-circle" />
          </div>
        </div>

        <div v-if="this.$store.getters.isLoggedIn" class="nav-session">
          <SessionNav />
        </div>
        <div class="nav-button right">
          <slot name="right"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LanguageNav from '@/components/LanguageNav'
import SessionNav from '@/components/SessionNav'
import NotificationIcon from '@/components/utilities/NotificationIcon'
import CartIcon from '@/components/utilities/CartIcon'
import BaseService from '@/services/BaseService'

export default {
  components: {
    LanguageNav,
    SessionNav,
    NotificationIcon,
    CartIcon,
  },
  props: {
    backTo: String,
    backToHistory: Boolean,
    hasBackTo: Boolean,
    title: String,
    shortTitle: String,
    hasNotificationIcon: {
      type: Boolean,
      default: true,
    },
    hasCartIcon: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      assetsPath: process.env.VUE_APP_ROUTER_BASE,
      updateSummaryTimer: '',
      isStaging: process.env.VUE_APP_IS_STAGING,
    }
  },
  computed: {
    userSummary() {
      return this.$store.getters.getUserSummary
    },
    user() {
      return this.$store.getters.getUser
    },
    userInfo() {
      return this.$store.getters.getUserInfo
    },
  },
  mounted() {
    this.getUserInfo(), (this.updateSummaryTimer = setInterval(this.getUserSummary, 15000))
    this.getUserSummary()
  },
  beforeDestroy() {
    clearInterval(this.updateSummaryTimer)
  },
  methods: {
    goBack() {
      if (this.backTo) {
        this.$router.push(this.backTo)
      } else if (this.backToHistory) {
        this.$router.go(-1)
      } else {
        this.$router.push('/')
      }
    },

    async getUserSummary() {
      const userSummary = await BaseService.getUserSummary(true)
      this.$store.dispatch('setUserSummary', userSummary)
    },

    async getUserInfo(force = false) {
      if (!this.userInfo || force) {
        const userInfo = await BaseService.getUserInfo()
        this.$store.dispatch('setUserInfo', userInfo)
      }
    },
  },
}
</script>
