<template>
  <DynamicScroller
    :items="entries"
    :min-item-size="60"
    :buffer="200"
    key-field="data"
    class="scroller"
    @visible="isReady"
  >
    <template v-slot="{ item, index, active }">
      <DynamicScrollerItem :item="item" :active="active" :size-dependencies="[item.data]" :data-index="index">
        <div class="record">
          <div class="record-content">
            <div class="record-title">
              <span v-if="!item.lida" class="tag is-small is-primary has-margin-right-small">{{ $t('169') }}</span>
              <span v-else class="tag is-small has-margin-right-small">{{ $t('172') }}</span>
              <span :class="{ 'has-text-weight-bold': !item.lida }">{{ item.descricao | breakIfHasBrTag }}</span>
            </div>
            <div class="record-meta is-flex is-flex-space-between">
              <div>{{ item.data }}</div>
              <div v-if="!item.lida">
                <a class="has-text-small is-text" @click.prevent="readOne(item)">{{ $t('171') }}</a>
              </div>
            </div>
          </div>
        </div>
      </DynamicScrollerItem>
    </template>
  </DynamicScroller>
</template>

<script>
import { DynamicScroller } from 'vue-virtual-scroller'
import { DynamicScrollerItem } from 'vue-virtual-scroller'
import BaseService from '@/services/BaseService'

export default {
  components: {
    DynamicScroller,
    DynamicScrollerItem,
  },
  props: {
    list: Array,
  },
  data() {
    return {
      entries: [],
    }
  },
  watch: {
    list(value) {
      this.entries = value
    },
  },
  mounted() {
    this.entries = this.list
  },
  methods: {
    isReady() {
      this.$emit('is-ready')
    },
    async readOne(item) {
      const updated = await BaseService.markNotificationAsRead(item.data)
      if (updated && updated.nr_notificacoes_marcadas) {
        item.lida = true
      }
    },
  },
}
</script>
