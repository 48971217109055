<template>
  <BasicLayout :has-header="false" back-history page-title="Registo">
    <template #content>
      <div class="main-header">
        <div class="logo-sasuc">
          <img :src="assetsPath + 'img/logo-sasuc.png'" />
        </div>
        <div class="app-icon">
          <img :src="assetsPath + 'img/logo-app.png'" />
        </div>
      </div>

      <div v-if="errorPassword" class="has-margin-bottom-medium has-text-centered hidden">
        <p v-if="isInstitutionalEmail">
          <span class="has-text-weight-bold">{{ $t('61') }}?</span><br />
          <span class="has-text-small">
            {{ $t('6') }}
            <a class="has-text-weight-bold" href="https://apps.uc.pt/recover" target="_blank">apps.uc.pt/recover</a>.
          </span>
        </p>
        <a v-else class="has-text-weight-bold" @click="$router.push('/login/recover')">E{{ $t('61') }} ?</a>
      </div>

      <form method="post" @keypress.enter="tryLogin" @submit.prevent="tryLogin">
        <b-field
          label="E-mail"
          label-for="email"
          :type="{ 'is-danger': $v.email.$error }"
          :message="$v.email.$error ? $t('45') : ''"
        >
          <div v-if="!isConfirmedEmail">
            <div>
              <b-input v-model="email" type="email" :placeholder="$t('59')"></b-input>
            </div>
            <div class="has-text-small has-margin-top">
              {{ $t('267') }}
            </div>
          </div>
          <div v-else class="is-size-3 has-margin-bottom">
            {{ email }}
          </div>
        </b-field>
        <b-field
          v-if="isConfirmedEmail"
          label="Password"
          label-for="password"
          :type="{ 'is-danger': $v.password.$error }"
          :message="$v.password.$error ? $t('46') : ''"
        >
          <b-input v-model="password" password-reveal type="password" name="password" :placeholder="$t('46')" />
        </b-field>

        <b-field class="has-margin-top-medium">
          <b-button expanded type="is-primary" @click.prevent="tryLogin">
            {{ isConfirmedEmail ? $t('30') : $t('60') }}
          </b-button>
        </b-field>
      </form>

      <div class="is-flex is-flex-space-between has-margin-top-medium">
        <a v-if="isConfirmedEmail || isInstitutionalEmail" href="" @click.prevent="clearLogin">{{ $t('31') }}</a>
        <a v-if="isConfirmedEmail && !isInstitutionalEmail" href="" @click.prevent="setPassword">{{ $t('281') }}</a>
      </div>
    </template>
    <template #modals>
      <ModalSetPassword :show.sync="showSetPasswordModal" @close="showSetPasswordModal = $event" />
    </template>
  </BasicLayout>
</template>

<script>
import { required, email, minLength } from 'vuelidate/lib/validators'
import BasicLayout from '@/components/BasicLayout'
import AuthService from '@/services/AuthService'
import ModalSetPassword from '@/components/modals/ModalSetPassword'
// import BaseService from '@/services/BaseService'
import localAccounts from '@/utilities/localAccounts'

export default {
  components: {
    BasicLayout,
    ModalSetPassword,
  },
  data() {
    return {
      email: this.$store.state.register.email,
      password: null,
      assetsPath: process.env.VUE_APP_ROUTER_BASE,
      errorPassword: false,
      unknownEmail: false,
      userTypeDetails: null,
      showSetPasswordModal: false,
    }
  },

  validations: {
    email: { required, email, min: minLength(5) },
    password: { required, min: minLength(1) },
  },

  computed: {
    isConfirmedEmail() {
      return this.$store.state.register.emailConfirmed
    },
    isInstitutionalEmail() {
      return AuthService.helpers.isInstitutionalEmail(this.email)
    },
    showRegisterButton() {
      return this.unknownEmail
    },
  },

  mounted() {
    if (this.$store.state.register.token && this.email && this.$store.state.register.finished) {
      this.$store.state.register.emailConfirmed = true
    }
  },

  methods: {
    clearLogin() {
      this.email = null
      this.password = null
      this.$store.dispatch('clearStateLogin')
    },

    async tryLogin() {
      this.$v.$touch()

      // Caso esteja tudo pronto, submete para login
      if (this.isConfirmedEmail && !this.$v.$invalid) {
        this.doLogin()
      }

      // Valida o tipo de email
      // Caso Institucional: apresenta campo para introdução da password - não precisa de registo
      // Caso Externo: valida se o registo existe - é necessário solicitar código de utilização ao backend
      else if (!this.$v.email.$invalid) {
        this.$store.state.register.email = this.email
        const account = localAccounts.getLocalAccounts().find(({ email }) => email === this.email)

        if (account && account.pinToken && account.email) {
          this.$store.state.register.emailConfirmed = true

          localStorage.setItem('session.pinToken', account.pinToken)
          localStorage.setItem('session.email', account.email)

          // Garante que a última conta é guardada na primeira posição da lista de contas locais
          localAccounts.updateLocalAccounts(account)

          this.$router.push('/login/pin')
        } else if (!this.isInstitutionalEmail) {
          if (this.$store.state.register.token && this.$store.state.register.finished) {
            // const user = await BaseService.getUserType(this.email, this.$store.state.register.token)
            // if (user.code == 5 || user.code == 6) {
            this.$store.state.register.emailConfirmed = true
            // }
          } else {
            this.$router.push('/register/code')
          }
        } else {
          this.$store.state.register.emailConfirmed = true
        }
      }
    },

    doLogin() {
      this.$v.$touch()

      // Realiza pedido de login apenas se o formulário for validado
      if (!this.$v.$invalid) {
        // Para garantir que não ficou o token por limpar
        this.$store.dispatch('fullLogout')

        // Utiliza a ação no store para executar o pedido à API
        this.$store
          .dispatch('login', {
            email: this.email,
            password: this.password,
          })
          .then(() => {
            this.$router.push('/login/set/pin')
          })
          .catch(err => {
            let errorMessage = err

            // Utilizador não encontrado
            if (err == this.$t('9')) {
              errorMessage = this.$t('10')
            }

            // Login errado
            else if (err.includes(this.$t('11'))) {
              errorMessage = this.$t('7')
              this.errorPassword = true
            }

            // Login errado - última tentativa
            else if (err.includes(this.$t('12'))) {
              errorMessage = this.$t('13')
              this.errorPassword = true
            }

            // Conta bloqueada
            else if (err.includes(this.$t('14'))) {
              errorMessage =
                `<p class="has-text-weight-bold is-size-3">` +
                this.$t('8') +
                `</p><p>` +
                this.$t('15') +
                ` <a href="mailto:gabadmin@sas.uc.pt">gabadmin@sas.uc.pt</a></p>`
              this.errorPassword = true
            }

            this.$buefy.dialog.alert({
              indefinite: true,
              message: errorMessage,
              type: 'is-black',
              position: 'is-bottom-left',
              actionText: 'OK',
              queue: false,
            })
          })
      }
    },

    setPassword() {
      this.showSetPasswordModal = !this.showSetPasswordModal
    },
  },
}
</script>
