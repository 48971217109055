<template>
  <div class="basic-layout">
    <div v-if="hasHeader" class="header">
      <slot name="header">
        <div class="inner-mobile">
          <div class="header-left">
            <b-button v-if="hasBackTo" type="is-text" class="app-title" @click="goBackButton">
              <faicon v-if="showBackButton" icon="chevron-left"></faicon>
            </b-button>
          </div>
          <div class="header-center start-hidden">
            <div v-if="pageTitle">
              <v-clamp autoresize :max-lines="2">{{ pageTitle }}</v-clamp>
            </div>
          </div>
          <div class="header-right">
            <LanguageNav />
          </div>
        </div>
      </slot>
    </div>
    <div v-else><LanguageNav /></div>
    <div class="main">
      <div class="inner">
        <div class="main-content">
          <div class="inner">
            <slot name="content"></slot>
          </div>
        </div>
      </div>
    </div>
    <slot name="modals"></slot>
  </div>
</template>

<script>
import LanguageNav from '@/components/LanguageNav'

export default {
  components: {
    LanguageNav,
  },
  props: {
    backTo: String,
    backHistory: Boolean,
    hasBackTo: {
      default: true,
      type: Boolean,
    },
    pageTitle: String,
    hasHeader: {
      default: true,
      type: Boolean,
    },
  },
  computed: {
    showBackButton() {
      return true
    },
  },
  methods: {
    goBackButton() {
      if (this.backTo) {
        this.$router.push(this.backTo)
      } else if (this.backHistory) {
        this.$router.go(-1)
      } else {
        this.$router.push('/')
      }
    },
  },
}
</script>
