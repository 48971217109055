export default [
  {
    key: 'AF',
    title: 'Afeganist\u00e3o',
  },
  {
    key: 'AL',
    title: 'Alb\u00e2nia',
  },
  {
    key: 'DE',
    title: 'Alemanha',
  },
  {
    key: 'AD',
    title: 'Andorra',
  },
  {
    key: 'AO',
    title: 'Angola',
  },
  {
    key: 'AI',
    title: 'Anguilla',
  },
  {
    key: 'AQ',
    title: 'Ant\u00e1rctida',
  },
  {
    key: 'AG',
    title: 'Ant\u00edgua e Barbuda',
  },
  {
    key: 'AR',
    title: 'Argentina',
  },
  {
    key: 'DZ',
    title: 'Arg\u00e9lia',
  },
  {
    key: 'AM',
    title: 'Arm\u00e9nia',
  },
  {
    key: 'AW',
    title: 'Aruba',
  },
  {
    key: 'SA',
    title: 'Ar\u00e1bia Saudita',
  },
  {
    key: 'AU',
    title: 'Austr\u00e1lia',
  },
  {
    key: 'AZ',
    title: 'Azerbaij\u00e3o',
  },
  {
    key: 'BS',
    title: 'Bahamas',
  },
  {
    key: 'BD',
    title: 'Bangladesh',
  },
  {
    key: 'BB',
    title: 'Barbados',
  },
  {
    key: 'BH',
    title: 'Barein',
  },
  {
    key: 'BZ',
    title: 'Belize',
  },
  {
    key: 'BJ',
    title: 'Benim',
  },
  {
    key: 'BM',
    title: 'Bermuda',
  },
  {
    key: 'BY',
    title: 'Bielor\u00fassia',
  },
  {
    key: 'BO',
    title: 'Bol\u00edvia',
  },
  {
    key: 'BQ',
    title: 'Bonaire, Santo Eust\u00e1quio e Saba',
  },
  {
    key: 'BW',
    title: 'Botsuana',
  },
  {
    key: 'BR',
    title: 'Brasil',
  },
  {
    key: 'BN',
    title: 'Brunei',
  },
  {
    key: 'BG',
    title: 'Bulg\u00e1ria',
  },
  {
    key: 'BF',
    title: 'Burkina Faso',
  },
  {
    key: 'BI',
    title: 'Burundi',
  },
  {
    key: 'BT',
    title: 'But\u00e3o',
  },
  {
    key: 'BE',
    title: 'B\u00e9lgica',
  },
  {
    key: 'BA',
    title: 'B\u00f3snia-Herzeg\u00f3vina',
  },
  {
    key: 'CV',
    title: 'Cabo Verde',
  },
  {
    key: 'CM',
    title: 'Camar\u00f5es',
  },
  {
    key: 'KH',
    title: 'Cambodja',
  },
  {
    key: 'CA',
    title: 'Canad\u00e1',
  },
  {
    key: 'KZ',
    title: 'Cazaquist\u00e3o',
  },
  {
    key: 'TD',
    title: 'Chade',
  },
  {
    key: 'CL',
    title: 'Chile',
  },
  {
    key: 'CN',
    title: 'China',
  },
  {
    key: 'CY',
    title: 'Chipre',
  },
  {
    key: 'CO',
    title: 'Col\u00f4mbia',
  },
  {
    key: 'KM',
    title: 'Comoros',
  },
  {
    key: 'CG',
    title: 'Congo',
  },
  {
    key: 'CD',
    title: 'Congo, Rep\u00fablica Democr\u00e1tica do',
  },
  {
    key: 'KP',
    title: 'Coreia, Rep\u00fablica Popular Democr\u00e1tica de ',
  },
  {
    key: 'KR',
    title: 'Coreia, Rep\u00fablica da',
  },
  {
    key: 'CR',
    title: 'Costa Rica',
  },
  {
    key: 'CI',
    title: 'Costa do Marfim',
  },
  {
    key: 'HR',
    title: 'Cro\u00e1cia',
  },
  {
    key: 'CU',
    title: 'Cuba',
  },
  {
    key: 'CW',
    title: 'Cura\u00e7\u00e3o',
  },
  {
    key: 'CZ',
    title: 'Czechia',
  },
  {
    key: 'DK',
    title: 'Dinamarca',
  },
  {
    key: 'DJ',
    title: 'Djibouti',
  },
  {
    key: 'DM',
    title: 'Dominica',
  },
  {
    key: 'EG',
    title: 'Egipto',
  },
  {
    key: 'SV',
    title: 'El Salvador',
  },
  {
    key: 'AE',
    title: 'Emirados \u00c1rabes Unidos',
  },
  {
    key: 'EC',
    title: 'Equador',
  },
  {
    key: 'ER',
    title: 'Eritreia',
  },
  {
    key: 'SK',
    title: 'Eslov\u00e1quia',
  },
  {
    key: 'SI',
    title: 'Eslov\u00e9nia',
  },
  {
    key: 'ES',
    title: 'Espanha',
  },
  {
    key: 'US',
    title: 'Estados Unidos',
  },
  {
    key: 'EE',
    title: 'Est\u00f3nia',
  },
  {
    key: 'ET',
    title: 'Eti\u00f3pia',
  },
  {
    key: 'RU',
    title: 'Federa\u00e7\u00e3o Russa',
  },
  {
    key: 'FJ',
    title: 'Fiji',
  },
  {
    key: 'PH',
    title: 'Filipinas',
  },
  {
    key: 'FI',
    title: 'Finl\u00e2ndia',
  },
  {
    key: 'FR',
    title: 'Fran\u00e7a',
  },
  {
    key: 'GA',
    title: 'Gab\u00e3o',
  },
  {
    key: 'GH',
    title: 'Gana',
  },
  {
    key: 'GE',
    title: 'Ge\u00f3rgia',
  },
  {
    key: 'GS',
    title: 'Ge\u00f3rgia do Sul e Ilhas Sandwich do Sul',
  },
  {
    key: 'GI',
    title: 'Gibraltar',
  },
  {
    key: 'GD',
    title: 'Granada',
  },
  {
    key: 'GL',
    title: 'Gronel\u00e2ndia',
  },
  {
    key: 'GR',
    title: 'Gr\u00e9cia',
  },
  {
    key: 'GP',
    title: 'Guadalupe',
  },
  {
    key: 'GU',
    title: 'Guam',
  },
  {
    key: 'GT',
    title: 'Guatemala',
  },
  {
    key: 'GG',
    title: 'Guernsey',
  },
  {
    key: 'GY',
    title: 'Guiana',
  },
  {
    key: 'GF',
    title: 'Guiana Francesa',
  },
  {
    key: 'GN',
    title: 'Guin\u00e9',
  },
  {
    key: 'GQ',
    title: 'Guin\u00e9 Equatorial',
  },
  {
    key: 'GW',
    title: 'Guin\u00e9-Biss\u00e1u',
  },
  {
    key: 'GM',
    title: 'G\u00e2mbia',
  },
  {
    key: 'HT',
    title: 'Haiti',
  },
  {
    key: 'HN',
    title: 'Honduras',
  },
  {
    key: 'HK',
    title: 'Hong Kong',
  },
  {
    key: 'HU',
    title: 'Hungria',
  },
  {
    key: 'BV',
    title: 'Ilha Bouvet',
  },
  {
    key: 'HM',
    title: 'Ilha Heard e Ilhas McDonald',
  },
  {
    key: 'CX',
    title: 'Ilha Natal',
  },
  {
    key: 'NF',
    title: 'Ilha Norfolk',
  },
  {
    key: 'RE',
    title: 'Ilha Reuni\u00e3o',
  },
  {
    key: 'IM',
    title: 'Ilha de Man',
  },
  {
    key: 'KY',
    title: 'Ilhas Caim\u00e3o',
  },
  {
    key: 'CC',
    title: 'Ilhas Cocos',
  },
  {
    key: 'CK',
    title: 'Ilhas Cook',
  },
  {
    key: 'FK',
    title: 'Ilhas Falkland (Malvinas)',
  },
  {
    key: 'FO',
    title: 'Ilhas Fero\u00e9',
  },
  {
    key: 'MP',
    title: 'Ilhas Marianas do Norte',
  },
  {
    key: 'MH',
    title: 'Ilhas Marshall',
  },
  {
    key: 'UM',
    title: 'Ilhas Menores Exteriores dos Estados Unidos',
  },
  {
    key: 'SB',
    title: 'Ilhas Salom\u00e3o',
  },
  {
    key: 'TC',
    title: 'Ilhas Turks e Caicos',
  },
  {
    key: 'VG',
    title: 'Ilhas Virgens, Brit\u00e2nicas',
  },
  {
    key: 'VI',
    title: 'Ilhas Virgens, Estados Unidos',
  },
  {
    key: 'AX',
    title: 'Ilhas \u00c5land',
  },
  {
    key: 'ID',
    title: 'Indon\u00e9sia',
  },
  {
    key: 'IQ',
    title: 'Iraque',
  },
  {
    key: 'IE',
    title: 'Irlanda',
  },
  {
    key: 'IR',
    title: 'Ir\u00e3o, Rep\u00fablica Isl\u00e2mica do',
  },
  {
    key: 'IS',
    title: 'Isl\u00e2ndia',
  },
  {
    key: 'IL',
    title: 'Israel',
  },
  {
    key: 'IT',
    title: 'It\u00e1lia',
  },
  {
    key: 'JM',
    title: 'Jamaica',
  },
  {
    key: 'JP',
    title: 'Jap\u00e3o',
  },
  {
    key: 'JE',
    title: 'Jersey',
  },
  {
    key: 'JO',
    title: 'Jord\u00e2nia',
  },
  {
    key: 'KI',
    title: 'Kiribati',
  },
  {
    key: 'KW',
    title: 'Kuwait',
  },
  {
    key: 'LS',
    title: 'Lesoto',
  },
  {
    key: 'LV',
    title: 'Let\u00f3nia',
  },
  {
    key: 'LR',
    title: 'Lib\u00e9ria',
  },
  {
    key: 'LI',
    title: 'Liechtenstein',
  },
  {
    key: 'LT',
    title: 'Litu\u00e2nia',
  },
  {
    key: 'LU',
    title: 'Luxemburgo',
  },
  {
    key: 'LB',
    title: 'L\u00edbano',
  },
  {
    key: 'LY',
    title: 'L\u00edbia',
  },
  {
    key: 'MO',
    title: 'Macau',
  },
  {
    key: 'MK',
    title: 'Maced\u00f3nia, Rep\u00fablica',
  },
  {
    key: 'MG',
    title: 'Madag\u00e1scar',
  },
  {
    key: 'MW',
    title: 'Malawi',
  },
  {
    key: 'MV',
    title: 'Maldivas',
  },
  {
    key: 'ML',
    title: 'Mali',
  },
  {
    key: 'MT',
    title: 'Malta',
  },
  {
    key: 'MY',
    title: 'Mal\u00e1sia',
  },
  {
    key: 'MA',
    title: 'Marrocos',
  },
  {
    key: 'MQ',
    title: 'Martinica',
  },
  {
    key: 'MR',
    title: 'Maurit\u00e2nia',
  },
  {
    key: 'MU',
    title: 'Maur\u00edcias',
  },
  {
    key: 'YT',
    title: 'Mayotte',
  },
  {
    key: 'FM',
    title: 'Micronesia, Estados Federados da',
  },
  {
    key: 'MD',
    title: 'Mold\u00e1via',
  },
  {
    key: 'MN',
    title: 'Mong\u00f3lia',
  },
  {
    key: 'ME',
    title: 'Montenegro',
  },
  {
    key: 'MS',
    title: 'Montserrat',
  },
  {
    key: 'MZ',
    title: 'Mo\u00e7ambique',
  },
  {
    key: 'MM',
    title: 'Myanmar',
  },
  {
    key: 'MX',
    title: 'M\u00e9xico',
  },
  {
    key: 'MC',
    title: 'M\u00f3naco',
  },
  {
    key: 'NA',
    title: 'Nam\u00edbia',
  },
  {
    key: 'NR',
    title: 'Nauru',
  },
  {
    key: 'NP',
    title: 'Nepal',
  },
  {
    key: 'NI',
    title: 'Nicar\u00e1gua',
  },
  {
    key: 'NG',
    title: 'Nig\u00e9ria',
  },
  {
    key: 'NU',
    title: 'Niue',
  },
  {
    key: 'NO',
    title: 'Noruega',
  },
  {
    key: 'NC',
    title: 'Nova Caled\u00f3nia',
  },
  {
    key: 'NZ',
    title: 'Nova Zel\u00e2ndia',
  },
  {
    key: 'NE',
    title: 'N\u00edger',
  },
  {
    key: 'OM',
    title: 'Oman',
  },
  {
    key: 'PW',
    title: 'Palau',
  },
  {
    key: 'PS',
    title: 'Palestina, Autoridade da',
  },
  {
    key: 'PA',
    title: 'Panam\u00e1',
  },
  {
    key: 'PG',
    title: 'Papua Nova Guin\u00e9',
  },
  {
    key: 'PK',
    title: 'Paquist\u00e3o',
  },
  {
    key: 'PY',
    title: 'Paraguai',
  },
  {
    key: 'NL',
    title: 'Pa\u00edses Baixos',
  },
  {
    key: 'PE',
    title: 'Peru',
  },
  {
    key: 'PN',
    title: 'Pitcairn',
  },
  {
    key: 'PF',
    title: 'Polin\u00e9sia Francesa',
  },
  {
    key: 'PL',
    title: 'Pol\u00f3nia',
  },
  {
    key: 'PR',
    title: 'Porto Rico',
  },
  {
    key: 'PT',
    title: 'Portugal',
  },
  {
    key: 'QA',
    title: 'Quatar',
  },
  {
    key: 'KG',
    title: 'Quirgist\u00e3o',
  },
  {
    key: 'KE',
    title: 'Qu\u00e9nia',
  },
  {
    key: 'GB',
    title: 'Reino Unido',
  },
  {
    key: 'CF',
    title: 'Rep\u00fablica Centro-Africana',
  },
  {
    key: 'LA',
    title: 'Rep\u00fablica Democr\u00e1tica Popular do Laos',
  },
  {
    key: 'DO',
    title: 'Rep\u00fablica Dominicana',
  },
  {
    key: 'SS',
    title: 'Rep\u00fablica do Sud\u00e3o',
  },
  {
    key: 'SY',
    title: 'Rep\u00fablica \u00c1rabe S\u00edria',
  },
  {
    key: 'RO',
    title: 'Rom\u00e9nia',
  },
  {
    key: 'RW',
    title: 'Ruanda',
  },
  {
    key: 'EH',
    title: 'Sahara Ocidental',
  },
  {
    key: 'BL',
    title: 'Saint Barth\u00e9lemy',
  },
  {
    key: 'KN',
    title: 'Saint Kitts e Nevis',
  },
  {
    key: 'PM',
    title: 'Saint Pierre e Miquelon',
  },
  {
    key: 'WS',
    title: 'Samoa',
  },
  {
    key: 'AS',
    title: 'Samoa Americana',
  },
  {
    key: 'SM',
    title: 'San Marino',
  },
  {
    key: 'SH',
    title: 'Santa Helema, Ascen\u00e7\u00e3o e Trist\u00e3o da Cunha',
  },
  {
    key: 'LC',
    title: 'Santa L\u00facia',
  },
  {
    key: 'VA',
    title: 'Santa S\u00e9 (Estado Cidade do Vaticano)',
  },
  {
    key: 'SN',
    title: 'Senegal',
  },
  {
    key: 'SL',
    title: 'Serra Leoa',
  },
  {
    key: 'SC',
    title: 'Seychelles',
  },
  {
    key: 'SG',
    title: 'Singapura',
  },
  {
    key: 'SO',
    title: 'Som\u00e1lia',
  },
  {
    key: 'LK',
    title: 'Sri Lanka',
  },
  {
    key: 'SZ',
    title: 'Suazil\u00e2ndia',
  },
  {
    key: 'SD',
    title: 'Sud\u00e3o',
  },
  {
    key: 'SR',
    title: 'Suriname',
  },
  {
    key: 'SE',
    title: 'Su\u00e9cia',
  },
  {
    key: 'CH',
    title: 'Su\u00ed\u00e7a',
  },
  {
    key: 'SJ',
    title: 'Svalbard e Jan Mayen',
  },
  {
    key: 'MF',
    title: 'S\u00e3o Martin (Territ\u00f3rio Franc\u00eas)',
  },
  {
    key: 'SX',
    title: 'S\u00e3o Martinho (Pa\u00edses Baixos)',
  },
  {
    key: 'ST',
    title: 'S\u00e3o Tom\u00e9 e Pr\u00edncipe',
  },
  {
    key: 'VC',
    title: 'S\u00e3o Vicente e Grenadinas',
  },
  {
    key: 'RS',
    title: 'S\u00e9rvia',
  },
  {
    key: 'TJ',
    title: 'Tadjaquist\u00e3o',
  },
  {
    key: 'TH',
    title: 'Tail\u00e2ndia',
  },
  {
    key: 'TW',
    title: 'Taiwan',
  },
  {
    key: 'TZ',
    title: 'Tanzania',
  },
  {
    key: 'IO',
    title: 'Territ\u00f3rio Brit\u00e2nico do Oceano \u00cdndico',
  },
  {
    key: 'TF',
    title: 'Territ\u00f3rios Franceses do Sul',
  },
  {
    key: 'TL',
    title: 'Timor-Leste',
  },
  {
    key: 'TG',
    title: 'Togo',
  },
  {
    key: 'TK',
    title: 'Tokelau',
  },
  {
    key: 'TO',
    title: 'Tonga',
  },
  {
    key: 'TT',
    title: 'Trinidade e Tobago',
  },
  {
    key: 'TN',
    title: 'Tun\u00edsia',
  },
  {
    key: 'TM',
    title: 'Turcomenist\u00e3o',
  },
  {
    key: 'TR',
    title: 'Turquia',
  },
  {
    key: 'TV',
    title: 'Tuvalu',
  },
  {
    key: 'UA',
    title: 'Ucr\u00e2nia',
  },
  {
    key: 'UG',
    title: 'Uganda',
  },
  {
    key: 'UY',
    title: 'Uruguai',
  },
  {
    key: 'UZ',
    title: 'Uzbequist\u00e3o',
  },
  {
    key: 'VU',
    title: 'Vanuatu',
  },
  {
    key: 'VE',
    title: 'Venezuela',
  },
  {
    key: 'VN',
    title: 'Vietnam',
  },
  {
    key: 'WF',
    title: 'Wallis e Futuna',
  },
  {
    key: 'YE',
    title: 'Y\u00e9men',
  },
  {
    key: 'ZW',
    title: 'Zimbabwe',
  },
  {
    key: 'ZM',
    title: 'Z\u00e2mbia',
  },
  {
    key: 'ZA',
    title: '\u00c1frica do Sul',
  },
  {
    key: 'AT',
    title: '\u00c1ustria',
  },
  {
    key: 'IN',
    title: '\u00cdndia',
  },
]
