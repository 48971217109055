<template>
  <MainLayout :title="$t('124')" short-title="CC" back-to="/recharge" :has-notification-icon="false">
    <template v-slot:content>
      <div v-if="!showSibsForm" class="page-icon">
        <span class="icon">
          <faicon icon="credit-card"></faicon>
        </span>
      </div>
      <div v-if="!loading" class="has-text-centered width-small">
        <div v-if="!showSibsForm">
          <div class="has-margin-top-large">
            <div class="is-meta">{{ $t('91') }} (€)</div>
            <div class="has-margin-top">
              <b-numberinput
                v-model="newAmountValue"
                :min="mbSettings.min"
                :max="mbSettings.max"
                :step="mbSettings.inc"
                type="is-dark"
              ></b-numberinput>
            </div>
          </div>
          <div class="has-margin-top-large">
            <button class="button is-primary is-fullwidth" @click="callCheckout">{{ $t('103') }}</button>
          </div>
        </div>
        <div v-else class="has-margin-top">
          <div class="is-meta">{{ $t('91') }}</div>
          <div class="is-size-1">{{ newAmountValue | currency }}</div>
        </div>
      </div>
      <div v-if="!loading && showSibsForm" class="has-margin-top-large">
        <form :action="afterPaymentUrl" class="paymentWidgets" :data-brands="dataBrands"></form>
        <div class="has-margin-top has-text-centered">
          <button class="button is-text is-small" @click="$router.push('/recharge/methods')">
            {{ $t('31') }}
          </button>
        </div>
      </div>
      <div class="is-flex is-flex-align-center is-flex-justify-center has-margin-top">
        <img :src="assetsPath + 'img/mastercard.gif'" class="img-mastercard" />
        <img :src="assetsPath + 'img/visa.png'" class="img-visa" />
      </div>
    </template>
    <template #modals>
      <b-modal
        :active="isModalPaymentOkActive"
        :width="500"
        scroll="keep"
        custom-class="hide-close"
        has-modal-card
        trap-focus
        aria-role="dialog"
        aria-modal
        @close="goToRecharge"
      >
        <div class="modal-card has-margin" style="width: auto">
          <section class="modal-card-body">
            <h3>{{ $t(234) }}</h3>
            <p>{{ $t(235) }}</p>
            <div class="is-pulled-right has-margin-top">
              <button class="button is-text" @click="goToRecharge">{{ $t(236) }}</button>
            </div>
          </section>
        </div>
      </b-modal>
    </template>
  </MainLayout>
</template>

<script>
import MainLayout from '@/components/MainLayout'
import BaseService from '@/services/BaseService'

export default {
  components: {
    MainLayout,
  },
  data() {
    return {
      loading: false,
      showSibsForm: false,
      entries: [],
      newAmountValue: 0,
      mbSettings: {},
      dataBrands: 'VISA MASTER AMEX',
      afterPaymentUrl: window.location.href,
      isModalPaymentOkActive: false,
      assetsPath: process.env.VUE_APP_ROUTER_BASE,
    }
  },

  computed: {
    userSummary() {
      return this.$store.getters.getUserSummary
    },
    userInfo() {
      return this.$store.getters.getUserInfo
    },
  },

  mounted() {
    this.getMbSettings()
    this.checkResponseFromCheckout()
  },

  methods: {
    goToRecharge() {
      this.$router.push('/recharge')
    },

    initSibsForm(scriptUrl) {
      window.user = this.userInfo
      window.sibsCheckCardHolder = () => {
        const holder = document.querySelector('.wpwl-control-cardHolder')
        if (holder.value.trim().length < 2) {
          holder.classList.add('wpwl-has-error')

          let errorLabel = document.createElement('div')
          errorLabel.className = 'wpwl-hint wpwl-hint-cardHolderError'
          errorLabel.innerText = 'Titular obrigatório'

          holder.parentNode.insertBefore(errorLabel, holder.nextSibling)

          return false
        }
        return true
      }

      window.buefy = this.$buefy

      // https://sibs.docs.onlinepayments.pt/tutorials/integration-guide/widget-api
      window.wpwlOptions = {
        style: 'card',
        locale: 'pt',
        paymentTarget: '_top',
        billingAddress: {
          country: '',
          city: this.userInfo.localidade,
          postcode: this.userInfo.cod_postal,
          street1: this.userInfo.morada,
        },
        mandatoryBillingFields: {
          country: true,
          city: true,
          state: false,
          postcode: true,
          street1: true,
        },
        useSummaryPage: false,
        onSaveTransactionData: function(data) {
          if (data.status && data.status != 200) {
            window.buefy.dialog.alert({
              indefinite: true,
              message: `<div>
      <h2 class="has-text-danger">Ocorreu um erro</h2>
      <p class="is-meta has-margin-top">${data.responseText}</p>
      <p class="has-margin-top has-text-weight-bold has-text-small">Esta janela é temporária e serve apenas para DEBUG.</p>
      </div>`,
              type: 'is-danger',
              position: 'is-bottom-left',
              actionText: 'OK',
              queue: false,
              onAction: () => {
                window.location.href = window.afterPaymentUrl
              },
            })
          } else {
            window.buefy.dialog.alert({
              indefinite: true,
              message: `<div>
                <h2>O pagamento foi submetido com sucesso</h2>
                <p class="has-margin-top">(Code: ${data.result.code}) ${data.result.description}</p>
                <p class="has-margin-top has-text-weight-bold has-text-small">Esta janela é temporária e serve apenas para DEBUG.</p>
                </div>`,
              type: 'is-primary',
              position: 'is-bottom-left',
              actionText: 'OK',
              queue: false,
              onAction: () => {
                window.location.href = window.afterPaymentUrl
              },
            })
          }
        },
        onBeforeSubmitCard: function() {
          return window.sibsCheckCardHolder()
        },
        onReady: function() {
          document.getElementsByName('card.holder')[0].value = window.user.nome
          document.getElementsByName('billing.city')[0].placeholder = 'Cidade'
          document.getElementsByName('billing.postcode')[0].placeholder = 'Cód. Postal'
          document.getElementsByName('billing.street1')[0].placeholder = 'Morada'

          // document.getElementsByName('billing.country')[0].remove()
          document.querySelector('.wpwl-control-country [value="PT"]').selected = true

          let groupCvvElement = document.getElementsByClassName('wpwl-group-cvv')[0]
          let userDivWrapper = document.createElement('div')
          const userEmail = localStorage.getItem('session.email')
          userDivWrapper.innerHTML = `
            <div class='wpwl-group wpwl-group-costumerEmail wpwl-clearfix'>
              <div class='wpwl-label wpwl-label-costumerEmail' name='wpwl-label-customer.email'>Email</div>
              <div class='wpwl-wrapper wpwl-wrapper-costumerEmail'>
                <input class='wpwl-control wpwl-control-costumerEmail' name='customer.email' placeholder='Email'
                  type='text' value='${userEmail}' aria-label='Email' />
              </div>
            </div>`

          groupCvvElement.parentNode.insertBefore(userDivWrapper, groupCvvElement.nextSibling)

          // Make sure car-holder is mandatory
          document.addEventListener(
            'click',
            function(event) {
              if (!event.target.matches('.wpwl-button-pay')) return false
              return window.sibsCheckCardHolder()
            },
            false
          )
        },
      }

      let sibsForm = document.createElement('script')
      //sibsForm.setAttribute('src', scriptUrl.replace('https://test.onlinepayments.pt', 'https://test.oppwa.com'))
      sibsForm.setAttribute('src', scriptUrl)
      document.head.appendChild(sibsForm)
      this.showSibsForm = true
    },

    checkResponseFromCheckout() {
      // Caso exista id definido pelo redirect do CC, apresenta modal a indicar ao utilizador
      // que deve confirmar os movimentos
      if (this.$route.query.id) {
        this.isModalPaymentOkActive = true
      }
    },

    getMbSettings() {
      BaseService.getMbSettings().then(response => {
        this.mbSettings = response
        this.newAmountValue = response.min
      })
    },

    async callCheckout() {
      // Garante que o utilizador não altera os valores no input
      if (this.newAmountValue < this.mbSettings.min || this.newAmountValue > this.mbSettings.max) {
        this.$buefy.snackbar.open({
          duration: 5000,
          message: this.$t(237) + ' ' + this.mbSettings.min + ' ' + this.$t(242) + ' ' + this.mbSettings.max + '.',
          type: 'is-danger',
          position: 'is-bottom-left',
          actionText: 'OK',
          queue: false,
        })
      } else {
        await BaseService.requestNewCheckoutId(this.dataBrands, this.newAmountValue)
          .then(response => {
            this.initSibsForm(response.script_url)
          })
          .catch(err => {
            this.$buefy.dialog.alert({
              indefinite: true,
              message:
                `<div class="has-text-centered">
              <h2 class="has-text-danger is-width-75">` +
                this.$t(238) +
                `</h2>
              <p class="is-meta has-margin-top">${err}</p>
              </div>`,
              type: 'is-danger',
              position: 'is-bottom-left',
              actionText: 'OK',
              queue: false,
              onAction: () => {},
            })
          })
      }
    },
  },
}
</script>
