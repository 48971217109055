/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icon-qrcode': {
    width: 63,
    height: 63,
    viewBox: '0 0 63 63',
    data: '<g clip-path="url(#clip0)"><path pid="0" d="M15.002 33.002h3.003v3.003h3v3.001H6.005v-3h3v-6.004h6.004v3h-.006zm39.004 27h2.997V57h-2.997v3.001zm-36.003-27h3v-3h-3v3zm41.996 27h3.003V57H60v3.001zM24.006 63h5.998v-3h-5.998v3zm26.997 0v-3H48v3h3.003zm-11.999 0h3v-6.002h-3V63zM15.002 24.002v3.001h9v-3h-9zm-2.997 2.999v-3H6.002v6h3v-3h3.003zm8.998-6.002H.005V.002h21V21zm-3-17.996H3V18h15.002V3.003zM6.002 57H15v-9H6.002v9zm30-3.003V57h3.004v-3.003h-3.005zm-21-47.994H6.004V15h8.998V6.004zM63 .002v21H42v-21h21zm-3 3H45.001v14.999h14.997V3.003zM0 41.997h21.001v21H0v-21zm3.003 18.005h15.002V45H3.003v15.002zm0-35.999H0v15.002h3.003V24.002zM42 39v2.996h3V39h-3zM33 51v-3h-3v3.002h-5.997v6.004h5.998v3H33v-6.003h3.003v-3H33V51zm-8.995-38.998h5.998V8.998h-5.998v3.005zm23.996 24h6.006v3.001h2.999v-8.998h-2.999v-6.004h-3.005v9H42.01v3.003h3v3.001h3.002v-3h-.009v-.003zM51.003 45H48v-3.003h-3V48H36v3.003h6.006v6.004h3v3h3.002v-6.003h11.996v-3h-8.998v-6.005h-.004zm0 0h3.005v-6h-3.005v6zm-24 0v-3.003H30V39h2.998v-3h3.003v-6.005H45v-6.003h-3v3h-3.001V14.993h-3.001V8.99h3V0h-3v6.002h-3V0h-8.999v6.002h3V3h2.997v5.997h3.001v9h3.001V21h-3v6.002h-3.002v-6.002H27v-2.998h-3.001v6.001h3v3.001h-3v9.002h3v-6.003h2.997v6.003H27v3.001h-3.001V48h5.997v-3h-2.992zM59.999 48v-3h-5.997v3h5.997zM57 6.004h-8.997V15h8.996V6.004zM33 44.999h6.006v-3.003h-3.005V39h-3.003v6H33zm6.004-6v-3H36v3h3.005zm17.994-11.998h6.004v-3h-6.004v3zM60 51h3.003v-3H60v3zm0-17.998h3.003v-3.001H60v3zM33 18h-2.998v3H33v-3z" _fill="#fff"/></g><defs><clipPath id="clip0"><path pid="1" _fill="#fff" d="M0 0h63v63H0z"/></clipPath></defs>'
  }
})
