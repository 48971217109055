import Login from '@/views/auth/Login'
import LoginSetPin from '@/views/auth/LoginSetPin'
import LoginPin from '@/views/auth/LoginPin'
import Register from '@/views/auth/Register'
import RegisterCode from '@/views/auth/RegisterCode'
import RegisterOK from '@/views/auth/RegisterOK'

export default [
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: { requiresNoAuth: true, layout: 'basic' },
  },
  {
    path: '/login/set/pin',
    name: 'login-set-pin',
    component: LoginSetPin,
    meta: { requiresAuth: true, layout: 'basic' },
  },
  {
    path: '/login/pin',
    name: 'login-pin',
    component: LoginPin,
    meta: { requiresNoAuth: true, layout: 'basic' },
  },
  {
    path: '/register',
    name: 'register',
    component: Register,
    meta: { requiresNoAuth: true, layout: 'basic' },
  },
  {
    path: '/register/code',
    name: 'register-code',
    component: RegisterCode,
    meta: { requiresNoAuth: true, layout: 'basic' },
  },
  {
    path: '/register/ok',
    name: 'register-ok',
    component: RegisterOK,
    meta: { requiresNoAuth: true, layout: 'basic' },
  },
]
