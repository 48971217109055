<template>
  <BasicLayout title="Registo" :has-back-to="false">
    <template slot="content">
      <h1 v-if="!pin" class="has-margin-bottom-small">{{ $t(230) }}</h1>
      <h1 v-else class="has-margin-bottom-small">{{ $t(231) }}</h1>
      <p v-if="error" class="has-text-centered">
        {{ $t(232) }}<br /><small>{{ $t(233) }}</small>
      </p>
      <PinPanel
        :reset.sync="resetPinPanel"
        @update="setPin"
        @cancel="cancel"
        @clear="clearPin"
        @key-pressed="keyPressed"
      />
    </template>
  </BasicLayout>
</template>

<script>
import BasicLayout from '@/components/BasicLayout'
import PinPanel from '@/components/utilities/PinPanel'

export default {
  components: {
    BasicLayout,
    PinPanel,
  },
  data() {
    return {
      pin: '',
      confirmPin: '',
      resetPinPanel: false,
      error: false,
      clearErrorAfter: false,
    }
  },
  methods: {
    clearPin() {
      this.pin = ''
      this.confirmPin = ''
      this.error = false
      this.resetPinPanel = true
    },
    cancel() {
      this.doLogout()
    },
    doLogout() {
      this.$store.dispatch('fullLogout').then(() => {
        this.$router.push('/login')
      })
    },
    keyPressed() {
      if (this.clearErrorAfter) {
        this.error = false
        this.clearErrorAfter = false
      }
    },
    setPin(pin) {
      if (!this.pin) {
        this.pin = pin
        this.confirmPin = ''
      } else {
        this.confirmPin = pin
      }

      if (this.pin && this.confirmPin) {
        if (this.pin == this.confirmPin) {
          this.loginSetPin()
        } else {
          this.clearPin()
          this.error = true
          setTimeout(() => {
            this.clearErrorAfter = true
          }, 0)
        }
      }

      this.resetPinPanel = true
    },
    loginSetPin() {
      this.$store
        .dispatch('loginSetPin', this.pin)
        .then(() => {
          this.$router.push('/')
        })
        .catch(err => {
          this.error = err
        })
    },
  },
}
</script>
