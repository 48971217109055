import axios from 'axios'
import Nprogress from 'nprogress'
import { SnackbarProgrammatic as Snackbar } from 'buefy'

import store from '@/store'
import router from '@/router'

export default () => {
  const instance = axios.create({
    baseURL: process.env.VUE_APP_BASE_API_URI,
    withCredentials: true,
    headers: {
      'accept-language': store.state.language || this.$i18n.locale,
    },
  })

  // Interceta o pedido
  instance.interceptors.request.use(
    function(config) {
      store.dispatch('setApiStatus', true)
      if (!config.headers.quiet) {
        Nprogress.start()
      }
      return config
    },
    function(error) {
      Nprogress.done()
      return Promise.reject(error)
    }
  )

  // Interceta a resposta
  instance.interceptors.response.use(
    // Qualquer resposta com status code 200
    function(response) {
      // Já que a API não devolve um status code de erro,
      // força deste lado a que o pedido seja tratado corretamente
      //Excepções: login_by_token

      if (
        // we must reset password
        response.data &&
        (typeof response.data.reset == 'undefined' || response.data.reset == false || response.data.reset == null) &&
        // we must change password
        (typeof response.data.mustChangePWD == 'undefined' || response.data.mustChangePWD == 'F') &&
        (response.data.error || response.data.erro)
      ) {
        Nprogress.done()
        return Promise.reject(response.data.error || response.data.erro)
      }

      Nprogress.done()
      return response
    },

    // Qualquer resposta com erro, onde o status code é diferente de 200
    function(error) {
      Nprogress.done()

      if (error.response) {
        // console.log(error.response)

        if (error.response.status == 500) {
          router.push('/internal-error')
        }

        if (error.response.status == 404) {
          Snackbar.open({
            duration: 5000,
            message: this.$t('181'),
            type: 'is-danger',
            position: 'is-bottom-right',
            actionText: 'OK',
            queue: false,
          })
        }

        if (error.response.status == 401 || error.response.status == 403) {
          store.dispatch('logout')

          // Evita o redirect para a própria vista
          if (router.currentRoute.path != '/login/pin' && router.currentRoute.path != '/login') {
            if (!store.getters.getPinToken) {
              router.push('/login')
            } else {
              router.push('/login/pin')
            }
          }
        }

        return Promise.reject(error)
      }

      // Erro de ligação - servidor não responde
      else {
        store.dispatch('setApiStatus', false)
      }
    }
  )

  return instance
}
