<template>
  <div class="record">
    <div class="record-icon">
      <faicon icon="ticket-alt" size="sm" class="has-minus-45-rotation"></faicon>
    </div>
    <div class="record-content" @click="goToDetail">
      <div class="record-title is-flex is-flex-space-between">
        <a class="has-margin-right has-text-weight-medium">
          <v-clamp autoresize :max-lines="1">{{ item.desc_tipo_cred }}</v-clamp></a
        >
        <span class="is-meta"># {{ item.nr_credencial }}</span>
      </div>
      <div class="record-meta is-flex is-flex-space-between">
        <div>{{ item.data_inicio }} {{ item.data_fim ? '> ' + item.data_fim : '' }}</div>
        <div>
          <span v-if="item.almoco == 'T'" class="has-margin-left"
            >{{ $t('77') }} <faicon class="has-text-tiny" icon="check-circle"></faicon
          ></span>
          <span v-if="item.jantar == 'T'" class="has-margin-left"
            >{{ $t('78') }} <faicon class="has-text-tiny" icon="check-circle"></faicon
          ></span>
        </div>
      </div>
    </div>
    <div class="record-nav">
      <a @click="goToDetail">
        <faicon icon="chevron-right" class="has-text-primary"></faicon>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: Object,
  },
  methods: {
    goToDetail() {
      this.$router.push('/credential/' + this.item.nr_credencial)
    },
  },
}
</script>
