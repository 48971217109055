<template>
  <div>
    <multiselect
      v-model="value"
      :disabled="disabled"
      :options="countries"
      :show-labels="false"
      :clear-on-select="false"
      :allow-empty="false"
      placeholder="Escolha um país"
      label="title"
      track-by="title"
      :max-height="200"
      @input="updateParent"
    ></multiselect>
  </div>
</template>

<script>
import isEmpty from 'lodash/isEmpty'
import Multiselect from 'vue-multiselect'
import countries from '@/utilities/countries'

export default {
  components: {
    Multiselect,
  },
  props: {
    input: String,
    disabled: Boolean,
  },
  data() {
    return {
      countries: countries,
      value: null,
    }
  },
  mounted() {
    this.getCountries()
  },
  methods: {
    updateParent() {
      this.$emit('update', this.value.title)
    },
    async getCountries() {
      // Define Portugal como default para facilitar o preenchimento
      const defaultCountry = this.countries[this.countries.findIndex(item => item.title == 'Portugal')]
      this.value = !isEmpty(this.input)
        ? this.countries[this.countries.findIndex(item => item.title == this.input)]
        : defaultCountry.title
    },
  },
}
</script>
