/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icon-transfer': {
    width: 39,
    height: 23,
    viewBox: '0 0 39 23',
    data: '<path pid="0" d="M12.187 9.726v-.664h6.751v-3.32l4.051 3.984-4.05 3.983v-3.32h-5.402v5.976h-1.35V9.726zM31.76 8.924a4.577 4.577 0 01-3.209-1.307 4.425 4.425 0 01-1.329-3.155c0-1.183.478-2.318 1.329-3.155A4.577 4.577 0 0131.76 0c1.203 0 2.357.47 3.208 1.307a4.424 4.424 0 011.329 3.155 4.424 4.424 0 01-1.329 3.155 4.577 4.577 0 01-3.209 1.307zm0 1.115c2.438 0 4.764.446 6.92 1.216l-1.249 6.592h-1.417l-.851 4.462h-6.807l-.85-4.462h-1.418l-1.248-6.592a20.648 20.648 0 016.92-1.216zM4.867 9.586c-.847 0-1.658-.331-2.257-.92a3.112 3.112 0 01-.935-2.218c0-.833.337-1.631.935-2.22a3.219 3.219 0 012.257-.919c.846 0 1.658.331 2.256.92.599.588.935 1.386.935 2.219 0 .832-.336 1.63-.935 2.218-.598.589-1.41.92-2.256.92zm0 .784c1.715 0 3.35.314 4.866.855l-.877 4.637h-.997L7.26 19H2.473l-.598-3.138H.878L0 11.225c1.56-.56 3.206-.85 4.867-.855z" _fill="#466552"/>'
  }
})
