export default {
  isLoggedIn: state => !!state.session.email,
  getUser(state) {
    return state.session.email
  },
  getLocalSession(state) {
    return {
      email: state.session.email || localStorage.getItem('session.email'),
      pinToken: state.session.pinToken || localStorage.getItem('session.pinToken'),
    }
  },
  getUserInfo(state) {
    return state.session.userInfo
  },
  getUserSummary(state) {
    return state.session.userSummary
  },
  getCredit: state => state.session.userSummary.saldo,
  getPinToken(state) {
    return (state.session.pinToken = state.session.pinToken
      ? state.session.pinToken
      : localStorage.getItem('session.pinToken'))
  },
  getBreadcrumb(state) {
    return state.breadcrumb
  },
  getCart(state) {
    return state.cart
  },
  getCartCount(state) {
    return state.cart.reduce((count, item) => count + item.qt, 0)
  },
  getCartRefCount(state) {
    return (
      state.cart.reduce((count, item) => {
        if (item.tipo_ref) {
          return count + item.qt
        }
      }, 0) || 0
    )
  },
  getCartTotal(state) {
    return state.cart.reduce((total, item) => {
      if (item.preco_social && item.ot) {
        return total + item.ut.total + item.ot.total * (item.qt - 1)
      } else {
        if (item.ot) {
          return total + item.ot.total * item.qt
        } else {
          return total + item.ut.total * item.qt
        }
      }
    }, 0)
  },
  getCartSocialItem(state) {
    return mealId => {
      return state.cart.find(item => item.preco_social == true && item.tr_cod == mealId)
    }
  },
  getCartDate(state) {
    return state.cartDate
  },
}
