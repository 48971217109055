<template>
  <RecycleScroller
    v-slot="{ item }"
    :items="list"
    :item-size="55"
    :buffer="100"
    key-field="nr_credencial"
    class="scroller"
  >
    <RecordCredential :item="item" />
  </RecycleScroller>
</template>

<script>
import { RecycleScroller } from 'vue-virtual-scroller'
import RecordCredential from '@/components/records/RecordCredential'

export default {
  components: {
    RecycleScroller,
    RecordCredential,
  },
  props: {
    list: Array,
  },
}
</script>
