<template>
  <BasicLayout title="Conta criada com sucesso" back-to="/login">
    <template slot="content">
      <h1>A sua conta foi criada com sucesso!</h1>

      <div class="page-icon is-large">
        <span class="icon">
          <faicon icon="check-circle"></faicon>
        </span>
      </div>

      <div class="has-text-centered">
        <b-button type="is-primary" @click="$router.push('/login')">Autenticar</b-button>
      </div>
    </template>
  </BasicLayout>
</template>

<script>
import BasicLayout from '@/components/BasicLayout'

export default {
  components: {
    BasicLayout,
  },
}
</script>
