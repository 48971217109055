/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icon-mb': {
    width: 21,
    height: 16,
    viewBox: '0 0 21 16',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M3 0a3 3 0 00-3 3v10a3 3 0 003 3h15a3 3 0 003-3V3a3 3 0 00-3-3H3zm6.943 12h.973l-.603-8H8.95L7.49 9.817 5.957 4H4.603L4 12h.952l.243-3.53c.092-1.27.137-2.388.137-3.355l1.66 5.98h.942L9.51 5.125c.014.759.074 1.846.18 3.263L9.942 12zm6.592-3.646c-.31-.294-.716-.488-1.216-.58.38-.086.701-.283.962-.593.268-.31.402-.696.402-1.16 0-.682-.247-1.189-.74-1.522C15.456 4.166 14.75 4 13.828 4h-1.724v8h2.009C16.038 12 17 11.241 17 9.724c0-.619-.155-1.076-.465-1.37zm-3.427-.964V4.87h.825c.578 0 1.008.09 1.29.268.29.178.434.5.434.964 0 .41-.137.727-.412.952-.268.224-.614.336-1.037.336h-1.1zm2.359 3.437c-.31.194-.762.29-1.354.29h-1.005V8.239h1.195c.487 0 .882.116 1.185.348.303.233.455.612.455 1.138 0 .542-.16.91-.476 1.103z" _fill="#466552"/>'
  }
})
