<template>
  <div>
    <div v-for="item in list" :key="item.reference" class="record">
      <div class="record-icon">
        <faicon icon="euro-sign"></faicon>
      </div>
      <div class="record-content">
        <div class="record-title is-flex is-flex-space-between">
          <span class="has-margin-right has-text-weight-medium">
            <v-clamp autoresize :max-lines="1">{{ item.tipo_pagamento }}</v-clamp></span
          >
          <span class="has-text-weight-medium">{{ item.valor | currency }}</span>
        </div>
        <div class="is-meta is-flex is-flex-space-between">
          <div>{{ item.data_criacao }}</div>
          <div># {{ item.reference }}</div>
        </div>
        <div class="record-meta">
          <div>{{ item.estado }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    list: Array,
  },
}
</script>
