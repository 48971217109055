/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icon-santander': {
    width: 16,
    height: 16,
    viewBox: '0 0 128 128',
    data: '<path pid="0" d="M84.308 60.509c-.211-3.173-1.057-6.347-2.75-9.097L67.172 26.236c-1.058-1.904-1.904-4.02-2.327-6.135l-.635 1.058c-3.597 6.135-3.597 13.963 0 20.098l11.636 20.098c3.597 6.136 3.597 13.964 0 20.099l-.635 1.058c-.423-2.116-1.269-4.232-2.327-6.136L62.306 57.97l-6.77-11.847c-1.058-1.904-1.904-4.02-2.327-6.135l-.635 1.057c-3.597 6.136-3.597 13.752 0 20.099L64.21 81.242c3.597 6.136 3.597 13.964 0 20.099l-.635 1.058c-.423-2.116-1.269-4.232-2.327-6.136L46.65 71.087c-1.904-3.385-2.75-7.193-2.75-11.001-15.444 4.02-26.234 12.694-26.234 22.637 0 13.963 20.733 25.176 46.332 25.176 25.6 0 46.333-11.213 46.333-25.176.211-9.52-10.367-18.194-26.023-22.214z" _fill="#ea1d25" fill-rule="nonzero"/>'
  }
})
