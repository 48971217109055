<template>
  <MainLayout :title="$t('118')" short-title="Referências MB" back-to="/recharge" :has-notification-icon="false">
    <template v-slot:content>
      <div class="page-icon is-large">
        <span class="icon">
          <icon name="icon-mbway-o"></icon>
        </span>
      </div>
      <div v-if="!loading && userInfo" class="has-text-centered width-small">
        <div v-if="userInfo.contacto">
          <div class="is-meta">{{ $t('119') }}</div>
          <div class="is-size-1">
            {{ userInfo.contacto }}
          </div>
          <div class="has-margin-top-large">
            <div class="is-meta">{{ $t('91') }} (€)</div>
            <div class="has-margin-top">
              <b-numberinput
                v-model="newMbValue"
                :min="mbSettings.min"
                :max="mbSettings.max"
                :step="mbSettings.inc"
                type="is-dark"
              ></b-numberinput>
            </div>
          </div>
          <div class="has-margin-top-large">
            <button class="button is-primary is-fullwidth" @click="doRequestNew">{{ $t('103') }}</button>
          </div>
          <div class="has-margin-top-large">
            <button class="button is-small is-text" @click="resetNumberConfirm()">
              {{ $t('120') }}
            </button>
          </div>
        </div>
        <div v-else>
          <div class="has-text-lead has-margin-bottom-large">
            {{ $t('133') }}
          </div>
          <form>
            <b-field :label="$t('119')" :type="{ 'is-danger': $v.number.$error }">
              <b-input v-model="number" class="has-text-centered" type="number" maxlength="9"></b-input>
            </b-field>
            <b-field>
              <b-button type="is-primary" expanded :loading="loading" @click="doSaveNumber">{{ $t('121') }}</b-button>
            </b-field>
          </form>
          <div class="is-meta has-margin-top-medium">
            {{ $t('134') }}
          </div>
        </div>
      </div>
    </template>
    <template #modals>
      <b-modal
        :active="isNewCreatedModalActive"
        :width="500"
        scroll="keep"
        custom-class="hide-close"
        has-modal-card
        trap-focus
        aria-role="dialog"
        aria-modal
        @close="isNewCreatedModalActive = false"
      >
        <div v-if="justCreated" class="modal-card has-margin" style="width: auto">
          <section class="modal-card-body has-text-centered">
            <h3>{{ $t('131') }}</h3>
            <div class="has-margin-top has-margin-bottom is-meta">{{ $t('122') }} # {{ justCreated.info }}</div>
            <div class="has-text-small">
              {{ $t('132') }}
            </div>
            <div class="is-pulled-right has-margin-top">
              <button
                class="button is-text"
                @click="
                  isNewCreatedModalActive = false
                  $router.push('/recharge')
                "
              >
                OK
              </button>
            </div>
          </section>
        </div>
      </b-modal>
    </template>
  </MainLayout>
</template>

<script>
import { required, minLength, maxLength } from 'vuelidate/lib/validators'
import MainLayout from '@/components/MainLayout'
import BaseService from '@/services/BaseService'

export default {
  components: {
    MainLayout,
  },
  data() {
    return {
      loading: false,
      entries: [],
      number: null,
      newMbValue: 0,
      mbSettings: {},
      justCreated: null,
      isNewCreatedModalActive: false,
    }
  },
  computed: {
    isDownloadingFile() {
      return this.$store.state.isDownloading > 0 && this.$store.state.isDownloading < 100
    },
    userSummary() {
      return this.$store.getters.getUserSummary
    },
    userInfo() {
      return this.$store.getters.getUserInfo
    },
  },

  mounted() {
    this.getUserInfo()
    this.getMbSettings()
  },

  validations: {
    number: {
      required,
      min: minLength(9),
      max: maxLength(9),
    },
  },

  methods: {
    // Obtém dados atualizados do utilizador para validar o número de telefone
    async getUserInfo() {
      this.loading = true
      const userInfo = await BaseService.getUserInfo()
      this.$store.dispatch('setUserInfo', userInfo)
      this.number = userInfo.contacto
      this.loading = false
    },

    resetNumberConfirm() {
      this.$buefy.dialog.confirm({
        type: 'is-primary',
        message: this.$t(239),
        indefinite: true,
        queue: false,
        confirmText: this.$t(120),
        cancelText: this.$t(31),
        canCancel: true,
        onConfirm: () => {
          this.resetNumber()
        },
      })
    },

    resetNumber() {
      this.loading = true
      BaseService.updateUserInfoPhoneNumber('')
        .then(() => {
          this.$buefy.snackbar.open({
            message: this.$t(240),
            type: 'is-primary',
            position: 'is-bottom-right',
            duration: 5000,
          })

          // Atualiza perfil
          this.getUserInfo()
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },

    async doSaveNumber() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.loading = true
        BaseService.updateUserInfoPhoneNumber(this.number)
          .then(() => {
            this.$buefy.snackbar.open({
              message: this.$t(241),
              type: 'is-primary',
              position: 'is-bottom-right',
              duration: 5000,
            })
            this.getUserInfo()
          })
          .catch(err => {
            console.log(err)
            this.loading = false
          })
      }
    },

    getMbSettings() {
      BaseService.getMbSettings().then(response => {
        this.mbSettings = response
        this.newMbValue = response.min
      })
    },

    async doRequestNew() {
      // Garante que o utilizador não altera os valores no input
      if (this.newMbValue < this.mbSettings.min || this.newMbValue > this.mbSettings.max) {
        this.$buefy.snackbar.open({
          duration: 5000,
          message: this.$t(237) + ' ' + this.mbSettings.min + ' ' + this.$t(242) + ' ' + this.mbSettings.max + '.',
          type: 'is-danger',
          position: 'is-bottom-left',
          actionText: 'OK',
          queue: false,
        })
      } else {
        await BaseService.requestNewMbWay(this.newMbValue)
          .then(response => {
            this.justCreated = response
            this.isNewCreatedModalActive = true
          })
          .catch(err => {
            this.$buefy.dialog.alert({
              indefinite: true,
              message:
                `<div class="has-text-centered">
              <h2 class="has-text-danger is-width-75">` +
                this.$t(238) +
                `</h2>
              <p class="is-meta has-margin-top">${err}</p>
              </div>`,
              type: 'is-danger',
              position: 'is-bottom-left',
              actionText: 'OK',
              queue: false,
              onAction: () => {},
            })
          })
      }
    },
  },
}
</script>
