<template>
  <div id="app" :class="{ scrolled: mainScrolled }">
    <router-view />
  </div>
</template>

<style lang="scss">
@import '@/styles/main';
</style>

<script>
import 'typeface-fira-sans'

// Importa os icones necessários
import '@/assets/compiled-svg/icon-cc'
import '@/assets/compiled-svg/icon-qrcode'
import '@/assets/compiled-svg/icon-euro-minus'
import '@/assets/compiled-svg/icon-euro-plus'
import '@/assets/compiled-svg/icon-home'
import '@/assets/compiled-svg/icon-mb'
import '@/assets/compiled-svg/icon-mb-o'
import '@/assets/compiled-svg/icon-mbway-o'
import '@/assets/compiled-svg/icon-santander'
import '@/assets/compiled-svg/icon-return'
import '@/assets/compiled-svg/icon-transfer'
import '@/assets/compiled-svg/icon-store'
import '@/assets/compiled-svg/icon-tabuleiro'
import '@/assets/compiled-svg/icon-food-unit'

export default {
  data() {
    return {
      mainScrolled: false,
      refreshing: false,
      registration: null,
      updateExists: false,
    }
  },

  created() {
    document.addEventListener('swUpdated', this.showRefreshUI, { once: true })
    navigator.serviceWorker.addEventListener('controllerchange', () => {
      if (this.refreshing) return
      this.refreshing = true
      window.location.reload()
    })
  },

  mounted() {
    window.addEventListener('scroll', this.updateScroll)
  },

  beforeDestroy() {
    window.addEventListener('scroll', this.updateScroll)
  },

  methods: {
    updateScroll() {
      if ((window.pageYOffset || document.documentElement.scrollTop) > 25) {
        this.mainScrolled = true
      } else {
        this.mainScrolled = false
      }
    },

    showRefreshUI(e) {
      this.registration = e.detail
      this.updateExists = true

      this.$buefy.dialog.confirm({
        type: 'is-primary',
        message: `<p class="is-size-4 has-margin-top">Nova versão disponível</p>
        <p class="has-margin-top-small is-meta">Clique em atualizar para garantir que utiliza a versão
        mais recente da aplicação.</p>`,
        indefinite: true,
        queue: false,
        confirmText: 'Atualizar',
        canCancel: false,
        onConfirm: () => {
          this.refreshApp()
        },
      })
    },

    refreshApp() {
      this.updateExists = false
      if (!this.registration || !this.registration.waiting) {
        return
      }
      this.registration.waiting.postMessage('skipWaiting')
    },
  },
}
</script>
