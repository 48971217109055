<template>
  <div class="date-panel">
    <div class="date-content has-text-weight-medium">
      {{ date }}
      {{ weekday }}
    </div>
    <div class="date-content">
      {{ month }}
      {{ year }}
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import isoWeek from 'dayjs/plugin/isoWeek'
import localeData from 'dayjs/plugin/localeData'

import 'dayjs/locale/pt'
import 'dayjs/locale/en'

dayjs.extend(isoWeek)
dayjs.extend(localeData)

export default {
  computed: {
    date() {
      return dayjs().date()
    },
    weekday() {
      return dayjs.weekdays()[dayjs().isoWeekday()].toLowerCase()
    },
    month() {
      return dayjs.months()[dayjs().month()].toLowerCase()
    },
    year() {
      return dayjs().year()
    },
  },
  beforeCreate() {
    dayjs.locale(this.$i18n.locale)
  },
}
</script>
