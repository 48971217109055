import Vue from 'vue'
import VueRouter from 'vue-router'

import Nprogress from 'nprogress'

import store from '@/store'
import BaseRoutes from './routes/base'
import AuthRoutes from './routes/auth'
import UserRoutes from './routes/user'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.VUE_APP_ROUTER_BASE,
  routes: [].concat(BaseRoutes, AuthRoutes, UserRoutes),
})

router.beforeResolve((to, from, next) => {
  if (to.name) {
    Nprogress.start()
  }
  next()
})

// eslint-disable-next-line no-unused-vars
router.afterEach((to, from) => {
  Nprogress.done()
})

router.beforeEach((to, from, next) => {
  // Valida vistas que obrigam a um utilizador autenticado
  // Caso não aconteça, redirecionar o utilizador para a página de login
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.getters.isLoggedIn) {
      const localUser = store.getters.getLocalSession
      store.state.session.email = localUser.email
    }

    if (store.getters.isLoggedIn) {
      next()
      return
    }

    // Caso exista um pin token registado no dispositivo,
    // reencaminha para o login com pin.
    if (localStorage.getItem('session.pinToken')) {
      next('/login/pin')
    }
    // Caso contrário, entra no login normal
    else {
      next('/login')
    }
  }

  // Valida as vistas que não permitam utilizadores autenticados
  // Normalmente, apenas a vista de login, registo e recuperação de password nesta acl.
  // Caso aconteça, redireciona para a home.
  else if (to.matched.some(record => record.meta.requiresNoAuth)) {
    if (store.getters.isLoggedIn) {
      next('/')
      return
    }
    next()
  } else {
    next()
  }
})

export default router
