import Vue from 'vue'
import Vuex from 'vuex'

import messages from '@/i18n'

import actions from './actions'
import mutations from './mutations'
import getters from './getters'
import modules from './modules'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    language: localStorage.getItem('settings.language') || 'pt',
    locales: messages['pt'],
    notifications: {
      unread: 0,
      loaded: false,
    },
    register: {
      email: null,
      emailConfirmed: false,
      token: null,
      finished: false,
    },
    session: {
      userInfo: null,
      userSummary: null,
      email: null,
      password: null, // manter apenas para definir o pin, após autenticação
    },
    showOffcanvas: false,
    isDownloading: 0,
    api: {
      isActive: true,
    },
    breadcrumb: [],
    cart: JSON.parse(localStorage.getItem('cart') || '[]'),
    cartDate: localStorage.getItem('cartDate') || null,
  },
  mutations,
  actions,
  modules,
  getters,
})
