<template>
  <div>
    <b-modal
      :active.sync="isActive"
      scroll="keep"
      has-modal-card
      :width="350"
      trap-focus
      aria-role="dialog"
      aria-modal
      :can-cancel="true"
    >
      <div class="modal-card" style="width: auto">
        <section class="modal-card-body">
          <div>
            <h2 class="is-size-1 has-margin-bottom-medium">
              {{ $t(284) }}
            </h2>

            <div v-if="!token && !tokenRequested">
              <div class="has-text-centered has-text-small has-margin-bottom-medium">
                {{ $t(286) }}
              </div>
              <div>
                <b-button type="is-primary" :loading="loading" expanded @click="requestNewToken">
                  {{ $t(287) }}
                </b-button>
              </div>
            </div>

            <form
              v-else-if="!token && tokenRequested"
              class="width-small has-text-centered has-margin-top-medium"
              @keypress.enter="doConfirm"
              @submit.prevent="doConfirm"
            >
              <b-field :label="$t('101')" label-for="full_name" class="has-margin-bottom">
                <b-input
                  v-model="newToken"
                  class="has-text-centered is-code"
                  maxlength="5"
                  :placeholder="$t('100')"
                  type="input"
                ></b-input>
              </b-field>
              <b-field>
                <b-button
                  type="is-primary"
                  :loading="loading"
                  :disabled="!newToken"
                  expanded
                  @click="token = newToken"
                  >{{ $t(60) }}</b-button
                >
              </b-field>
              <b-field v-if="!tokenRequestBlocked" class="has-margin-bottom-medium">
                <b-button type="is-text" size="is-small" @click="getNewToken">{{ $t('268') }}</b-button>
              </b-field>
            </form>

            <form v-if="token" @keypress.enter="doContinue" @submit.prevent="doContinue">
              <b-field
                :label="$t(282) + ' *'"
                label-for="password"
                :type="{ 'is-danger': $v.password.$error }"
                :message="$v.password.$error ? $t(243) : ''"
              >
                <b-input
                  v-model="password"
                  password-reveal
                  :placeholder="$t(244)"
                  autofocus
                  type="password"
                  name="password"
                />
              </b-field>
              <b-field
                :label="$t(283) + ' *'"
                label-for="confirm-password"
                :type="{ 'is-danger': $v.confirmPassword.$error }"
                :message="$v.confirmPassword.$error ? $t(245) : ''"
                class="has-margin-bottom-small"
              >
                <b-input
                  v-model="confirmPassword"
                  :placeholder="$t(246)"
                  password-reveal
                  type="password"
                  name="confirm-password"
                />
              </b-field>
              <div class="has-margin-top-medium has-text-tiny">
                {{ $t(270) }}
              </div>
              <div class="has-margin-top-medium">
                <b-button type="is-primary" :disabled="!isValid" :loading="loading" expanded @click="doContinue">{{
                  $t(281)
                }}</b-button>
              </div>
            </form>
            <div class="has-margin-top has-text-centered has-text-small">
              <a href="" @click.prevent="closeModal">{{ $t(31) }}</a>
            </div>
          </div>
        </section>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { required, minLength } from 'vuelidate/lib/validators'
import BaseService from '@/services/BaseService'

export default {
  props: {
    show: Boolean,
    initToken: String,
  },

  data() {
    return {
      isActive: this.show || false,
      token: this.initToken,
      email: this.$store.state.register.email,
      password: null,
      confirmPassword: null,
      loading: false,
      newToken: null,
      tokenRequested: false,
      tokenRequestBlocked: false,
    }
  },

  computed: {
    isValid() {
      return this.password == this.confirmPassword
    },
    user() {
      return this.$store.getters.getUser
    },
  },

  validations: {
    password: { required, min: minLength(8) },
    confirmPassword: { required, min: minLength(8) },
  },

  watch: {
    show(value) {
      this.isActive = value
    },
    initToken(value) {
      if (value) this.token = value
    },
  },

  methods: {
    closeModal() {
      this.isActive = false
      this.tokenRequested = false
      this.$emit('close', false)
    },
    getUserEmail() {
      return this.email || this.user
    },
    async requestNewToken() {
      this.loading = true
      BaseService.getRegisterToken(this.getUserEmail())
      this.loading = false

      if (this.tokenRequested) {
        this.$buefy.snackbar.open({
          duration: 3000,
          message: 'Novo código de autorização enviado para a sua caixa de correio.',
          type: 'is-primary',
          position: 'is-top-right',
          actionText: 'OK',
          queue: false,
        })
        this.tokenRequestBlocked = true
      }
      this.tokenRequested = true
    },
    getNewToken() {
      this.requestNewToken()
    },
    async doContinue() {
      this.loading = true
      this.$v.$touch()

      if (!this.$v.$invalid) {
        try {
          await BaseService.setNewPassword(this.getUserEmail(), this.password, this.token)
          this.$buefy.dialog.alert({
            message: this.$t(285),
            confirmText: 'OK',
          })
          this.closeModal()
        } catch (e) {
          this.$buefy.dialog.alert({
            message: e,
            type: 'is-danger',
            ariaRole: 'alertdialog',
            ariaModal: true,
          })
        }
      }
      this.loading = false
    },
  },
}
</script>
