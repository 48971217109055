<template>
  <MainLayout :title="$t('295')">
    <template v-slot:content>
      <div class="record has-text-centered has-margin-top">
        <div class="record-content">
          <p class="has-margin">
            <faicon size="2x" icon="info-circle"></faicon>
          </p>
          <p class="has-margin-bottom">
            <span v-html="$t('292')"></span>
            <a href="https://www.uc.pt/sasuc/app-sasuc-go/" target="_blank" class="is-text has-margin-left-tiny"
              >uc.pt/sasuc/app-sasuc-go/</a
            >
          </p>
        </div>
      </div>

      <div class="record has-text-centered">
        <div class="record-content ">
          <p class="has-margin">
            <faicon size="2x" icon="envelope"></faicon>
          </p>
          <p class="has-margin-bottom">
            <span v-html="$t('293')"></span> <br />
            <a href="mailto:conta.cartao@sas.uc.pt"> conta.cartao@sas.uc.pt</a>
          </p>
        </div>
      </div>
    </template>
  </MainLayout>
</template>

<script>
import MainLayout from '@/components/MainLayout'

export default {
  components: {
    MainLayout,
  },
}
</script>
