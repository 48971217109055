import Home from '@/views/Home'
import Transfer from '@/views/Transfer'
import BuyLocations from '@/views/buy/Locations'
import BuyMeals from '@/views/buy/Meals'
import BuyProducts from '@/views/buy/Products'
import Cart from '@/views/Cart'
import DayMenu from '@/views/DayMenu'
import History from '@/views/recharge/History'
import Methods from '@/views/recharge/Methods'
import Donate from '@/views/donate/Donate'

import Mb from '@/views/recharge/Mb'
import CC from '@/views/recharge/CC'
import MbWay from '@/views/recharge/MbWay'
import Notifications from '@/views/Notifications'
import Credential from '@/views/Credential'
import Help from '@/views/Help'
import Laundry from '@/views/Laundry'
import NotFound from '@/components/errors/NotFound'
import InternalServerError from '@/components/errors/InternalServerError'
import Forbidden from '@/components/errors/Forbidden'

export default [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: { requiresAuth: true },
  },

  {
    path: '/transfer',
    name: 'transfer',
    component: Transfer,
    meta: { requiresAuth: true },
  },

  {
    path: '/buy',
    name: 'buy-locations',
    component: BuyLocations,
    meta: { requiresAuth: true },
  },

  {
    path: '/buy/meals',
    name: 'buy-meals',
    component: BuyMeals,
    meta: { requiresAuth: true },
  },

  {
    path: '/buy/products',
    name: 'buy-products',
    component: BuyProducts,
    meta: { requiresAuth: true },
  },

  {
    path: '/donate',
    name: 'donate',
    component: Donate,
    meta: { requiresAuth: true },
  },

  {
    path: '/cart',
    name: 'cart',
    component: Cart,
    meta: { requiresAuth: true },
  },

  {
    path: '/recharge',
    name: 'recharge',
    component: History,
    meta: { requiresAuth: true },
  },

  {
    path: '/menu',
    name: 'menu',
    component: DayMenu,
    meta: { requiresAuth: true },
  },

  {
    path: '/recharge/methods',
    name: 'recharge-methods',
    component: Methods,
    meta: { requiresAuth: true },
  },

  {
    path: '/recharge/mb',
    name: 'recharge-mb',
    component: Mb,
    meta: { requiresAuth: true },
  },

  {
    path: '/recharge/cc--disabled',
    name: 'recharge-cc',
    component: CC,
    meta: { requiresAuth: true },
  },

  {
    path: '/recharge/mbway',
    name: 'recharge-mbway',
    component: MbWay,
    meta: { requiresAuth: true },
  },

  {
    path: '/notifications',
    name: 'notifications',
    component: Notifications,
    meta: { requiresAuth: true },
  },

  {
    path: '/credential/:key',
    name: 'credential',
    component: Credential,
    meta: { requiresAuth: true },
  },

  {
    path: '/laundry',
    name: 'laundry',
    component: Laundry,
    meta: { requiresAuth: true },
  },

  {
    path: '/help',
    name: 'help',
    component: Help,
    meta: { requiresAuth: true },
  },

  {
    path: '*',
    name: 'error',
    component: NotFound,
    meta: { requiresNoAuth: false, layout: 'error', showBack: true },
  },
  {
    path: '/internal-error',
    name: 'internalError',
    component: InternalServerError,
    meta: { requiresNoAuth: false, layout: 'error', showBack: true },
  },
  {
    path: '/forbidden',
    name: 'forbidden',
    component: Forbidden,
    meta: { requiresNoAuth: false, layout: 'error', showBack: true },
  },
]
