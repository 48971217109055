<template>
  <div>
    <b-modal :active.sync="isActive" :width="500" scroll="keep" has-modal-card trap-focus aria-role="dialog" aria-modal>
      <div class="modal-card has-margin" style="width: auto">
        <section class="modal-card-body">
          <h2 class="lead active">O ficheiro está a ser descarregado...</h2>
          <div class="has-margin-top">
            <b-progress size="is-tiny" type="is-primary" :value="parseInt(percentageActive)"></b-progress>
            <div class="is-flex is-flex-align-center is-flex-space-between">
              <div>{{ percentageActive }}%</div>
              <div><button class="button is-text" @click="isActive = false">OK</button></div>
            </div>
          </div>
        </section>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  props: {
    show: Boolean,
    percentage: Number,
  },
  data() {
    return {
      isActive: this.show || false,
      percentageActive: 0,
    }
  },
  watch: {
    show(value) {
      setTimeout(() => {
        if (this.percentageActive < 50) {
          this.isActive = value
        }
      }, 500)
    },
    percentage(value) {
      this.percentageActive = value
    },
  },
}
</script>
