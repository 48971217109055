<template>
  <div class="credits-panel">
    <div v-if="userSummary" class="credits-content">
      <span class="credits-icon">
        <faicon
          :class="{ 'has-text-danger': !userSummary.saldo }"
          :icon="userSummary.saldo > 0 ? 'smile' : 'frown'"
        ></faicon>
      </span>
      <span class="credits-value has-text-weight-bold">{{ userSummary.saldo | currency }}</span>
    </div>
    <div v-else class="credit-loading">
      <ContentLoader type="credit" />
    </div>
    <div class="credits-footer">{{ $t(56) }}</div>
  </div>
</template>

<script>
import ContentLoader from '@/components/utilities/ContentLoader'

export default {
  components: {
    ContentLoader,
  },
  computed: {
    userSummary() {
      return this.$store.getters.getUserSummary
    },
  },
}
</script>
