<template>
  <div>
    <b-modal
      :active.sync="isActive"
      :width="500"
      :on-cancel="close"
      scroll="keep"
      has-modal-card
      trap-focus
      aria-role="dialog"
      aria-modal
    >
      <div class="modal-card has-margin" style="width: auto; min-width: 250px">
        <section class="modal-card-body">
          <div v-if="product" class="record is-grid">
            <div class="record-icon">
              <div class="page-icon no-margin">
                <span class="icon is-responsive">
                  <img v-if="product.img" :src="'data:image/jpeg;base64,' + product.img" />
                  <faicon v-else icon="circle" />
                </span>
              </div>
            </div>
            <div class="record-content has-text-weight-medium has-margin-bottom-large">
              <div class="record-title">
                <v-clamp autoresize :max-lines="2">{{ product.desc }}</v-clamp>
              </div>
              <div class="record-subtitle">
                <small v-if="showPriceWarning">
                  1x {{ product.ut.total | currency }}
                  <span v-if="product.ot">/ {{ quantity - 1 }}x {{ product.ot.total | currency }}</span>
                </small>
                <small v-else-if="hasSocialPriceInCart && product.ot">{{ product.ot.total | currency }}</small>
                <small v-else>{{ product.ut.total | currency }}</small>
              </div>
            </div>
            <div class="width-small">
              <b-field>
                <b-numberinput v-model="quantity" step="1" min="1" :max="maxQuantity" :editable="false"></b-numberinput>
              </b-field>
            </div>
            <div v-if="showPriceWarning" class="has-text-centered has-margin-top is-size-7">
              {{
                $t('262', {
                  ut: $options.filters.currency(product.ut.total),
                  ot: $options.filters.currency(product.ot.total),
                })
              }}.
            </div>
            <div v-if="!maxQuantity" class="has-text-centered has-margin-top-medium is-size-7">{{ $t('264') }}.</div>
          </div>
          <div class="is-flex is-flex-align-center is-flex-space-between is-flex-justify-center has-margin-top">
            <button class="button is-primary" :disabled="!maxQuantity" @click="add">{{ $t('257') }}</button>
          </div>
        </section>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  props: {
    show: Boolean,
    product: Object,
    qtMaxRef: Number,
    usoSocial: Boolean,
  },
  data() {
    return {
      isActive: this.show || false,
      quantity: 1,
      cartItemQuantity: 0,
      breadcrumb: this.$store.getters.getBreadcrumb,
    }
  },
  computed: {
    cartSocialItem() {
      return this.$store.getters.getCartSocialItem(this.$route.query.meal_id)
    },
    hasSocialPriceInCart() {
      return this.usoSocial && this.cartSocialItem
    },
    maxQuantity() {
      if (this.product) {
        if (this.product.tipo_ref) {
          return this.product.qt_max - this.cartItemQuantity
        } else {
          return this.qtMaxRef - this.$store.getters.getCartRefCount
        }
      } else {
        return 9999
      }
    },
    showPriceWarning() {
      return this.usoSocial && !this.cartSocialItem && this.product && this.product.ot && this.quantity > 1
    },
  },
  watch: {
    show(value) {
      this.isActive = value
      this.quantity = 1

      if (value == true) {
        const cartItem = this.$store.getters.getCart.find(
          entry =>
            entry.cod == this.product.cod &&
            entry.ua_cod == this.$route.query.location_id &&
            entry.tr_cod == this.$route.query.meal_id
        )

        if (cartItem) {
          this.cartItemQuantity = cartItem.qt
        }
      }
    },
    product() {
      this.product.ua_cod = this.$route.query.location_id
      this.product.ua_desc = this.breadcrumb[1].text

      this.product.tr_cod = this.$route.query.meal_id
      this.product.tr_nome = this.breadcrumb[2].text
    },
  },
  methods: {
    add() {
      // Is the social item if is for social use and no other social item is in the cart
      // and the item is a social type item
      const isSocialItem = this.usoSocial && !this.cartSocialItem && this.product.tipo_social

      this.$store.dispatch(
        'addCartItem',
        Object.assign({ qt: this.quantity, preco_social: isSocialItem }, this.product)
      )
      this.close()
      // this.$router.push('/cart')
    },
    close() {
      this.$emit('close', true)
      this.isActive = false
    },
  },
}
</script>
