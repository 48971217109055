<template>
  <MainLayout :title="$t('274')" back-to="/buy">
    <template v-slot:content>
      <div class="has-text-centered is-meta">{{ $t('275') }}</div>
      <div v-if="!loading && entries" class="has-margin-top">
        <div v-if="entries.length">
          <b-tabs v-model="activeDay" position="is-centered">
            <template v-for="menu in entries">
              <b-tab-item :key="menu.$index" :value="menu.day" :label="menu.dayString.medium">
                <h3 class="has-text-weight-normal has-text-centered has-margin-top">
                  <span class="has-text-weight-bold">{{ menu.dayString.weekday }},</span>
                  {{ menu.dayString.long }}
                </h3>
                <div class="has-margin-top">
                  <div class="buttons is-flex is-flex-justify-center is-text-centered is-outlined">
                    <b-button
                      v-for="meal in menu.meals"
                      :key="meal.$index"
                      size="is-small"
                      :type="activeMeal == meal.description.toLowerCase() ? 'is-primary' : 'is-text'"
                      @click="activeMeal = meal.description.toLowerCase()"
                      >{{ meal.description }}</b-button
                    >
                  </div>
                </div>
                <div v-for="meal in menu.meals" :key="meal.$index">
                  <div v-if="activeMeal == meal.description.toLowerCase()">
                    <div v-for="dish in meal.dishes" :key="dish.$index" class="record">
                      <div class="record-content">
                        <div class="has-text-small is-uppercase is-flex is-flex-space-between">
                          <div class="has-text-weight-bold">{{ dish.name }}</div>
                          <div class="label">{{ dish.caloriesValue }} {{ dish.caloriesUnit }}</div>
                        </div>
                        <div class="is-size-4">{{ dish.description }}</div>
                        <div v-if="dish.allergens" class="record-meta">
                          <span class="has-text-muted">Alérgenos</span>: {{ dish.allergens }}
                        </div>
                      </div>
                      <div></div>
                    </div>
                  </div>
                </div>
                <div class="has-text-tiny has-margin-top-medium">
                  <div v-for="notes in menu.notes" :key="notes.$index" class="has-margin-top">
                    {{ notes }}
                  </div>
                </div>
              </b-tab-item>
            </template>
          </b-tabs>
        </div>
        <div v-else class="has-margin-top-large is-meta has-text-centered">{{ $t('128') }}.</div>
      </div>
      <ContentLoader v-if="loading" type="list" class="has-margin-top" />
    </template>
  </MainLayout>
</template>

<script>
import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'

import MainLayout from '@/components/MainLayout'
import ContentLoader from '@/components/utilities/ContentLoader'
import BaseService from '@/services/BaseService'

dayjs.extend(isBetween)

const TODAY = dayjs()
const SEVEN_DAYS_FUTURE = dayjs().add(6, 'day')

export default {
  components: {
    MainLayout,
    ContentLoader,
  },
  data() {
    return {
      loading: false,
      activeDay: null,
      activeMeal: this.$t('276').toLowerCase(),
      entries: [],
    }
  },
  mounted() {
    this.getEntries()
  },
  beforeCreate() {
    dayjs.locale(this.$i18n.locale)
  },
  methods: {
    async getEntries() {
      this.loading = true
      const entries = await BaseService.getMenusInfo()
      entries.forEach(entry => {
        const day = dayjs(entry.Data)
        if (day == TODAY || day.isBetween(TODAY, SEVEN_DAYS_FUTURE, 'days', '[]')) {
          const meals = entry.TiposRefeicao.map(meal => {
            const dishes = meal.TiposPrato.map(dish => {
              return {
                allergens: dish.Alergenos && dish.Alergenos != '-' ? dish.Alergenos : null,
                name: dish.Descricao,
                description: dish.Nome,
                caloriesUnit: dish.UnidCaloria,
                caloriesValue: dish.ValorCalorico,
              }
            })
            return {
              description: meal.Descricao,
              notes: meal.InfoAdicional,
              dishes: dishes,
            }
          })
          this.entries.push({
            date: day,
            day: day.format('YYYY-MM-DD'),
            dayString: {
              short: day.format('YYYY-MM-DD'),
              weekday: day.format('dddd'),
              medium: day.format('D MMM'),
              long: day.format('D MMMM'),
            },
            notes: entry.Observacoes,
            meals: meals,
          })
        }
      })
      this.entries = Object.freeze(this.entries)
      this.loading = false

      console.log(this.entries)
    },
  },
}
</script>
