<template>
  <nav
    v-if="items.length"
    class="breadcrumb is-flex is-flex-space-between is-flex-align-center"
    aria-label="breadcrumbs"
  >
    <ul>
      <li v-for="(item, index) in items" :key="index" :class="{ 'is-active': item.url == '#' }">
        <a :href="item.url" @click="updateBreadcrumb(item.position)" @click.stop.prevent="$router.push(item.url)">
          {{ item.text }}
        </a>
      </li>
    </ul>
    <div v-if="items.length < 4">
      <b-button outlined type="is-dark" size="is-small" @click="$router.push('/menu')">{{ $t('277') }}</b-button>
    </div>
  </nav>
</template>

<script>
export default {
  props: {
    items: Array,
  },
  methods: {
    updateBreadcrumb(position) {
      this.$store.dispatch('removeBreadcrumbItem', position)
    },
  },
}
</script>
