<template>
  <MainLayout :title="$t('uc_share')" back-to="/">
    <template v-slot:content>
      <div class="page-icon is-medium">
        <span class="icon">
          <svg class="fill-current image is-48x48" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 442.14 347.61">
            <path
              d="m309.09,313.31c4.11,0,7.44-3.33,7.44-7.44s-3.33-7.44-7.44-7.44-7.44,3.33-7.44,7.44,3.33,7.44,7.44,7.44Z"
              style="fill: #6daa8a; stroke-width: 0px"
            />
            <path
              d="m329.57,313.53c39.42,0,71.49-32.07,71.49-71.49,0-4.23-3.43-7.66-7.66-7.66s-7.66,3.43-7.66,7.66c0,30.97-25.2,56.17-56.17,56.17-4.23,0-7.66,3.43-7.66,7.66s3.43,7.66,7.66,7.66Z"
              style="fill: #6daa8a; stroke-width: 0px"
            />
            <path
              d="m287.42,192.53c0-25.24,20.54-45.78,45.78-45.78,13.74,0,26.69,6.2,35.35,16.72-7.58,7.37-12.31,17.67-12.31,29.06h15.32c0-13.91,11.32-25.23,25.23-25.23s25.23,11.32,25.23,25.23h15.32c0-22.36-18.19-40.55-40.55-40.55-5.44,0-10.63,1.08-15.37,3.04-11.55-14.83-29.33-23.59-48.23-23.59-33.69,0-61.1,27.41-61.1,61.1h15.32Z"
              style="fill: #6daa8a; stroke-width: 0px"
            />
            <path
              d="m263.85,206.95l13.55-100.55h-74.03c.07-1.55.11-3.1.11-4.66C203.48,45.55,157.93,0,101.74,0S0,45.55,0,101.74s45.55,101.74,101.74,101.74c2.11,0,4.2-.07,6.28-.2l14.98,111.19c2.53,18.81,18.76,33,37.74,33h50.88c13.17,0,25.01-6.83,31.87-17.3,19.39,11.07,41.79,17.43,65.66,17.43,73.33,0,132.99-59.66,132.99-132.99v-7.66h-178.3ZM78.6,115.95c-.6-.34-.98-.36-1.55.13-11.57,9.82-28.66,3.77-31.5-11.14-2.19-11.53,7.16-22.46,19.09-22.31,4.71.06,8.84,1.66,12.38,4.75.53.46.9.54,1.54.17,11.11-6.36,22.24-12.7,33.37-19.03.58-.33.86-.63.71-1.39-2.07-10.9,5.11-20.94,16.15-22.62.18-.03.34-.12.51-.19h4.26c1.61.44,3.26.75,4.81,1.35,8.21,3.17,13.01,11.35,11.99,20.31-.93,8.24-7.79,15.25-16.21,16.4-6.14.84-11.52-.93-16.05-5.19-.22-.2-.43-.41-.65-.61-.03-.02-.07-.04-.2-.11-2.76,1.57-5.57,3.18-8.38,4.78-8.4,4.79-16.79,9.59-25.21,14.35-.71.4-.9.75-.69,1.57.8,3.06.78,6.14-.01,9.2-.16.6-.18.99.48,1.36,11.2,6.35,22.39,12.74,33.58,19.11.06.04.14.05.31.12.23-.21.49-.45.76-.69,2.36-2.15,4.87-3.68,7.52-4.56h.1c3.44-1.13,7.11-1.16,10.98,0h.1c.27.08.54.16.81.25,7.22,2.48,11.51,7.76,12.71,15.28,1.64,10.24-5.64,20.08-15.78,21.65-7.05,1.09-13.78-1.69-17.95-6.78-.21-.26-.41-.53-.61-.8-.1-.14-.2-.28-.29-.41-.26-.38-.51-.77-.75-1.18-.14-.24-.28-.48-.41-.73-.12-.22-.23-.44-.33-.67-.16-.33-.31-.67-.44-1.01-.06-.15-.13-.3-.19-.46-.18-.48-.34-.98-.48-1.48-.04-.15-.08-.3-.12-.45-.14-.55-.27-1.1-.36-1.67-.36-2.24-.06-4.6.07-6.9.04-.72-.08-1.06-.69-1.4-4.75-2.7-9.5-5.42-14.25-8.13h0c-6.37-3.63-12.75-7.26-19.12-10.9Zm133.02,216.19h-50.88c-11.34,0-21.04-8.48-22.55-19.72l-14.99-111.22c39.45-8.47,70.41-39.82,78.31-79.48h58.36l-25.7,190.71c-1.51,11.24-11.21,19.72-22.55,19.72Zm97.53.13c-21.91,0-42.42-6.03-60-16.5.07-.43.15-.86.21-1.3l12.42-92.2h164.79c-3.96,61.33-55.11,110-117.42,110Z"
              style="fill: #6daa8a; stroke-width: 0px"
            />
          </svg>
        </span>
      </div>
      <div v-if="error" class="width-small has-text-centered notification is-black has-text-warning has-padding-small">
        {{ error }}
      </div>
      <div class="has-text-centered has-text-medium has-text-weight-bold has-margin-top-small">
        {{ $t('give_away') }}
      </div>
      <div class="has-text-centered has-text-small width-small">{{ $t('donate_description') }}</div>
      <div v-if="!loading" class="has-margin-top-medium width-small">
        <div v-if="max > 0">
          <div class="has-margin-top">
            <b-field>
              <b-numberinput v-model="quantity" step="1" min="1" :max="max" :editable="false"></b-numberinput>
            </b-field>
          </div>
          <div class="is-flex is-flex-align-center is-flex-space-between is-flex-justify-center has-margin-top">
            <button class="button is-primary" :disabled="quantity == 0" @click="donate">
              {{ quantity == 1 ? $t('donate_meal') : $t('donate_meals') }}
            </button>
          </div>
          <div class="has-text-centered has-text-small is-meta has-margin-top-medium">
            {{ $t('meal_donation_description_1') }}{{ unit_price }}€{{ $t('meal_donation_description_2') }}{{ min
            }}{{ $t('meal_donation_description_3') }}{{ max }}{{ $t('meal_donation_description_4') }}
          </div>
        </div>
        <div v-else class="has-margin-top-large is-meta has-text-centered">{{ $t('cant_donate') }}.</div>
      </div>
      <ContentLoader v-if="loading" type="list" class="has-margin-top" />
      <hr class="has-margin-top-large" />
      <div class="has-text-centered has-text-medium has-text-weight-bold has-margin-top-large">
        {{ $t('receive_meal') }}
      </div>
      <div class="has-text-centered has-text-small width-small">{{ $t('receive_meal_description') }}</div>
      <div
        v-if="!dataAuthorized"
        class="is-flex is-flex-align-center is-flex-space-between is-flex-justify-center has-margin-top"
      >
        <button class="button is-primary" @click="redeem">
          {{ $t('verify_eligibility') }}
        </button>
      </div>
      <div v-else class="is-flex is-flex-align-center is-flex-space-between is-flex-justify-center has-margin-top">
        <b-button type="is-primary" :disabled="!can_redeem" :loading="receiving" @click="redeem">
          {{ $t('receive_meal_today') }}</b-button
        >
      </div>
      <div
        v-if="isEligible == true && dataVerified"
        class="has-text-weight-bold has-margin-top-medium is-meta has-margin-bottom-small has-text-small has-text-centered"
      >
        {{ $t('uc_share_eligible') }}
      </div>
      <div
        v-if="!isEligible && dataVerified"
        class="has-text-weight-bold has-margin-top-medium is-meta has-margin-bottom-small has-text-small has-text-centered"
      >
        {{ $t('uc_share_not_eligible') }}
      </div>
      <button v-if="false" @click="authorizePASEP()">PASEP!</button>
    </template>
  </MainLayout>
</template>

<script>
import MainLayout from '@/components/MainLayout'
import ContentLoader from '@/components/utilities/ContentLoader'
import BaseService from '@/services/BaseService'

export default {
  components: {
    MainLayout,
    ContentLoader,
  },
  data() {
    return {
      loading: false,
      receiving: false,
      can_redeem: false,
      isEligible: null, //null if not verified, true if eligible, false if not eligible
      dataAuthorized: false,
      min: 0,
      max: 0,
      quantity: 0,
      unit_price: 0,
      error: null,
      organizations: [],
    }
  },
  computed: {
    donationPrice() {
      return (this.quantity * this.unit_price).toFixed(2)
    },
    dataVerified() {
      return this.isEligible != null
    },
    dgesInfo() {
      return this.organizations.find(o => o.descricao.toLowerCase().includes('dges'))
    },
  },
  created() {
    this.getDonationLimits()
    this.getOrganizationsWithBenefits()
  },
  methods: {
    async getDonationLimits() {
      this.loading = true
      try {
        const result = await BaseService.getDonationLimits()
        if (result.error == null) {
          this.max = parseInt(result.UC_SHARE_MAX_NR)
          this.min = parseInt(result.UC_SHARE_MIN_NR)

          this.can_redeem = result.UC_SHARE_PERM_OBTER_REF && result.UC_SHARE_COM_BENEFICIO
          this.isEligible = result.UC_SHARE_COM_BENEFICIO
          this.dataAuthorized = result.UC_SHARE_ACESSO_BENEFICIO

          if (result.UC_SHARE_UNIT_PRICE) {
            this.unit_price = parseFloat(result.UC_SHARE_UNIT_PRICE)
          }
          this.quantity = this.min
        } else {
          this.error = result.error
        }
      } catch (error) {
        //console.log(error)
        this.error = error
      } finally {
        this.loading = false
      }
    },
    donate() {
      if (this.quantity > 0) {
        localStorage.setItem('donation', this.quantity)
        localStorage.setItem('donation_value', this.donationPrice)
        //go to cart
        this.$router.push('/cart')
      }
    },
    redeem() {
      this.error = null
      //receber refeição
      if (!this.dataAuthorized) {
        //popup a pedir autorização para partilhar dados com a DGES
        this.$buefy.dialog.confirm({
          message: this.$t('uc_share_authorize'),
          confirmText: this.$t('confirm'),
          cancelText: this.$t('cancel'),
          canCancel: true,
          onConfirm: () => {
            this.authorizeData()
          },
        })
      } else if (this.isEligible) {
        this.getFreeMeal()
      } else {
        this.$buefy.dialog.alert({
          message: this.$t('uc_share_not_eligible'),
          confirmText: this.$t('ok'),
          canCancel: false,
        })
      }
    },
    async authorizePASEP() {
      try {
        const result = await BaseService.authorizeDataShare(2, true)
        if (result == null) {
          //popup a dizer que autorização foi guardada e que pode demorar até 24h
          this.$buefy.dialog.alert({
            message: this.$t('uc_share_authorize_success'),
            confirmText: this.$t('ok'),
            canCancel: false,
          })
        } else {
          this.$buefy.dialog.alert({
            title: 'Erro',
            message: result,
            type: 'is-danger',
            ariaRole: 'alertdialog',
            ariaModal: true,
          })
        }
      } catch (error) {
        this.error = error
        //console.log(error)
      }
    },
    async authorizeData() {
      this.error = null
      if (this.dgesInfo) {
        //GET DGES id
        let dgesId = this.dgesInfo.id
        //AUTHORIZE DATA
        try {
          const result = await BaseService.authorizeDataShare(dgesId, true)
          if (result == null) {
            //popup a dizer que autorização foi guardada e que pode demorar até 24h
            this.$buefy.dialog.alert({
              message: this.$t('uc_share_authorize_success'),
              confirmText: this.$t('ok'),
              canCancel: false,
            })
          } else {
            this.$buefy.dialog.alert({
              title: 'Erro',
              message: result,
              type: 'is-danger',
              ariaRole: 'alertdialog',
              ariaModal: true,
            })
          }
        } catch (error) {
          this.error = error
          //console.log(error)
        }
      } else {
        this.$buefy.dialog.alert({
          message: this.$t('cant_find_dges'),
          confirmText: this.$t('ok'),
          canCancel: false,
        })
      }
    },
    getFreeMeal() {
      this.error = null
      //GET FREE MEAL
      //Warning that you must use the free meal until midnight of the same day
      this.$buefy.dialog.confirm({
        message: this.$t('uc_share_confirm'),
        confirmText: this.$t('confirm'),
        cancelText: this.$t('cancel'),
        canCancel: true,
        onConfirm: async () => {
          this.receiving = true
          try {
            const result = await BaseService.getFreeMeal()
            if (result.error == null) {
              this.$buefy.dialog.alert({
                message: this.$t('uc_share_success'),
                confirmText: this.$t('ok'),
                canCancel: false,
              })
              //redirect to homepage
              this.$router.push('/')
            } else {
              this.error = result
            }
          } catch (error) {
            //console.log(error)
            this.$buefy.dialog.alert({
              title: 'Erro',
              message: error,
              type: 'is-danger',
              ariaRole: 'alertdialog',
              ariaModal: true,
            })
          } finally {
            this.receiving = false
          }
        },
      })
    },
    async getOrganizationsWithBenefits() {
      try {
        const result = await BaseService.getDonationOrganizationsPermissions()
        if (result.error == null) {
          //console.log(result)
          this.organizations = result.lstEntidades
        } else {
          this.error = result.error
        }
      } catch (error) {
        this.error = error
        //console.log(error)
      }
    },
  },
}
</script>
