<template>
  <MainLayout :title="$t('106')" short-title="Referências MB" back-to="/recharge" :has-notification-icon="false">
    <template v-slot:content>
      <div class="page-icon is-medium">
        <span class="icon">
          <icon name="icon-mb-o"></icon>
        </span>
      </div>
      <div class="has-margin-bottom-medium has-text-centered">
        <button class="button is-primary" @click="requestNew">{{ $t('107') }}</button>
      </div>
      <div class="has-margin-top-large">
        <div class="buttons is-flex is-flex-justify-center is-text-centered is-outlined">
          <b-button
            size="is-small"
            :type="activeFilter == 'active' ? 'is-primary' : 'is-text'"
            @click="getMbEntries('active')"
            >{{ $t('108') }}</b-button
          >
          <b-button
            size="is-small"
            :type="activeFilter == 'hidden' ? 'is-primary' : 'is-text'"
            @click="getMbEntries('hidden')"
            >{{ $t('110') }}</b-button
          >
        </div>
      </div>
      <div v-if="!loading && entries" class="has-margin-top">
        <p v-if="activeFilter == 'active'" class="has-text-centered">
          {{ $t('111') }}
        </p>
        <p class="has-text-centered is-meta has-margin-bottom">
          {{ entries.length }}
          {{ entries.length > 1 ? $t('113').toLowerCase() + 's' : $t('113').toLowerCase() }}
        </p>
        <ListMbRequests v-if="entries.length" :list="entries" @reload-entries="getMbEntries('active')" />
        <div v-else class="has-margin-top is-meta has-text-centered">
          {{ $t('112') }}
        </div>
      </div>
      <ContentLoader v-if="loading" type="list" class="has-margin-top" />
    </template>
    <template #modals>
      <b-modal
        :active="isRequestNewModalActive"
        :width="500"
        scroll="keep"
        custom-class="hide-close"
        has-modal-card
        trap-focus
        aria-role="dialog"
        aria-modal
        @close="isRequestNewModalActive = false"
      >
        <div class="modal-card has-margin" style="width: auto">
          <section class="modal-card-body">
            <h2 class="lead active has-text-centered">{{ $t('116') }}</h2>
            <div class="has-margin-top">
              <div>
                <b-numberinput
                  v-model="newMbValue"
                  :min="mbSettings.min"
                  :max="mbSettings.max"
                  :step="mbSettings.inc"
                  type="is-dark"
                ></b-numberinput>
              </div>
              <div class="is-flex is-flex-align-center is-flex-space-between has-margin-top-large">
                <div>
                  <button class="button is-text" @click="isRequestNewModalActive = false">
                    {{ $t('31') }}
                  </button>
                </div>
                <div>
                  <b-button type="is-primary" :loading="loading" @click="doRequestNew">
                    {{ $t('177') }}
                  </b-button>
                </div>
              </div>
            </div>
          </section>
        </div>
      </b-modal>
      <b-modal
        :active="isNewCreatedModalActive"
        :width="500"
        scroll="keep"
        custom-class="hide-close"
        has-modal-card
        trap-focus
        aria-role="dialog"
        aria-modal
        @close="isNewCreatedModalActive = false"
      >
        <div v-if="justCreated" class="modal-card has-margin" style="width: auto">
          <section class="modal-card-body">
            <h3>{{ $t('130') }}:</h3>
            <div class="is-flex-space-center has-margin-top">
              <span class="is-meta">{{ $t('114') }}</span
              >{{ justCreated.entidade }}
            </div>
            <div class="is-flex-space-center">
              <span class="is-meta">{{ $t('113') }}</span
              >{{ justCreated.referencia }}
            </div>
            <div class="is-flex-space-center">
              <span class="is-meta">{{ $t('116') }}</span
              >{{ justCreated.valor | currency }}
            </div>
            <div class="is-pulled-right has-margin-top">
              <button class="button is-text" @click="isNewCreatedModalActive = false">OK</button>
            </div>
          </section>
        </div>
      </b-modal>
    </template>
  </MainLayout>
</template>

<script>
import MainLayout from '@/components/MainLayout'
import ContentLoader from '@/components/utilities/ContentLoader'
import BaseService from '@/services/BaseService'
import ListMbRequests from '@/components/lists/ListMbRequests'

export default {
  components: {
    MainLayout,
    ListMbRequests,
    ContentLoader,
  },
  data() {
    return {
      loading: false,
      entries: [],
      newMbValue: 0,
      mbSettings: {},
      justCreated: null,
      activeFilter: 'active',
      isRequestNewModalActive: false,
      isNewCreatedModalActive: false,
    }
  },
  mounted() {
    this.getMbEntries('active')
  },

  methods: {
    async getMbEntries(type) {
      this.loading = true
      const entries = await BaseService.getMbEntries(type)
      this.entries = Object.freeze(entries)
      this.activeFilter = type
      this.loading = false
    },
    requestNew() {
      BaseService.getMbSettings().then(response => {
        this.mbSettings = response
        this.newMbValue = response.min
        this.isRequestNewModalActive = true
      })
    },
    async doRequestNew() {
      // Garante que o utilizador não altera os valores no input
      if (this.newMbValue < this.mbSettings.min || this.newMbValue > this.mbSettings.max) {
        this.$buefy.snackbar.open({
          duration: 5000,
          message: this.$t(237) + ' ' + this.mbSettings.min + ' ' + this.$t(242) + ' ' + this.mbSettings.max + '.',
          type: 'is-danger',
          position: 'is-bottom-left',
          actionText: 'OK',
          queue: false,
        })
      } else {
        this.loading = true
        BaseService.requestNewMbEntry(this.newMbValue).then(response => {
          this.loading = false
          if (response.error && response.error !== null) {
            this.$buefy.dialog.alert({
              indefinite: true,
              message: response.error,
              type: 'is-black',
              position: 'is-bottom-left',
              actionText: 'OK',
              queue: false,
            })
          } else {
            this.justCreated = response
            this.isRequestNewModalActive = false
            this.isNewCreatedModalActive = true
            this.getMbEntries('active')
          }
        })
      }
    },
  },
}
</script>
