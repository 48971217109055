<template>
  <div class="columns is-multiline is-mobile">
    <div v-for="item in list" :key="item.cod" class="column is-half-mobile is-one-third-tablet">
      <a href="#" class="record is-grid" @click="setModalContent(item)">
        <div class="record-icon">
          <div class="page-icon is-responsive no-margin">
            <span class="icon">
              <img v-if="item.img" :src="'data:image/jpeg;base64,' + item.img" />
              <faicon v-else icon="circle" />
            </span>
          </div>
        </div>
        <div class="record-content has-text-weight-medium">
          <div class="record-title">
            <v-clamp autoresize :max-lines="2">{{ item.desc }}</v-clamp>
          </div>
          <div class="record-subtitle">
            <small v-if="hasSocialPriceInCart && item.ot">{{ item.ot.total | currency }}</small>
            <small v-else>{{ item.ut.total | currency }}</small>
          </div>
        </div>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    list: Array,
    modal: Boolean,
    usoSocial: Boolean,
  },
  computed: {
    hasSocialPriceInCart() {
      return this.usoSocial && this.$store.getters.getCartSocialItem(this.$route.query.meal_id)
    },
  },
  methods: {
    setModalContent(item) {
      this.$emit('set-modal-content', item)
    },
  },
}
</script>
