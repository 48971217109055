<template>
  <MainLayout :title="$t('258')" back-to="/" :has-notification-icon="false">
    <template #header-mobile-right>
      <div></div>
    </template>
    <template v-slot:content>
      <hr class="with-arrow to-down" />
      <div v-if="products.length || hasDonation" class="cart">
        <div v-if="hasDonation" class="cart-list">
          <div class="record">
            <div class="record-content is-flex-space-center">
              <div>
                <div>{{ $t('donation') }}</div>
                <div class="has-text-primary">
                  <span>{{ $t('meal_donation') }}</span>
                  <span class="has-text-muted has-margin-left-small">({{ donationValue | currency }})</span>
                </div>
              </div>
              <div class="cart-quantity has-text-muted" @click="removeDonation">
                {{ $t('remove') }}
              </div>
            </div>
          </div>
        </div>
        <div v-else class="cart-list">
          <div v-for="item in products" :key="item.ua_cod + '_' + item.tr_cod + '_' + item.cod">
            <div class="record">
              <div class="record-content is-flex-space-center">
                <div>
                  <div>{{ item.ua_desc }} , {{ item.tr_nome }}</div>
                  <div class="has-text-primary">
                    <span>{{ item.desc }}</span>
                    <span v-if="item.preco_social" class="has-text-muted has-margin-left-small"
                      >({{ item.ut.total | currency }})</span
                    >
                    <span v-else class="has-text-muted">
                      (
                      <span v-if="item.ot">{{ item.ot.total | currency }}</span>
                      <span v-else>{{ item.ut.total | currency }}</span>
                      )
                    </span>
                  </div>
                  <div v-if="item.preco_social && item.ot && item.qt > 1" class="has-text-muted is-size-7">
                    <small>{{ $t('263', { qt: item.qt - 1, ot: $options.filters.currency(item.ot.total) }) }}.</small>
                  </div>
                </div>
                <div class="cart-quantity">
                  <b-field>
                    <b-numberinput
                      v-model="item.qt"
                      step="1"
                      min="0"
                      :max="item.qt_max"
                      :editable="false"
                      :disabled="loading"
                      @input="updateCart(item)"
                    ></b-numberinput>
                  </b-field>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="cart-summary">
          <div class="record no-border has-margin-left-right">
            <div class="record-content is-flex-space-center has-text-weight-medium">
              <div class="has-text-muted is-uppercase">
                {{ $t('260') }}
              </div>
              <div class="has-text-primary">{{ total | currency }}</div>
            </div>
          </div>
          <div class="flex-column is-flex-align-center has-margin-top-bottom has-margin-top-large">
            <b-button
              class="is-width-small"
              type="is-primary"
              :loading="loading"
              :disabled="disablePayButton()"
              @click="pay"
            >
              {{ $t('261') }}
            </b-button>
            <div v-if="!hasDonation" class="has-text-centered has-margin-top has-text-small">{{ $t('280') }}</div>
            <div v-if="!disablePayButton()" class="has-text-muted has-text-tiny has-text-centered has-margin-top">
              <div class="has-text-centered is-width-medium">{{ $t('272') }}</div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="has-margin-top-large is-meta has-text-centered">{{ $t('259') }}.</div>
      <div v-if="successOverlay" class="overlay flex-column is-flex-align-center is-flex-justify-center">
        <div class="page-icon is-xlarge">
          <a @click="goBackAfterPay()">
            <span class="icon">
              <faicon icon="check" />
            </span>
          </a>
          <div class="is-size-2 has-text-primary has-margin-top-medium">Compra efetuada!</div>
        </div>
      </div>
    </template>
  </MainLayout>
</template>

<script>
import MainLayout from '@/components/MainLayout'
import BaseService from '@/services/BaseService'

export default {
  components: {
    MainLayout,
  },
  data() {
    return {
      loading: false,
      successOverlay: false,
      products: this.$store.getters.getCart,
    }
  },
  computed: {
    hasDonation() {
      return localStorage.getItem('donation') != null
    },
    donationValue() {
      return localStorage.getItem('donation_value')
    },
    total() {
      return this.$store.getters.getCartTotal + this.donationValue
    },
    userSummary() {
      return this.$store.getters.getUserSummary
    },
  },
  methods: {
    removeDonation() {
      localStorage.removeItem('donation')
      localStorage.removeItem('donation_value')
      //refresh page
      this.$router.go()
    },
    disablePayButton() {
      return false
      // return !this.userSummary || !this.userSummary.saldo || this.userSummary.saldo < this.total
    },
    updateCart(product) {
      if (product.qt == 0) {
        this.$store.dispatch('removeCartItem', product)

        if (product.preco_social) {
          this.$store.dispatch('setCartSocialItem', product)
        }
      }

      localStorage.setItem('cart', JSON.stringify(this.$store.getters.getCart))
    },
    pay() {
      this.$buefy.dialog.confirm({
        message: this.$t(273),
        confirmText: this.$t(261),
        cancelText: this.$t(31),
        canCancel: true,
        onConfirm: () => {
          this.loading = true

          let missing_param = false

          this.products.forEach(product => {
            if (!product.ua_cod || !product.tr_cod || !product.cod) {
              missing_param = true
            }
          })

          if (missing_param) {
            setTimeout(() => {
              this.goBackAfterPay()
            }, 2000)

            this.$buefy.snackbar.open({
              message: 'Ocurreu um erro ao tentar realizar a compra. ',
              type: 'is-danger',
              position: 'is-bottom-right',
              duration: 5000,
            })

            this.loading = false
          } else {
            BaseService.payCart(this.products, this.total)
              .then(() => {
                this.loading = false
                this.successOverlay = true
                setTimeout(() => {
                  this.goBackAfterPay()
                }, 3000)
              })
              .catch(err => {
                this.$buefy.snackbar.open({
                  message: err,
                  type: 'is-danger',
                  position: 'is-bottom-right',
                  duration: 5000,
                })
                this.loading = false
              })
          }
        },
      })
    },
    goBackAfterPay() {
      this.successOverlay = false

      this.$store.dispatch('clearCart')
      this.$router.push('/')
    },
  },
}
</script>
