<template>
  <ErrorLayout>
    <template #content>
      <div class="has-text-centered">
        <div class="has-margin-medium">
          <faicon icon="sad-cry" size="3x"></faicon>
        </div>
        <h1 class="title is-1">(403) Forbidden</h1>
        <p class="title is-4">{{ $t('47') }}</p>

        <a class="button is-text is-small" @click="$router.go(-1)">
          <faicon icon="chevron-left"></faicon> {{ $t('174') }}
        </a>
      </div>
    </template>
  </ErrorLayout>
</template>

<script>
import ErrorLayout from '@/components/ErrorLayout'

export default {
  components: {
    ErrorLayout,
  },
}
</script>
