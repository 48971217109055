const en = {
  1: 'Check your e-mail inbox to get the confirmation code.',
  2: 'In order to continue, we need to validate your e-mail address.',
  3: 'There was an error. The data you provided is not valid.',
  4: 'The confirmation code is not valid.',
  5: 'Your account is suspended.',
  6: 'Aceda à página de recuperação de contas institucionais, em',
  7: 'Your e-mail and/or your password might be incorrect.',
  8: 'Your account might be suspended',
  9: 'Username not found',
  10: 'A sua conta não parece estar registada. Por favor, aceda à página de registo para criar uma nova conta.',
  11: 'Wrong password',
  12: 'Wrong password (attempt 2 out of 3)',
  13: 'O seu email ou password ainda parecem não estar corretos e tem apenas mais uma tentativa.',
  14: 'Account suspended',
  15: 'Por favor, entre em contacto com o nosso suporte através do email',
  16: 'Please, wait a few minutes and try again.',
  17: 'Login (será escondido após login)',
  18: 'Signin with PIN',
  19: 'New account',
  20: 'New PIN',
  21: 'Password recover',
  22: 'Notifications',
  23: 'Profile',
  24: 'Home',
  25: 'Transfer',
  26: 'Top-up',
  27: 'Settings',
  28: 'Menu',
  29: 'Refund',
  30: 'Signin',
  31: 'Cancel',
  32: 'Reset',
  33: 'SASUC Go!',
  34: 'University of Coimbra',
  35: 'Informations',
  36: 'Popular questions',
  37: 'Contacts',
  38: 'Contact us trough our',
  39: 'support channel',
  40: 'by e-mail',
  41: 'or phone (239 000 000)',
  42: 'Your account was successfully created.',
  43: 'An internal server error occurred. Please, try again later.',
  44: 'Required',
  45: 'Provide a valid e-mail address.',
  46: 'Type your password.',
  47: 'You do not have permissions.',
  48: 'Not found',
  49: 'The page you were looking for was not found.',
  50: 'Session',
  51: 'Signout',
  52: 'Reset session / pin',
  53: 'Deletes current session on this device. You will have to set up a new pin next time you signin.',
  54: 'Authentication',
  55: 'Features',
  56: 'Current balance',
  57: 'Hello',
  58: 'Use on',
  59: 'Enter your e-mail address',
  60: 'Next',
  61: 'Forgot password?',
  62: 'Balance',
  63: 'Credentials',
  64: 'Transfer',
  65: 'Invoices',
  66: 'MB',
  67: 'Refund',
  68: 'Personal ID',
  69: 'Position the QR code in front of the scanner',
  70: 'Tickets',
  71: 'Validated',
  72: 'Unused',
  73: 'Invalid',
  74: 'Outdated',
  75: 'Used',
  76: 'No meal tickets available',
  77: 'Lunch',
  78: 'Dinner',
  79: 'Transactions',
  80: 'No previous transactions to show',
  81: 'Show all',
  82: 'Credential',
  83: 'Uses',
  84: 'Use',
  85: 'Canceled',
  86: 'No credentials to show',
  87: 'Transfer',
  88: 'Amount to transfer (in euros)',
  89: 'Transfer completed',
  90: 'Receiver',
  91: 'Amount',
  92: 'Favourites list',
  93: 'Choose a person',
  94: 'New receiver',
  95: 'Delete favourite',
  96: 'Student, Employee or External number',
  97: 'Try in your phone',
  98: 'More info',
  99: 'Hide this',
  100: 'code',
  101: 'Confirmation code',
  102: 'Top-Ups',
  103: 'Top-up',
  104: 'All',
  105: 'ATM Payment',
  106: 'ATM Payment number/reference',
  107: 'New ATM Payment number/reference',
  108: 'Valid',
  109: 'New account',
  110: 'Hidden',
  111: 'References waiting for payment.',
  112: 'There are no references to show.',
  113: 'Reference',
  114: 'Entity',
  115: 'Generated on',
  116: 'Amount to top up',
  117: 'MBWay',
  118: 'MBWay references',
  119: 'MBWay phone',
  120: 'Remove phone number',
  121: 'Save number',
  122: 'Request',
  123: 'Credit / Debit',
  124: 'Credit / Debit card',
  125: 'Santander App',
  126: 'Paid',
  127: 'Waiting payment',
  128: 'No previous top-ups to show',
  129: 'Choose how you want to top-up',
  130: 'Your new reference was created',
  131: 'Your request for a new MBWay top up was sent.',
  132: 'Please, accept the request in your MBWay app and, after that, confirm the operation status in the top up list.',
  133: 'Provide a valid phone number associated with MBWay.',
  134: 'The number you provide will be saved in your profile and will be used as a primary phone number.',
  135: 'Further information about the benefits and top-up methods available in the Santander App.',
  136: 'User type',
  137: 'Student',
  138: 'Indique o seu perfil',
  139: 'Worker',
  140: 'Other / External',
  141: 'You are registering a non-institucional email account. Please attach a file with proof of your profile.',
  142: 'Upload proof',
  143: 'Account',
  144: 'Change photo',
  145: 'Personal data',
  146: 'Contact data',
  147: 'Personal data policy acceptance',
  148: 'Data sharing with Santander App',
  149: 'Electronic invoice',
  150: 'Invoice as final consumer',
  151: 'Choose this option if you want your invoices to be sent as a Final Consumer.',
  152: 'Full name',
  153: 'Screen name',
  154: 'VAT Number',
  155: 'VAT Country',
  156: 'Address',
  157: 'Postal Code',
  158: 'Local or City',
  159: 'Nationality',
  160: 'Phone / Mobile number',
  161: 'Your personal data was updated',
  162: 'Birthdate',
  163: 'Gender',
  164: 'Male',
  165: 'Female',
  166: 'Notifications',
  167: 'Notification',
  168: 'Options',
  169: 'New',
  170: 'Mark all as read',
  171: 'Mark as read',
  172: 'Read',
  173: 'No notifications to show.',
  174: 'Back to previous page',
  175: 'Close',
  176: 'Save',
  177: 'Generate',
  178: 'Hide',
  179: 'Status',
  180: 'Choose',
  181: 'The document you selected does not exist.',
  182: 'Session expired.',
  183: 'There was an erros connecting to the server.',
  184: 'Are you sure you want to end your session on this device?',
  185: 'loading',
  186: 'The file is too big. Please, upload a smaller one.',
  187: 'Your photo was updated.',
  188: 'today',
  189: 'tomorrow',
  190: 'in',
  191: 'days',
  192: 'By type',
  193: 'Teaching staff',
  194: 'Researchers',
  195: 'Non-teaching staff',
  196: 'Dirigentes',
  197: 'Install on your phone',
  198: 'Preparámos cuidadosamente a nossa plataforma para uma ótima experiência de utilização no seu smartphone. Através da utilização de uma nova tecnologia, esta plataforma web pretende comportar-se de forma semelhante a uma aplicação nativa.',
  199: 'How to install',
  200: 'Find the option',
  201: 'Add to home screen',
  202: 'in your browser, available on your',
  203: 'Android (Google) device or find the option',
  204: 'Share',
  205: 'on your iOS (Apple) device',
  206: 'Texto introdutório sobre a app e os serviços associados. Lorem ipsum dolor sit amet, consectetur adipiscing elit...',
  207: 'Before we begin ...',
  208: 'Algumas instruções ou normas importantes apresentar. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  209: 'Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
  210: 'At last ...',
  211: 'Algumas informações para concluir a apresentação (apresentada uma vez por dispositivo).',
  212: 'Lets use the app',
  213: 'Condições de aceitação',
  214: 'Declaro que li e tomei conhecimento da Política de Privacidade e de Proteção de Dados da Universidade de Coimbra (UC), disponível na página web e mais declaro que tomei conhecimento, que:',
  215: 'A finalidade da recolha dos meus dados pessoais pela UC, no presente contexto, destina-se à minha identificação e credenciação como utilizador habituado a utilizar as funcionalidades da plataforma onde decorrerá a tramitação ...;',
  216: 'Os dados pessoais solicitados, nome, e-mail e contacto telefónico são os dados considerados essenciais para a organização administrativa e técnica da plataforma;',
  217: 'O prazo de conservação de dados pessoais é o que estiver fixado por norma legal ou regulamentar;',
  218: 'Na qualidade titular dos dados, em conformidade com a Lei, tenho os direitos de acesso e de retificação, podendo estes ser exercidos, por escrito, mediante comunicação por correio eletrónico para',
  219: 'O Encarregado de Proteção de Dados da UC pode ser contactado pelo email',
  220: 'Declaro que li e tomei conhecimento da Política de Privacidade',
  221: 'Accept and register an account',
  222: 'Erro na identificação do utente origem/destino',
  223: 'Do you want to save this user as afavourite to facilitate further transactions??',
  224: 'Favourite name?',
  225: 'Person name',
  226: 'Are you sure you want to delete this favourite?',
  227: 'The favourite was deleted',
  228: 'The signup form contains some errors.',
  229: 'Load',
  230: 'Set up PIN',
  231: 'Confirm PIN',
  232: 'The PIN does not match.',
  233: 'Please, try again.',
  234: 'Top-up request was sent',
  235: 'Please check your transactions to make sure the payment was made.',
  236: 'Open transactions',
  237: 'Please, input a value between',
  238: 'Ocorreu um erro a comunicar com o serviço MBWay.',
  239: 'Are you sure?',
  240: 'The phone number was removed',
  241: 'The phone number was saved',
  242: 'and',
  243: 'Please, provide a valid password',
  244: 'Please, provide a secure password',
  245: 'The password does not match',
  246: 'Confirm your password',
  247: 'Password',
  248: 'Confirm password',
  249: 'Back to home',
  250: 'Buy',
  251: 'Choose location',
  252: 'No locations to show',
  253: 'Choose meal',
  254: 'No meals to show',
  255: 'Choose products',
  256: 'No products to show',
  257: 'Add',
  258: 'Tray',
  259: 'No products to show',
  260: 'Total',
  261: 'Pay',
  262: 'The first unit is charged at social price ({ut}) and the remaining units at standard price ({ot})',
  263: '{qt} unit(s) at standard price ({ot})',
  264: 'You cannot add more units',
  265: 'Payment completed.',
  266: 'A carregar os locais...',

  267: 'Os membros da comunidade UC devem utilizar o endereço de e-mail e respetiva password, para que a aplicação fique associada à conta cartão UC.',
  272: 'After making the payment, it is not possible to cancel the operation.',
  273: 'Are you sure? After making the payment, it is not possible to cancel the operation.',
  274: 'Menu per day',
  275: 'Next 7 days',
  276: 'Lunch', // do not change
  277: 'Menu',
  278: 'Country Code',
  279: 'Employee, Student or External Number',
  280: 'The purchases are only valid for the same day.',
  281: 'Change password',
  282: 'New password',
  283: 'Confirm new password',
  284: 'Change account password',
  285: 'Password updated. Please login using the new password.',
  286: 'To change your password, you will need a security code. We will send a new one to your inbox.',
  287: 'Send new security code',
  288: 'An error occurred while trying to make the purchase.',
  289: 'Invalid PIN code',
  290: 'A new PIN code is needed. Please, login.',
  291: 'You need to change your password',
  292: 'For information about the application, <br />access',
  293: 'In case of difficulty, <br />contact our support services via email',
  294: 'Consult',
  295: 'Help',
  296: 'Laundry',
  297: 'Generate code',
  298: 'Enter the code in the self-service laundry kiosk, in accordance with the instructions provided.',
  299: 'Generate new code',
  300: 'For use in self-service laundries, without the need for a physical card.',
  301: 'The code will expire in',
  302: 'QR Code with expiration time limit. Click on the QR Code to renew it',
  uc_share: 'UCShare',
  give_away: 'Give meal away',
  donate_description:
    'With UCShare you can donate your meals. The meals will be used to help people in need in our community.',
  cant_donate: 'You cannot donate meals at this time.',
  donate: 'Donate',
  receive_meal: 'Receive meal',
  donate_meal: 'Donate meal',
  donate_meals: 'Donate meals',
  receive_meal_description:
    'If you are in need, you can receive a meal donated by the UC community. The meals are free and can only be used in the day you redeem them. Check if you are eligible.',
  receive_meal_today: 'Receive meal today',
  uc_share_authorize:
    'Do you authorize to share your data with DGES so we can verify your eligibility? It can take up to 24h to complete the process.',
  confirm: 'Confirm',
  cancel: 'Cancel',
  ok: 'Ok',
  uc_share_not_eligible: 'You are not eligible to receive meals.',
  uc_share_eligible: 'You are eligible to receive free meals.',
  uc_share_authorize_success:
    'UCShare data share authorization successful. Wait up to 24h for us to verify eligibility.',
  cant_find_dges: 'Internal error: We could not find DGES organization id. Please, try again later.',
  verify_eligibility: 'Verify eligibility',
  uc_share_confirm: 'Do you confirm that you want to receive a free meal? You have until midnight to use it.',
  uc_share_success: 'Meal received! You can find the ticket to your meal in the main page of the application.',
  donation: 'Donation',
  meal_donation: 'Meal donation',
  meal_donation_description_1: 'Each meal has the value of ',
  meal_donation_description_2: ' and you can donate between ',
  meal_donation_description_3: ' and ',
  meal_donation_description_4: ' meals.',
  remove: 'Remove',
}

export default en
