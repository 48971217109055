export default {
  updateLocalAccounts: account => {
    let localAccounts = localStorage.getItem('accounts')
    let updatedAccounts = [account]

    if (localAccounts) {
      localAccounts = JSON.parse(localAccounts)
      localAccounts.forEach(localAccount => {
        if (localAccount.email != account.email) {
          updatedAccounts.push(localAccount)
        }
      })
    }

    localStorage.setItem('accounts', JSON.stringify(updatedAccounts))
  },

  updateTokenLocalAccount: (email, token) => {
    let localAccounts = localStorage.getItem('accounts')
    let updatedAccounts = []

    if (localAccounts) {
      localAccounts = JSON.parse(localAccounts)
      localAccounts.forEach(localAccount => {
        if (localAccount.email == email) {
          localAccount.pinToken = token
        }
        updatedAccounts.push(localAccount)
      })
    }

    localStorage.setItem('accounts', JSON.stringify(updatedAccounts))
  },

  forgetLocalAccount: email => {
    let localAccounts = localStorage.getItem('accounts')
    let updatedAccounts = []

    if (localAccounts) {
      localAccounts = JSON.parse(localAccounts)
      localAccounts.forEach(localAccount => {
        if (localAccount.email !== email) {
          updatedAccounts.push(localAccount)
        }
      })
    }

    localStorage.setItem('accounts', JSON.stringify(updatedAccounts))
  },

  getLocalAccounts: () => {
    const localAccounts = localStorage.getItem('accounts')
    return localAccounts ? JSON.parse(localAccounts) : []
  },
}
