/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icon-euro-minus': {
    width: 30,
    height: 21,
    viewBox: '0 0 30 21',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M19.225 18.14a8.07 8.07 0 100-16.14 8.07 8.07 0 000 16.14zm0 2c5.562 0 10.07-4.508 10.07-10.07C29.296 4.51 24.788 0 19.226 0S9.155 4.509 9.155 10.07c0 5.562 4.509 10.07 10.07 10.07z" _fill="#DC5B53"/><path pid="1" d="M17.45 10.997c-.1.888-.007 1.582.28 2.08.29.488.804.831 1.54 1.029.384.102.747.142 1.09.118a7.03 7.03 0 001.135-.17l-.316 1.179c-.42.077-.82.117-1.2.12a4.554 4.554 0 01-1.219-.168c-1.011-.271-1.755-.797-2.23-1.577-.466-.777-.64-1.777-.523-2.998l-1.297-.348.462-.84 1.031.277.356-1.326-1.253-.335.466-.855 1.09.293c.531-1.037 1.21-1.766 2.039-2.186.831-.43 1.713-.52 2.646-.27a4.86 4.86 0 012.46 1.544l-.779.786a4.957 4.957 0 00-.9-.763 3.675 3.675 0 00-1.022-.431c-1.208-.324-2.198.247-2.97 1.714l3.757 1.007-.466.854-3.624-.97-.356 1.325 3.345.896-.447.844-3.094-.83z" _fill="#DC5B53"/><path pid="2" fill-rule="evenodd" clip-rule="evenodd" d="M7.324 10.986H0v-2h7.324v2z" _fill="#DC5B53"/>'
  }
})
