import endsWith from 'lodash/endsWith'
import Api from '@/services/Api'
import axios from 'axios'

export default {
  login(email, password) {
    const params = new FormData()
    params.set('user', email)
    params.set('pwd', password)

    return Api().post('/login.json', params)
  },

  logout() {
    //https://webdev.sas.uc.pt/PySiges/services/logout.json
    return axios({
      method: 'get',
      url: 'logout.json',
      baseURL: process.env.VUE_APP_SERVICES_URI,
    })
    //return Api().post('/logout.json')
  },

  loginSetPin(email, password, pin) {
    const params = new FormData()
    params.set('p_user', email)
    params.set('p_password', password)
    params.set('p_pin', pin)

    return Api().post('/app/create_pin_token', params)
  },

  loginWithToken(pin, token) {
    const params = new FormData()
    params.set('p_pin', pin)
    params.set('p_token', token)

    return Api().post('/app/login_by_token', params)
  },

  helpers: {
    isInstitutionalEmail(email) {
      if (endsWith(email, '@uc.pt') || endsWith(email, '.uc.pt')) {
        return true
      } else {
        return false
      }
    },
  },
}
