import messages from '@/i18n'

const mutations = {
  setUser(state, login) {
    state.session.email = login.email
    state.session.password = login.password
  },
  setUserEmail(state, email) {
    state.session.email = email
  },
  setUserInfo(state, userInfo) {
    state.session.userInfo = userInfo
  },
  setUserSummary(state, userSummary) {
    state.session.userSummary = userSummary
    state.notifications.loaded = true
    state.notifications.unread = userSummary.notificacoes.total - userSummary.notificacoes.total_lidas
  },
  clearLoginUserData(state) {
    state.session.password = null
  },
  clearStateLogin(state) {
    state.register.email = null
    state.register.token = null
    state.register.emailConfirmed = null
    state.register.finished = false
  },
  setLanguage(state, lang) {
    state.language = lang
    state.locales = messages[lang]
  },
  setPinToken(state, token) {
    state.session.pinToken = token
    state.session.isLoggedIn = true
  },
  setCredit(state, credit) {
    state.credit = credit
  },
  logout(state) {
    state.showOffcanvas = false
    state.session.email = null
    state.session.password = null
    mutations.clearCart(state)
    mutations.clearBreadcrumb(state)
  },
  setApiStatus(state, isActive) {
    state.api.isActive = isActive
  },
  clearBreadcrumb(state) {
    state.breadcrumb = []
  },
  addBreadcrumbItem(state, item) {
    if (state.breadcrumb[item.position] !== undefined) {
      state.breadcrumb[item.position].url = item.url
      state.breadcrumb[item.position].text = item.text
    } else {
      state.breadcrumb.push(item)
    }
  },
  removeBreadcrumbItem(state, position) {
    state.breadcrumb = state.breadcrumb.slice(0, position)
  },
  clearCart(state) {
    state.cart = []
    state.cartDate = null
  },
  addCartItem(state, item) {
    if (state.cart.length === 0) {
      state.cartDate = Date.now()
    }

    const existingItem = state.cart.find(
      entry => entry.cod == item.cod && entry.ua_cod == item.ua_cod && entry.tr_cod == item.tr_cod
    )

    if (existingItem) {
      existingItem.qt += item.qt
    } else {
      state.cart.push(item)
    }
  },
  removeCartItem(state, item) {
    const removeIndex = state.cart.indexOf(item)

    if (removeIndex != -1) {
      state.cart.splice(removeIndex, 1)
    }
  },
  setCartSocialItem(state) {
    if (state.cart.length > 0) {
      const product = state.cart.find(entry => entry.tipo_social == true)

      if (product) {
        product.preco_social = true
      }
    }
  },
}

export default mutations
