import Account from '@/views/account/Account'
import PersonalData from '@/views/account/PersonalData'

export default [
  {
    path: '/account',
    name: 'account',
    component: Account,
    meta: { requiresAuth: true },
  },

  {
    path: '/account/personal',
    name: 'account-personal-data',
    component: PersonalData,
    meta: { requiresAuth: true },
  },
]
