import dayjs from 'dayjs'

import Api from '@/services/Api'
import store from '@/store'
import countries from '@/utilities/countries'

export default {
  // Obtém token de validação de conta
  getRegisterToken(email) {
    return Api()
      .get('app/get_register_token', {
        params: {
          p_email: email,
          p_lang: store.state.language.toUpperCase(),
        },
      })
      .then(response => {
        return response.data
      })
  },

  setNewPassword(email, password, token) {
    const params = new FormData()
    params.append('p_email', email)
    params.append('p_new_password', password)
    params.append('p_token', token)
    return Api().post('app/request_new_user_pwd', params)
  },

  registerNewAccount(user) {
    const params = new FormData()

    // Mapea os campos de update
    // Dados pessoais
    params.append('p_nome', user.nome)
    params.append('p_data_nasc', user.data_nasc ? dayjs(user.data_nasc).format('YYYY-MM-DD') : null)

    // Converte o país no código
    const birthCountry = user.nacionalidade ? countries.find(item => item.title === user.nacionalidade) : null
    const birthCountryCode = birthCountry ? birthCountry.key : null
    params.append('p_nac', birthCountryCode)
    params.append('p_morada', user.morada)
    params.append('p_cod_postal', user.cod_postal)
    params.append('p_localidade', user.localidade)
    params.append('p_contacto_telef', user.contacto_telef)

    // Should be opcional
    params.append('p_genero', null)

    params.append('p_nif', user.nif)

    const VatCountry = user.pais_nif ? countries.find(item => item.title === user.pais_nif) : null
    params.append('p_pais_nif', VatCountry.key)

    params.append('p_token', user.token)
    params.append('p_rgpd', 'T')
    params.append('p_regulamento', 'T')
    params.append('p_email', user.email)
    params.append('p_password', user.password)

    params.append('p_comprovativo', user.file)

    params.append('p_fatura_eletronica', user.data_fat_eletronica ? 'T' : null)
    params.append('p_fatura_cons_final', user.data_faturas_consumidor_final ? 'T' : null)
    params.append('p_cons_santander', user.data_perm_santander ? 'T' : null)

    params.append('p_tipo_utilizador_pret', user.usertype)

    return Api().post('app/register_new_user', params)
  },

  // Obtém dados da conta para pré-registo
  getUserType(email, token) {
    return Api()
      .get('app/get_user_type', {
        params: {
          p_email: email,
          p_token: token,
          p_lang: store.state.language.toUpperCase(),
        },
      })
      .then(response => {
        return response.data
      })
  },

  // Obtém dados da conta para pré-registo
  getUserTypes() {
    return Api()
      .get('app/list_user_types', {
        params: {
          p_lang: store.state.language.toUpperCase(),
        },
      })
      .then(response => {
        return response.data
      })
  },

  // Resumo cliente
  getUserSummary() {
    return Api()
      .get('app/resumo_clientes', {
        params: {
          p_lang: store.state.language.toUpperCase(),
        },
      })
      .then(response => {
        const details = response.data[0]
        return details
      })
  },

  // Utilizador
  getUserInfo() {
    return Api()
      .get('info_cliente.json', {
        params: {
          p_lang: store.state.language.toUpperCase(),
        },
      })
      .then(response => {
        const details = response.data[0] // hummm...

        // Altera opções para boolean
        details['data_cons_rgpd'] = Boolean(details.data_cons_rgpd)
        details['data_perm_santander'] = Boolean(details.data_perm_santander)
        details['data_fat_eletronica'] = Boolean(details.data_fat_eletronica)
        details['data_faturas_consumidor_final'] = Boolean(details.data_faturas_consumidor_final)

        // O backend está a devolver null como string, em alguns casos
        details['contacto'] = details.contacto == 'null' ? null : details.contacto

        return details
      })
  },

  updateUserInfo(userDetails) {
    const params = new FormData()

    // Mapea os campos de update
    // Dados pessoais

    // params.set('p_nome', userDetails.nome)
    params.set('p_nickname', userDetails.nickname)
    params.set('p_data_nasc', userDetails.data_nasc ? dayjs(userDetails.data_nasc).format('YYYY-MM-DD') : null)

    // Converte o país no código
    const birthCountry = userDetails.nacionalidade
      ? countries.find(item => item.title === userDetails.nacionalidade)
      : null
    params.set('p_nac', birthCountry.key)
    params.set('p_morada', userDetails.morada)
    params.set('p_cod_postal', userDetails.cod_postal)
    params.set('p_localidade', userDetails.localidade)
    params.set('p_contacto', userDetails.contacto)

    // Opções
    params.set('p_rgpd', userDetails.data_cons_rgpd ? 'T' : 'F')
    params.set('p_regulamento', userDetails.data_regulamento ? 'T' : 'F')
    params.set('p_cons_santander', userDetails.data_perm_santander ? 'T' : 'F')
    params.set('p_fatura_eletronica', userDetails.data_fat_eletronica ? 'T' : 'F')
    params.set('p_fatura_cons_final', userDetails.data_faturas_consumidor_final ? 'T' : 'F')

    // Não permitir alterar
    // params.set('p_nif', userDetails.nif)
    // params.set('p_pais_nif', userDetails.pais_nif)

    return Api().post('set_info_cliente.json', params)
  },

  acceptRGPD() {
    const params = new FormData()
    params.set('p_rgpd', 'T')
    params.set('p_regulamento', 'T')
    return Api().post('set_info_cliente.json', params)
  },

  updateUserInfoPhoneNumber(number) {
    const params = new FormData()
    params.set('p_contacto', number)

    return Api().post('set_info_cliente.json', params)
  },

  updateUserPhoto(photo) {
    const params = new FormData()
    params.append('p_foto', photo)

    return Api().post('set_info_cliente.json', params)
  },

  // Tickets de refeição
  getTickets(filter = 'active') {
    let paramsMap = {
      active: {
        p_tipo: 'T',
      },
      canceled: {
        p_tipo: 'C',
      },
      validated: {
        p_tipo: 'V',
      },
      expired: {
        p_tipo: 'F',
      },
    }

    let params = paramsMap[filter]

    // Adiciona idioma
    params['p_lang'] = store.state.language.toUpperCase()

    return Api()
      .get('app/list_tickets', { params })
      .then(response => {
        return response.data
      })
  },

  getTicketQrCode(key) {
    return Api()
      .get('app/get_ticket_qr_code', {
        params: {
          p_doc_id: key,
        },
      })
      .then(response => {
        return response.data
      })
  },

  // Lista de carregamentos
  // Tipos (aplicar filtro):
  //    R = Referências multibanco
  //    C = Cartões de crédito
  //    M = MBWay
  getRechargeHistory(filter, limit = 25) {
    const filterMap = {
      mb: 'R',
      mbway: 'M',
      cc: 'C',
    }

    let params = {
      p_nr_registos: limit,
      p_lang: store.state.language.toUpperCase(),
    }

    if (filter && filter != 'all') {
      params['p_apenas_tipo_pag'] = filterMap[filter]
    }

    return Api()
      .get('sibs/list_util_dpg', { params })
      .then(response => {
        return response.data.lstPagamentos
      })
  },

  // Referências MB
  getMbSettings() {
    return Api()
      .get('get_params_carreg_ref_mb.json')
      .then(response => {
        return response.data
      })
  },

  getMbEntries(filter) {
    const params = {
      p_mostra_ocultas: filter == 'active' ? 'False' : 'True',
      p_lang: store.state.language.toUpperCase(),
    }
    return Api()
      .get('get_info_carreg_ref_mb.json', { params })
      .then(response => {
        // O WS deveria devolver apenas as referências ativas
        // Para apresentar o filtro no interface, precisamos de as retirar da lista principal
        return response.data.filter(entry => {
          return (filter == 'active' && !entry.oculta) || (filter != 'active' && entry.oculta)
        })
      })
  },

  requestNewMbEntry(value) {
    const params = new FormData()
    params.set('p_valor', value)
    return Api()
      .post('sibs/carregamento_refmb', params)
      .then(response => {
        return response.data
      })
  },

  requestNewMbWay(value) {
    const params = new FormData()
    params.set('p_valor', value)
    return Api()
      .post('sibs/carregamento_mbway', params)
      .then(response => {
        return response.data
      })
  },

  requestNewCheckoutId(type, amount) {
    const params = new FormData()
    params.set('p_databrand', type)
    params.set('p_valor', amount)
    return Api()
      .post('sibs/carregamento_cartao', params)
      .then(response => {
        return response.data
      })
  },

  setMbEntryAsHidden(item) {
    const params = new FormData()
    params.set('p_entidade', item.entidade)
    params.set('p_referencia', item.referencia)

    return Api()
      .put('sibs/toogle_view_ref_mb', params)
      .then(response => {
        return response.data
      })
  },

  // Transferências
  transferCredit(amount, userIdFrom, userNumberTo) {
    const params = new FormData()
    params.set('montante', amount)
    params.set('id', userIdFrom)
    params.set('nr_aluno', userNumberTo)
    return Api()
      .post('transf_saldo.json', params)
      .then(response => {
        return response.data[0] // hummm ...
      })
  },

  // Movimentos da conta
  getMovements(lastestOnly = true) {
    let params = {
      p_lang: store.state.language.toUpperCase(),
    }

    if (lastestOnly) {
      params['p_nr_mov'] = 25
    }

    return Api()
      .get('list_mov_cuc.json', { params })
      .then(response => {
        return response.data
      })
  },

  getMovementFile(filename) {
    return Api()
      .get('get_doc_mov_pdf.json', {
        params: {
          IDDoc: filename,
          p_lang: store.state.language.toUpperCase(),
        },
        responseType: 'blob',
        onDownloadProgress(progressEvent) {
          store.state.isDownloading = Math.round((progressEvent.loaded * 100) / progressEvent.total)
        },
      })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', filename + '.pdf')
        document.body.appendChild(link)
        link.click()
      })
  },

  // Credenciais
  getCredentials() {
    return Api()
      .get('list_credenciais.json', {
        params: {
          p_lang: store.state.language.toUpperCase(),
        },
      })
      .then(response => {
        return response.data
      })
  },

  getCredentialDetails(credentialKey) {
    return Api()
      .get('list_uso_credenciais.json', {
        params: {
          nr_credencial: credentialKey,
          p_lang: store.state.language.toUpperCase(),
        },
      })
      .then(response => {
        return response.data[0] // hummm...
      })
  },

  // Faturas
  getInvoices() {
    return Api()
      .get('list_docs.json', {
        params: {
          p_lang: store.state.language.toUpperCase(),
        },
      })
      .then(response => {
        return response.data
      })
  },

  getInvoiceFile(filename) {
    return Api()
      .get('get_doc_pdf.json', {
        params: {
          IDDoc: filename,
        },
        responseType: 'blob',
        onDownloadProgress(progressEvent) {
          store.state.isDownloading = Math.round((progressEvent.loaded * 100) / progressEvent.total)
        },
      })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', filename + '.pdf')
        document.body.appendChild(link)
        link.click()
      })
  },

  // QRCodes
  getPersonalQRCode() {
    return Api()
      .get('app/get_client_qr_code', {
        params: {
          p_lang: store.state.language.toUpperCase(),
        },
      })
      .then(response => {
        return 'data:image/png;base64, ' + response.data.qrcode
      })
  },

  getUnreadNotifications() {
    const params = {
      data_cons: dayjs()
        .subtract(12, 'month')
        .format('YYYY-MM-DD'),
      notificacao_lida: 'F',
    }

    params['p_lang'] = store.state.language.toUpperCase()

    return Api()
      .get('cons_notificacoes.json', { params })
      .then(response => {
        return response.data[0] // hummm...
      })
  },

  getAllNotifications() {
    const params = {
      p_lang: store.state.language.toUpperCase(),
      data_cons: dayjs()
        .subtract(12, 'month')
        .format('YYYY-MM-DD'),
    }
    return Api()
      .get('cons_notificacoes.json', { params })
      .then(response => {
        return response.data[0] // hummm...
      })
  },

  markAllNotificationsAsRead() {
    const params = new FormData()
    params.set(
      'data_inicio',
      dayjs()
        .subtract(5, 'year')
        .format('YYYY-MM-DD')
    )
    params.set('data_final', dayjs().format('YYYY-MM-DD HH:mm:ss'))
    return Api()
      .post('set_notificacoes_lidas.json', params)
      .then(response => {
        return response.data[0] // hummm...
      })
  },

  markNotificationAsRead(date) {
    const params = new FormData()
    params.set('data_inicio', dayjs(date).format('YYYY-MM-DD HH:mm:ss'))
    params.set('data_final', dayjs(date).format('YYYY-MM-DD HH:mm:ss'))

    return Api()
      .post('set_notificacoes_lidas.json', params)
      .then(response => {
        return response.data[0] // hummm...
      })
  },

  getFavorites() {
    return Api()
      .get('/list_cli_fav.json', {
        params: {
          p_lang: store.state.language.toUpperCase(),
        },
      })
      .then(response => {
        return response.data
      })
  },

  addFavorite(employeeNumber, personalId, favoriteName) {
    const params = new FormData()

    params.set('nome', favoriteName)
    params.set('nr_aluno', employeeNumber)
    params.set('id_cliente', personalId)

    return Api().post('criar_cli_fav.json', params)
  },

  deleteFavorite(employeeNumber, personalId) {
    const params = {
      nr_aluno: employeeNumber,
      id_cliente: personalId,
    }
    return Api().delete('del_cli_fav.json', { params })
  },

  // Lista de unidades alimentares
  getBuyLocations() {
    return Api()
      .get('app/get_lst_unid_alim', {
        params: {
          p_lang: store.state.language.toUpperCase(),
        },
      })
      .then(response => {
        return response.data.info
      })
  },

  // Tipos de refeição disponíveis
  getBuyMeals(locationId) {
    return Api()
      .get('app/get_lst_tp_ref_unid_alim', {
        params: {
          p_lang: store.state.language.toUpperCase(),
          p_ua: locationId,
        },
      })
      .then(response => {
        return response.data.info
      })
  },

  // Produtos existentes
  getBuyProducts(locationId, mealId) {
    return Api()
      .get('app/get_cv_produtos', {
        params: {
          p_lang: store.state.language.toUpperCase(),
          p_ua: locationId,
          p_tipo_ref: mealId,
        },
      })
      .then(response => {
        return response.data.produtos
      })
  },

  // Compra de refeição
  payCart(cartProducts, cartTotal) {
    const params = new FormData()
    const dateTime = dayjs().format('YYYY-MM-DD HH:mm')
    const products = []
    let finalCart = []

    let donations = localStorage.getItem('donation')
    if (donations && parseInt(donations) > 0) {
      params.set('p_doacao_nr_refeicoes', parseInt(donations))
    } else {
      cartProducts.forEach(product => {
        if (product.preco_social) {
          // Uma linha com o preço "social" com um item
          products.push({
            ua_cod: product.ua_cod,
            data_hora: dateTime,
            tr_cod: product.tr_cod,
            prod_cod: product.cod,
            qt: 1,
            tab_preco: product.ut.tab_cod,
            iva_inc: product.ut.iva_inc,
            iva_cod: product.ut.iva_cod,
            taxa_iva: product.ut.taxa_iva,
            valor_unitario: product.ut.preco_unit,
          })
        }

        if (product.ot) {
          // Outra linha com o preço "normal" com os restantes itens
          products.push({
            ua_cod: product.ua_cod,
            data_hora: dateTime,
            tr_cod: product.tr_cod,
            prod_cod: product.cod,
            qt: product.qt,
            tab_preco: product.ot.tab_cod,
            iva_inc: product.ot.iva_inc,
            iva_cod: product.ot.iva_cod,
            taxa_iva: product.ot.taxa_iva,
            valor_unitario: product.ot.preco_unit,
          })
        } else {
          // Ou uma linha com o preço "ut" se já não está disponível "ot"
          products.push({
            ua_cod: product.ua_cod,
            data_hora: dateTime,
            tr_cod: product.tr_cod,
            prod_cod: product.cod,
            qt: product.qt,
            tab_preco: product.ut.tab_cod,
            iva_inc: product.ut.iva_inc,
            iva_cod: product.ut.iva_cod,
            taxa_iva: product.ut.taxa_iva,
            valor_unitario: product.ut.preco_unit,
          })
        }

        // Decrementar a quantidade da segunda linha se for preço "social"
        if (product.preco_social) {
          products[products.length - 1].qt -= 1
        }

        // Ignore when quantity is zero
        finalCart = products.filter(item => {
          return item.qt > 0
        })
      })

      params.set('p_linhas_compra', JSON.stringify(finalCart))
      params.set('p_total_compra', cartTotal.toFixed(2))
    }
    return Api().post('app/register_venda', params)
  },

  getMenusInfo() {
    let api = Api()
    api.defaults.baseURL = 'https://portaldossas.uc.pt/PySiges/services/signetpos'

    return api
      .get('/get_ementas.json', {
        params: {
          Lang: store.state.language.toUpperCase(),
        },
      })
      .then(response => {
        return response.data.InfoDiaria
      })
  },

  washingMachineCode() {
    let api = Api()
    api.defaults.baseURL = process.env.VUE_APP_SERVICES_URI

    //https://webdev.sas.uc.pt/PySiges/services/siglav/authPWA
    return api.post('/siglav/authPWA')
  },

  // UC Share - donations
  getDonationLimits() {
    ///services/signetpos/app/uc_share/obter_param_doacao
    return Api()
      .get('app/uc_share/obter_param_doacao', {
        params: {
          p_lang: store.state.language.toUpperCase(),
        },
      })
      .then(response => {
        return response.data
      })
  },
  getDonationOrganizationsPermissions() {
    return Api()
      .get('app/uc_share/lst_ent_benef_sociais', {
        params: {
          p_lang: store.state.language.toUpperCase(),
        },
      })
      .then(response => {
        return response.data
      })
  },
  authorizeDataShare(organizationId, value = true) {
    const params = new FormData()
    params.append('p_cod_entidade', organizationId)
    params.append('p_permissao', value)
    return Api()
      .post('app/uc_share/reg_perm_benef_social', params)
      .then(response => {
        return response.data
      })
  },
  getFreeMeal() {
    const params = new FormData()
    return Api()
      .post('app/uc_share/obter_doacao', params)
      .then(response => {
        return response.data
      })
  },
  helpers: {
    getMovementFileUrl(filename) {
      return process.env.VUE_APP_BASE_API_URI + '/get_doc_mov_pdf.json?IDDoc=' + filename
    },
    getInvoiceFileUrl(filename) {
      return process.env.VUE_APP_BASE_API_URI + '/get_doc_pdf.json?IDDoc=' + filename
    },
  },
}
