/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icon-home': {
    width: 22,
    height: 22,
    viewBox: '0 0 22 22',
    data: '<path pid="0" d="M8.8 22H3.3V11H0L11 0l11 11h-3.3v11h-5.5v-6.6H8.8V22z" _fill="#466552"/>'
  }
})
