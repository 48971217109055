<template>
  <RecycleScroller v-slot="{ item }" :items="list" :item-size="50" :buffer="100" key-field="nr_doc" class="scroller">
    <div class="record" :class="{ disabled: item.anulado != 'F' }">
      <div class="record-content">
        <div class="record-title is-flex is-flex-space-between has-text-weight-medium">
          <v-clamp autoresize :max-lines="1">{{ item.desc_tipo_ref }}</v-clamp>
        </div>
        <div class="record-meta is-flex is-flex-space-between">
          <div>{{ item.data }}</div>
          <div v-if="item.anulado != 'F'" class="has-text-weight-bold">{{ $t('85') }}</div>
          <div class="is-meta"># {{ item.nr_doc }}</div>
        </div>
      </div>
    </div>
  </RecycleScroller>
</template>

<script>
import { RecycleScroller } from 'vue-virtual-scroller'

export default {
  components: {
    RecycleScroller,
  },
  props: {
    list: Array,
  },
}
</script>
