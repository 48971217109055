<template>
  <MainLayout :title="!loading ? $t('251') : $t('266')">
    <template v-slot:breadcrumb>
      <DatePanel />
    </template>
    <template v-slot:content>
      <hr class="with-arrow to-up" />
      <div v-if="!loading && entries && entries.length > 1" class="has-margin-top">
        <ListLocations v-if="entries.length" :list="entries" />
        <div v-else class="has-margin-top-large is-meta has-text-centered">{{ $t('252') }}.</div>
      </div>
      <ContentLoader v-if="loading" type="list" class="has-margin-top" />
    </template>
  </MainLayout>
</template>

<script>
import dayjs from 'dayjs'

import MainLayout from '@/components/MainLayout'
import DatePanel from '@/components/utilities/DatePanel'
import ContentLoader from '@/components/utilities/ContentLoader'
import BaseService from '@/services/BaseService'
import ListLocations from '@/components/lists/ListLocations'

export default {
  components: {
    MainLayout,
    DatePanel,
    ContentLoader,
    ListLocations,
  },
  data() {
    return {
      loading: false,
      entries: [],
    }
  },
  beforeCreate() {
    dayjs.locale(this.$i18n.locale)
  },
  mounted() {
    this.getEntries()
  },
  methods: {
    async getEntries() {
      this.loading = true
      const entries = await BaseService.getBuyLocations()
      this.entries = Object.freeze(entries)
      this.loading = false

      if (this.entries.length == 1) {
        const location = this.entries[0]
        this.updateBreadcrumb(location.desc)
        this.$router.push({ name: 'buy-meals', query: { location_id: location.cod } })
      }
    },
    updateBreadcrumb(text) {
      this.$store.dispatch('clearBreadcrumb')
      this.$store.dispatch('addBreadcrumbItem', {
        position: 0,
        url: '#',
        text: dayjs()
          .format('D MMMM')
          .toLowerCase(),
      })
      this.$store.dispatch('addBreadcrumbItem', {
        position: 1,
        url: this.$router.currentRoute.fullPath,
        text: text,
      })
    },
  },
}
</script>
