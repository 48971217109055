<template>
  <BasicLayout title="Registo de nova conta" back-to="/login">
    <template slot="content">
      <h1>{{ $t('109') }}</h1>

      <div class="page-icon">
        <span class="icon">
          <faicon icon="grin"></faicon>
        </span>
      </div>

      <form class="register-form" @keypress.enter="checkRegister" @submit.prevent="checkRegister">
        <h3 class="has-margin-top-large has-margin-bottom is-size-3 has-text-weight-bold">
          {{ $t('143') }}
        </h3>

        <b-field :label="$t('136') + ' *'" class="is-marginless">
          <b-select v-model="usertype" :placeholder="$t('138')" size="is-medium" expanded>
            <option v-for="utype in userTypes" :key="utype.cod" :value="utype.cod">{{ utype.descricao }}</option>
          </b-select>
        </b-field>

        <div v-if="usertype != 'OT'" class="has-margin-top-small has-text-small">
          {{ $t('141') }}
        </div>

        <b-field label-for="file" :type="{ 'is-danger': $v.file.$error }" :message="$v.file.$error ? $t('44') : ''">
          <b-field v-if="usertype != 'OT'" class="has-margin-top-medium has-margin-bottom-large">
            <b-upload v-model="file">
              <a class="button is-link has-text-weight-normal is-outlined has-text-small is-small">
                <faicon icon="file-pdf"></faicon>
                <span class="has-margin-left-small">{{ $t('142') + ' *' }}</span>
              </a>
            </b-upload>
            <span v-if="file" class="file-name has-text-small">
              {{ file.name }}
            </span>
          </b-field>
        </b-field>

        <b-field label="Email" class="is-size-2 has-margin-top-large">
          <span class="is-size-2">{{ email }}</span>
        </b-field>

        <b-field
          :label="$t(247) + ' *'"
          label-for="password"
          :type="{ 'is-danger': $v.password.$error }"
          :message="$v.password.$error ? $t(243) : ''"
        >
          <b-input v-model="password" password-reveal :placeholder="$t(244)" type="password" name="password" />
        </b-field>

        <b-field
          :label="$t(248) + ' *'"
          label-for="confirm-password"
          :type="{ 'is-danger': $v.confirmPassword.$error }"
          :message="$v.confirmPassword.$error ? $t(245) : ''"
          class="has-margin-bottom-small"
        >
          <b-input
            v-model="confirmPassword"
            :placeholder="$t(246)"
            password-reveal
            type="password"
            name="confirm-password"
          />
        </b-field>

        <div class="has-text-tiny">{{ $t(270) }}</div>

        <h3 class="has-margin-top-large has-margin-bottom is-size-3 has-text-weight-bold">
          {{ $t('145') }}
        </h3>

        <b-field
          :label="$t('152') + ' *'"
          :type="{ 'is-danger': $v.nome.$error }"
          :message="$v.nome.$error ? $t('44') : ''"
        >
          <b-input v-model="nome" size="is-medium" type="text" name="name" />
        </b-field>

        <b-field :label="$t('162')">
          <b-datepicker v-model="data_nasc" :placeholder="$t('180')" name="data_nasc" trap-focus> </b-datepicker>
        </b-field>

        <b-field :label="$t('159')">
          <CountriesSelect :input="nacionalidade" @update="updateNationality" />
        </b-field>

        <b-field
          :label="$t('154') + ' *'"
          :type="{ 'is-danger': $v.nif.$error }"
          :message="$v.nif.$error ? $t('44') : ''"
        >
          <b-input v-model="nif" type="text" name="nif" />
        </b-field>

        <b-field
          :label="$t('155') + ' *'"
          :type="{ 'is-danger': $v.pais_nif.$error }"
          :message="$v.pais_nif.$error ? $t('44') : ''"
        >
          <CountriesSelect :input="pais_nif" @update="updateVatCountry" />
        </b-field>

        <h3 class="has-margin-top-large has-margin-bottom is-size-3 has-text-weight-bold">
          {{ $t('146') }}
        </h3>

        <b-field
          :label="$t('156') + ' *'"
          :type="{ 'is-danger': $v.morada.$error }"
          :message="$v.morada.$error ? $t('44') : ''"
        >
          <b-input v-model="morada" type="text" name="morada" />
        </b-field>

        <b-field grouped class="is-marginless">
          <b-field
            :label="$t('157') + ' *'"
            :type="{ 'is-danger': $v.cod_postal.$error }"
            :message="$v.cod_postal.$error ? $t('44') : ''"
          >
            <b-input
              v-model="cod_postal"
              size="is-width-small"
              maxlength="8"
              type="text"
              placeholder="0000-000"
              name="cod_postal"
            />
          </b-field>
          <b-field
            :label="$t('158') + ' *'"
            expanded
            :type="{ 'is-danger': $v.localidade.$error }"
            :message="$v.localidade.$error ? $t('44') : ''"
          >
            <b-input v-model="localidade" type="text" name="localidade" />
          </b-field>
        </b-field>

        <b-field expanded grouped class="is-marginless">
          <b-field :label="$t('278')">
            <b-input :disabled="true" size="is-width-small" type="text" value="+351" />
          </b-field>
          <b-field
            :label="$t('160') + ' *'"
            :type="{ 'is-danger': $v.contacto_telef.$error }"
            :message="$v.contacto_telef.$error ? $t('44') : ''"
          >
            <b-input v-model="contacto_telef" expanded type="number" maxlength="25" name="contacto_telef" />
          </b-field>
        </b-field>

        <b-field class="has-margin-bottom has-margin-top-large">
          <b-button expanded type="is-primary" :loading="loading" @click="checkRegister">{{ $t('176') }}</b-button>
        </b-field>
      </form>
    </template>

    <template #modals>
      <ModalRGPD :show.sync="rpgdShowModal" @accepted="doRegister" />
      <b-modal
        :active="newAccountRegistered"
        :width="500"
        scroll="keep"
        custom-class="hide-close"
        has-modal-card
        trap-focus
        aria-role="dialog"
        aria-modal
        @close="$router.push('/login')"
      >
        <div class="modal-card has-margin" style="width: auto">
          <section class="modal-card-body has-text-centered">
            <h2>{{ $t('42') }}</h2>
            <div class="has-margin-top">
              <button class="button is-primary" @click="$router.push('/login')">{{ $t('109') }}</button>
            </div>
          </section>
        </div>
      </b-modal>
    </template>
  </BasicLayout>
</template>

<script>
import { required, minLength, requiredIf } from 'vuelidate/lib/validators'
import BasicLayout from '@/components/BasicLayout'
import CountriesSelect from '@/components/utilities/CountriesSelect'
import BaseService from '@/services/BaseService'
import ModalRGPD from '@/components/modals/ModalRGPD'

export default {
  components: {
    BasicLayout,
    CountriesSelect,
    ModalRGPD,
  },

  data() {
    return {
      token: this.$store.state.register.token,
      email: this.$store.state.register.email,

      rpgdShowModal: false,
      rpgdAccepted: false,
      newAccountRegistered: false,

      usertype: 'OT',

      // Dados pessoais (obrigatórios)

      nome: null,
      nif: null,
      pais_nif: null,
      morada: null,
      cod_postal: null,
      localidade: null,
      contacto_telef: null,

      // Dados pessoais (opcionais)
      data_nasc: null,
      nacionalidade: null,

      password: null,
      confirmPassword: null,

      file: [],

      userTypes: [],

      loading: false,
      errors: [],
    }
  },

  computed: {
    userTypeFileRequired() {
      return (
        this.usertype &&
        this.userTypes.length &&
        this.userTypes.filter(ut => {
          return ut.cod == this.usertype && ut.deve_ter_comprovativo
        })
      )
    },
  },

  validations: {
    password: { required, min: minLength(8) },
    confirmPassword: {
      confirm() {
        return this.password == this.confirmPassword
      },
    },
    file: {
      required: requiredIf(function() {
        return this.userTypeFileRequired.length && !this.file.length
      }),
    },
    nome: {
      required,
      min: minLength(2),
    },
    nif: {
      required,
      min: minLength(9),
    },
    pais_nif: {
      required,
    },
    morada: {
      required,
    },
    cod_postal: {
      required,
      min: minLength(4),
    },
    localidade: {
      required,
      min: minLength(4),
    },
    contacto_telef: {
      required,
      min: minLength(9),
    },
  },

  mounted() {
    if (!this.email) {
      // this.$router.push('/login')
    }
    this.getUserTypes()
  },

  methods: {
    async getUserTypes() {
      this.userTypes = await BaseService.getUserTypes()
    },

    updateVatCountry(country) {
      this.pais_nif = country
    },

    updateNationality(country) {
      this.nacionalidade = country
    },

    checkRegister() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.errors = []
        this.rpgdShowModal = true
      } else {
        this.$buefy.snackbar.open({
          duration: 5000,
          message: this.$t(228),
          type: 'is-danger',
          position: 'is-bottom-right',
          actionText: 'OK',
          queue: false,
        })
      }
    },

    async doRegister(rgpdSettings) {
      this.loading = true
      const account = Object.assign(rgpdSettings, {
        token: this.token,
        email: this.email,
        nome: this.nome,
        nif: this.nif,
        pais_nif: this.pais_nif,
        morada: this.morada,
        cod_postal: this.cod_postal,
        localidade: this.localidade,
        contacto_telef: this.contacto_telef,
        data_nasc: this.data_nasc,
        nacionalidade: this.nacionalidade,
        password: this.password,
        file: this.file,
        usertype: this.usertype,
      })

      try {
        await BaseService.registerNewAccount(account)
        this.$store.state.register.finished = true
        this.$router.push('/register/ok')
      } catch (e) {
        this.$buefy.snackbar.open({
          duration: 5000,
          message: e,
          type: 'is-danger',
          position: 'is-bottom-left',
          actionText: 'OK',
          queue: false,
        })
      }

      this.rpgdShowModal = false
      this.loading = false
    },
  },
}
</script>
