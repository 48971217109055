<template>
  <div>
    <a
      v-for="item in list"
      :key="item.cod"
      :href="'/buy/products?location_id=' + $route.query.location_id + '&meal_id=' + item.cod"
      class="record"
      @click="updateBreadcrumb(item.nome)"
      @click.stop.prevent="
        $router.push('/buy/products?location_id=' + $route.query.location_id + '&meal_id=' + item.cod)
      "
    >
      <div class="record-content">
        <div class="record-title is-size-3 is-flex-space-center">
          <span class="has-margin-left has-margin-right has-text-weight-medium">
            <v-clamp autoresize :max-lines="2">{{ item.nome }}</v-clamp>
          </span>
          <span class="has-text-weight-medium">
            <faicon icon="chevron-right" />
          </span>
        </div>
      </div>
    </a>
  </div>
</template>

<script>
import dayjs from 'dayjs'

export default {
  props: {
    list: Array,
  },
  beforeCreate() {
    dayjs.locale(this.$i18n.locale)
  },
  methods: {
    updateBreadcrumb(text) {
      this.$store.dispatch('addBreadcrumbItem', {
        position: 2,
        url: this.$router.currentRoute.fullPath,
        text: text,
      })
    },
  },
}
</script>
