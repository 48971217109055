<template>
  <div>
    <div class="pin-dash">
      <div v-for="number in 4" :key="number.$index" class="pin-number" :class="{ 'is-active': code.length >= number }">
        <faicon icon="star-of-life"></faicon>
      </div>
    </div>
    <div class="pin-panel">
      <button v-for="key in 9" :id="'key-' + key" :key="key.$index" @click="doPressKey(key, $event.target)">
        {{ key }}
      </button>
      <button class="is-small" @click="doCancel($event.target)">{{ $t('31').toLowerCase() }}</button>
      <button id="key-0" @click="doPressKey('0', $event.target)">0</button>
      <button class="is-small" @click="doClear($event.target)">{{ $t('32').toLowerCase() }}</button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    reset: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      code: '',
    }
  },
  watch: {
    reset(value) {
      this.code = value ? '' : this.code
    },
  },
  methods: {
    toggleActive(element) {
      element.classList.contains('is-active')
        ? element.classList.remove('is-active')
        : element.classList.add('is-active')
      setTimeout(() => {
        element.classList.remove('is-active')
      }, 150)
    },
    doSubmit() {
      this.$emit('update', this.code)
      this.code = ''
    },
    doCancel(element) {
      this.toggleActive(element)
      this.code = ''
      this.$emit('cancel')
    },
    doClear(element) {
      this.toggleActive(element)
      this.code = ''
      this.$emit('clear')
    },
    doPressKey(key, element) {
      this.toggleActive(element)
      if (this.code.length < 4) {
        this.code = this.code ? this.code + key : String(key)
      }
      if (this.code.length == 4) {
        this.doSubmit()
      }
      this.$emit('key-pressed')
    },
  },
}
</script>
