const pt = {
  1: 'Verifique a sua caixa de correio para obter o código de validação.',
  2: 'Para continuar, precisamos validar o seu endereço de email.',
  3: 'Ocorreu um erro. Os parâmetros definidos não são válidos.',
  4: 'O código de validação não é válido!',
  5: 'A sua conta encontra-se bloqueada.',
  6: 'Aceda à página de recuperação de contas institucionais, em',
  7: 'O seu email ou password parecem não estar corretos.',
  8: 'A sua conta parece ter sido bloqueada.',
  9: 'Nome de utilizador não encontrado',
  10: 'A sua conta não parece estar registada. Por favor, aceda à página de registo para criar uma nova conta.',
  11: 'Senha incorreta',
  12: 'Senha incorreta (nº tentativas 2 de 3)',
  13: 'O seu email ou password ainda parecem não estar corretos e tem apenas mais uma tentativa.',
  14: 'Conta bloqueada',
  15: 'Por favor, entre em contacto com o nosso suporte através do email',
  16: 'Por favor, aguarde alguns minutos e tente novamente.',
  17: 'Login (será escondido após login)',
  18: 'Login com PIN',
  19: 'Nova conta',
  20: 'Novo pin',
  21: 'Recuperação de password',
  22: 'Notificações',
  23: 'Perfil',
  24: 'Início',
  25: 'Transferir',
  26: 'Carregar',
  27: 'Definições',
  28: 'Menu',
  29: 'Devolver',
  30: 'Entrar',
  31: 'Cancelar',
  32: 'Limpar',
  33: 'SASUC Go!',
  34: 'Universidade de Coimbra',
  35: 'Informações',
  36: 'Perguntas frequentes',
  37: 'Contactos',
  38: 'Fale connosco através do canal de',
  39: 'suporte',
  40: 'por e-mail',
  41: 'ou telefone (239 000 000)',
  42: 'A sua nova conta foi criada com sucesso.',
  43: 'Ocorreu um erro interno. Por favor, tente novamente mais tarde.',
  44: 'Obrigatório',
  45: 'Indique um endereço de e-mail válido.',
  46: 'Introduza a password.',
  47: 'Não tem permissões para aceder ao recurso.',
  48: 'Não encontrado',
  49: 'O recurso solicitado não foi encontrado.',
  50: 'Sessão',
  51: 'Sair',
  52: 'Eliminar sessão',
  53: 'Eliminar a sessão ativa neste dispositivo. Na próxima autenticação irá precisar definir um novo PIN.',
  54: 'Autenticação',
  55: 'Funcionalidades',
  56: 'Saldo disponível',
  57: 'Olá',
  58: 'Utilizar em',
  59: 'Indique o seu endereço de e-mail',
  60: 'Continuar',
  61: 'Esqueceu-se da password?',
  62: 'Balanço',
  63: 'Credenciais',
  64: 'Transferência de crédito',
  65: 'Faturas',
  66: 'MB',
  67: 'Devolução',
  68: 'Identificação',
  69: 'Posicione o QR Code diante do leitor, a uma distância de aproximadamente 10 cm',
  70: 'Tickets',
  71: 'Validados',
  72: 'Não usados',
  73: 'Anulados',
  74: 'Fora do prazo',
  75: 'Utilizado',
  76: 'Não tem tickets de refeição',
  77: 'Almoço',
  78: 'Jantar',
  79: 'Movimentos',
  80: 'Não existem movimentos',
  81: 'Mostrar todos',
  82: 'Credencial',
  83: 'Utilizações',
  84: 'Utilização',
  85: 'Anulado',
  86: 'Não existem credenciais registadas',
  87: 'Transferir',
  88: 'Valor a transferir (em euros)',
  89: 'A sua transferência foi realizada com sucesso.',
  90: 'Destinatário',
  91: 'Valor',
  92: 'Favoritos',
  93: 'Escolha uma pessoa',
  94: 'Novo destinatário',
  95: 'Eliminar favorito',
  96: 'Nº de funcionário, Estudante ou Externo',
  97: 'Experimente no seu telefone',
  98: 'Saber mais',
  99: 'Não mostrar',
  100: 'código',
  101: 'Código de confirmação',
  102: 'Carregamentos',
  103: 'Novo carregamento',
  104: 'Todos',
  105: 'Multibanco',
  106: 'Referências Multibanco',
  107: 'Nova referência Multibanco',
  108: 'Ativas',
  109: 'Registo de nova conta',
  110: 'Ocultas',
  111: 'Referências a aguardar pagamento',
  112: 'Não existem referências geradas.',
  113: 'Referência',
  114: 'Entidade',
  115: 'Gerada em',
  116: 'Indique o valor',
  117: 'MBWay',
  118: 'Referências MBWay',
  119: 'Telefone MBWay',
  120: 'Remover número',
  121: 'Definir número',
  122: 'Pedido',
  123: 'Crédito / Débito',
  124: 'Cartão de Crédito / Débito',
  125: 'App Santander',
  126: 'Pago',
  127: 'Por pagar',
  128: 'Ainda não foram feitos carregamentos',
  129: 'Escolha uma forma de pagamento',
  130: 'A sua nova referência multibanco foi criada',
  131: 'O seu pedido de carregamento MBWay foi enviado com sucesso.',
  132: ' Depois de aceitar o pedido na sua app MBWay, por favor, confirme o estado da operação na lista de carregamentos.',
  133: 'Indique um número de telefone para utilizar o serviço MBWay.',
  134: 'O número definido será guardado nos seus dados pessoais como telefone de contacto.',
  135: 'Informação sobre as vantagens e métodos de carregamento do cartão via App Santander.',
  136: 'Tipo de utilizador',
  137: 'Estudante',
  138: 'Indique o seu perfil',
  139: 'Trabalhador',
  140: 'Outro / Externo',
  141: 'Está a registar-se com um e-mail que não o/a identifica como membro da comunidade UC. Para que possa ser validado o tipo de utilizador selecionado, terá de submeter um documento comprovativo (cartão de estudante, certificado de matrícula, declaração comprovativa de ligação à UC, etc.).',
  142: 'Indicar comprovativo',
  143: 'Conta',
  144: 'Alterar fotografia',
  145: 'Dados pessoais',
  146: 'Dados de contacto',
  147: 'Aceitação de Política de Dados Pessoais',
  '147b': 'Declaro que li e aceito as condições de aceitação e política de dados pessoais',
  148: 'Partilha de dados com App Santander',
  149: 'Fatura eletrónica',
  150: 'Fatura como consumidor final',
  151: 'Escolha esta opção caso pretenda que as suas faturas sejam remetidas como Consumidor Final.',
  152: 'Nome completo',
  153: 'Nome de apresentação',
  154: 'NIF',
  155: 'País do NIF',
  156: 'Morada',
  157: 'Código postal',
  158: 'Localidade / Cidade',
  159: 'Nacionalidade',
  160: 'N.º de telefone / telemóvel',
  161: 'Your personal data was updated',
  162: 'Data de nascimento',
  163: 'Género',
  164: 'Masculino',
  165: 'Feminino',
  166: 'Notificações',
  167: 'Notificação',
  168: 'Opções',
  169: 'Nova',
  170: 'Marcar todas como lidas',
  171: 'Marcar como lida',
  172: 'Lida',
  173: 'Sem notificações para mostrar',
  174: 'Voltar à página anterior',
  175: 'Fechar',
  176: 'Guardar',
  177: 'Gerar',
  178: 'Ocultar',
  179: 'Estado',
  180: 'Selecionar',
  181: 'Infelizmente o documento que selecionou não existe.',
  182: 'A sessão expirou.',
  183: 'Ocorreu um erro na ligação ao servidor.',
  184: 'Tem a certeza que pretende terminar a sessão neste dispositivo?',
  185: 'a carregar',
  186: 'A fotografia é demasiado grande. Por favor, envie um tamanho mais reduzido.',
  187: 'A sua fotografia foi atualizada.',
  188: 'hoje',
  189: 'amanhã',
  190: 'dentro de',
  191: 'dias',
  192: 'Por tipo',
  193: 'Pessoal Docente',
  194: 'Investigadores',
  195: 'Pessoal Não Docente',
  196: 'Dirigentes',
  197: 'Experimente no seu telefone',
  198: 'Preparámos cuidadosamente a nossa plataforma para uma ótima experiência de utilização no seu smartphone. Através da utilização de uma nova tecnologia, esta plataforma web pretende comportar-se de forma semelhante a uma aplicação nativa.',
  199: 'Como instalar',
  200: 'Procure a opção',
  201: 'Adicionar ao Ecrã principal',
  202: 'no seu browser, disponível em',
  203: 'no seu Android (Google) ou na opção',
  204: 'Partilhar',
  205: 'no seu dispositivo iOS (Apple)',
  206: 'Texto introdutório sobre a app e os serviços associados. Lorem ipsum dolor sit amet, consectetur adipiscing elit...',
  207: 'Antes de começar...',
  208: 'Algumas instruções ou normas importantes apresentar. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  209: 'Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
  210: 'Por fim...',
  211: 'Algumas informações para concluir a apresentação (apresentada uma vez por dispositivo).',
  212: 'Entrar na aplicação',

  213: 'Condições de aceitação',
  214: 'Declaro que li e tomei conhecimento da Política de Privacidade e de Proteção de Dados da Universidade de Coimbra (UC), disponível na página web e mais declaro que tomei conhecimento, que:',
  215: 'A finalidade da recolha dos meus dados pessoais pela UC, no presente contexto, destina-se à minha identificação e credenciação como utilizador habituado a utilizar as funcionalidades da plataforma onde decorrerá a tramitação;',
  216: 'Os dados pessoais solicitados, nome, e-mail, NIF, género, morada, data de nascimento, nacionalidade e nº de telemóvel são os dados considerados essenciais para a organização administrativa e técnica da plataforma;',
  217: 'O prazo de conservação de dados pessoais é o que estiver fixado por norma legal ou regulamentar;',
  218: 'Na qualidade de titular dos dados, em conformidade com a Lei, tenho os direitos de acesso e de retificação, podendo estes ser exercidos, por escrito, mediante comunicação por correio eletrónico para',
  219: 'O Encarregado de Proteção de Dados da UC pode ser contactado pelo email',
  220: 'Declaro que li e tomei conhecimento da Política de Privacidade',
  221: 'Aceitar e Registar Conta',

  222: 'Erro na identificação do utente origem/destino',
  223: 'Pretende guardar este utilizador como favorito, para facilitar futuras transferências?',
  224: 'Qual é o nome do favorito?',
  225: 'Nome da pessoa',
  226: 'Tem a certeza que deseja eliminar o favorito?',
  227: 'O favorito foi eliminado',
  228: 'O formulário de registo contém erros de preenchimento.',
  229: 'Carregar',
  230: 'Definir PIN',
  231: 'Confirmar PIN',
  232: 'O PIN não é igual.',
  233: 'Por favor, tente novamente.',
  234: 'Pedido de carregamento enviado',
  235: 'Por favor, confirme os seus movimento para confirmar que o pagamento foi corretamente processado.',
  236: 'Abrir movimentos',
  237: 'Deverá indicar um valor entre',
  238: 'Ocorreu um erro a comunicar com o serviço MBWay.',
  239: 'Tem a certeza?',
  240: 'O número de telefone foi removido',
  241: 'O número de telefone foi definido',
  242: 'e',
  243: 'Defina uma password válida',
  244: 'Defina uma password segura',
  245: 'A password não é igual',
  246: 'Confirme a sua password',
  247: 'Password',
  248: 'Confirmar a password',
  249: 'Voltar ao início',
  250: 'Comprar',
  251: 'Escolha o local',
  252: 'Não existem locais',
  253: 'Escolha a refeição',
  254: 'Não existem refeições',
  255: 'Escolha os produtos',
  256: 'Não existem produtos',
  257: 'Adicionar',
  258: 'Tabuleiro',
  259: 'Não existem produtos no tabuleiro',
  260: 'Total',
  261: 'Pagar',
  262: 'A primeira unidade é cobrada a preço social ({ut}) e as restantes unidades a preço normal ({ot})',
  263: '{qt} unidade(s) a preço normal ({ot})',
  264: 'Não é possível adicionar mais unidades',
  265: 'O seu pagamento foi realizado com sucesso.',
  266: 'A carregar os locais...',

  267: 'Os membros da comunidade UC devem utilizar o endereço de e-mail e respetiva password, para que a aplicação fique associada à conta cartão UC.',
  268: 'Obter novo código',
  269: 'O ficheiro não é válido ou tem tamanho superior a 2MB',
  270: 'A password deverá ter um comprimento mínimo de 7 carateres dos quais, no mínimo: 1 algarismo, 1 letra maiúscula, 1 letra minúscula, e 1 carater especial.',
  271: 'Declaro de li e tomei conhecimento das regras constantes do <a class="has-text-weight-bold has-text-primary" href="https://www.uc.pt/regulamentos/sasuc/regulamentos/Reg_499_2020_26_05_Regulamento_geral_utilizacao_conta_cartao_sasuc" target="_blank">Regulamento Geral</a> de Utilização da Conta Cartão SASUC.',
  272: 'A operação de compra é irreversível. Após efetuar o pagamento não é possível o cancelamento da operação.',
  273: 'Tem a certeza que deseja efetuar a compra? Após efetuar o pagamento não é possível o cancelamento da operação. As compras só são válidas para o próprio dia.',
  274: 'Ementa por dia',
  275: 'Próximos 7 dias',
  276: 'Almoço', // do not change
  277: 'Ementa',
  278: 'Cód. País',
  279: 'Nº Funcionário, Estudante ou Externo',
  280: 'As compras só são válidas para o próprio dia.',
  281: 'Alterar password',
  282: 'Nova password',
  283: 'Confirmar nova password',
  284: 'Alterar password da conta',
  285: 'Password alterada. Por favor, autentique-se com os novos dados.',
  286: 'Para alterar a sua password, precisará de um código de autorização. Para emitir um novo e receber na sua caixa de correio, clique no botão seguinte.',
  287: 'Enviar novo código',
  288: 'Ocorreu um erro ao tentar realizar a compra.',
  289: 'PIN inválido',
  290: 'É necessário registar um novo PIN. Por favor, inicie sessão.',
  291: 'É necessário alterar a palavra-passe.',
  292: 'Para consulta de informação sobre a aplicação, <br /> aceda a',
  293: 'Em caso de dificuldade, <br />contacte os serviços de suporte através do e-mail',
  294: 'Consultar',
  295: 'Ajuda',
  296: 'Lavandarias',
  297: 'Gerar código',
  298: 'Introduzir o código no quiosque da lavandaria self-service, em conformidade com as instruções disponibilizadas.',
  299: 'Gerar novo código',
  300: 'Para utilização das lavandarias self-service, sem necessidade de cartão físico.',
  301: 'O código expirará dentro de',
  302: 'QR Code com tempo limite de validade. Clique no QR Code para o renovar',
  uc_share: 'UCShare',
  give_away: 'Doar refeição',
  donate_description:
    'Com a UCShare, pode doar refeições a quem mais precisa. As refeições doadas serão utilizadas por estudantes da UC que necessitem.',
  cant_donate: 'Não é possível doar refeições neste momento.',
  donate: 'Doar',
  donate_meal: 'Doar refeição',
  donate_meals: 'Doar refeições',
  receive_meal: 'Receber refeição',
  receive_meal_description:
    'Se é elegível, pode receber refeições doadas pela comunidade UC. As refeições são gratuitas e só podem ser utilizadas no dia em que as recebe. Verifique se é elegível.',
  receive_meal_today: 'Receber refeição hoje',
  uc_share_authorize:
    'Autoriza a partilha de dados com a DGES para verificar elegibilidade para receber refeições? Pode demorar até 24h para verificarmos eligibilidade.',
  confirm: 'Confirmar',
  cancel: 'Cancelar',
  ok: 'Ok',
  uc_share_not_eligible: 'Não é elegível para receber refeições.',
  uc_share_eligible: 'É elegível para receber refeições doadas.',
  cant_find_dges: 'Erro interno: Não foi possível encontrar informação da organização DGES.',
  uc_share_authorize_success:
    'Autorização para partilha de dados com a DGES efetuada com sucesso. Aguarde até 24h para verificarmos elegibilidade.',
  verify_eligibility: 'Verificar elegibilidade',
  uc_share_confirm: 'Confirma que pretende receber uma refeição? Tem até à meia-noite do dia de hoje para a utilizar.',
  uc_share_success:
    'Refeição recebida com sucesso. Pode encontrar o ticket da sua refeição na página inicial da aplicação.',
  donation: 'Doação',
  meal_donation: 'Doação de refeições',
  meal_donation_description_1: 'Cada refeição tem o valor de ',
  meal_donation_description_2: ' e pode doar entre ',
  meal_donation_description_3: ' e ',
  meal_donation_description_4: ' refeições.',
  remove: 'Remover',
}

export default pt
