<template>
  <div>
    <b-modal
      :active.sync="isActive"
      scroll="keep"
      has-modal-card
      full-screen
      trap-focus
      aria-role="dialog"
      aria-modal
      :can-cancel="false"
    >
      <div class="modal-card" style="width: auto">
        <section class="modal-card-body">
          <div>
            <h2 class="is-size-1 has-margin-top has-margin-bottom-medium has-text-centered">
              {{ $t(213) }}
            </h2>
            <b-field>
              <div class="has-text-small content">
                {{ $t(214) }}
                <ol type="a">
                  <li>
                    {{ $t(215) }}
                  </li>
                  <li>
                    {{ $t(216) }}
                  </li>
                  <li>
                    {{ $t(217) }}
                  </li>
                  <li>
                    {{ $t(218) }}
                    <a href="equipargpd@sas.uc.pt">equipargpd@sas.uc.pt</a>;
                  </li>
                  <li>
                    {{ $t(219) }}
                    <a href="mailto:epd@uc.pt">epd@uc.pt</a>.
                  </li>
                </ol>
              </div>
            </b-field>
            <b-field v-if="userInfo">
              <ul>
                <li class="has-margin-top-medium">
                  <p class="is-flex is-flex-space-between">
                    <span class="has-margin-right-small">{{ $t('147b') }}</span>
                    <b-switch v-model="userInfo.data_cons_rgpd" class="is-marginless"></b-switch>
                  </p>
                  <p class="is-flex is-flex-space-between has-margin-top-medium">
                    <span class="has-margin-right-small" v-html="$t('271')"></span>
                    <b-switch v-model="acceptRules" class="is-marginless"></b-switch>
                  </p>
                </li>
                <li class="has-margin-top-large is-meta">
                  Preferências
                </li>
                <li class="has-margin-top-medium">
                  <p class="is-flex is-flex-space-between">
                    <span class="has-margin-right-small">{{ $t('149') }}</span>
                    <b-switch v-model="userInfo.data_fat_eletronica" class="is-marginless"></b-switch>
                  </p>
                </li>
                <li class="has-margin-top">
                  <p class="is-flex is-flex-space-between">
                    <span class="has-margin-right-small">{{ $t('150') }}</span>
                    <b-switch v-model="userInfo.data_faturas_consumidor_final" class="is-marginless"></b-switch>
                  </p>
                </li>
                <li class="has-margin-top">
                  <p class="is-flex is-flex-space-between">
                    <span class="has-margin-right-small">{{ $t('148') }}</span>
                    <b-switch v-model="userInfo.data_perm_santander" class="is-marginless"></b-switch>
                  </p>
                </li>
              </ul>
            </b-field>
            <b-field class="has-margin-top-large">
              <b-button
                type="is-primary"
                :disabled="!userInfo.data_cons_rgpd || !acceptRules"
                expanded
                @click="doContinue"
                >Continuar</b-button
              >
            </b-field>
          </div>
        </section>
      </div>
    </b-modal>
  </div>
</template>

<script>
import dayjs from 'dayjs'

export default {
  props: {
    show: Boolean,
    user: {
      type: Object,
    },
  },

  data() {
    return {
      loading: false,
      acceptRules: false,
      isActive: this.show || false,
      today: dayjs().format('YYYY-MM-DD'),
      userInfo: {
        data_cons_rgpd: this.user ? this.user.data_cons_rgpd : false,
        data_regulamento: this.user ? this.user.data_cons_rgpd : false,
        data_perm_santander: this.user ? this.user.data_perm_santander : false,
        data_faturas_consumidor_final: this.user ? this.user.data_faturas_consumidor_final : false,
        data_fat_eletronica: this.user ? this.user.data_fat_eletronica : true,
      },
    }
  },

  watch: {
    show(value) {
      this.isActive = value
      this.acceptRules = false
    },
  },

  methods: {
    doContinue() {
      Object.keys(this.userInfo).forEach(key => {
        if (this.userInfo[key]) this.userInfo[key] = this.today
      })

      this.$emit('close')
      this.$emit('accepted', this.userInfo)
      this.isActive = false
    },
  },
}
</script>
