<template>
  <div class="default-layout" :class="{ 'offcanvas-open': $store.state.showOffcanvas }">
    <Header
      v-if="hasHeader"
      :has-back-to="hasBackTo"
      :back-to="backTo"
      :back-to-history="backToHistory"
      :title="title"
      :short-title="shortTitle"
      :has-notification-icon="hasNotificationIcon"
    >
      <template #mobile-right>
        <slot name="header-mobile-right"></slot>
      </template>
    </Header>
    <div v-if="!isIdle" class="main">
      <div class="inner">
        <div v-if="hasMenu" class="main-menu">
          <div class="inner">
            <div class="menu">
              <BackButton v-if="backTo" :to="backTo" :label="backToLabel || $t(249)" />

              <p class="menu-label">
                {{ $t('28') }}
              </p>
              <ul class="menu-list">
                <li>
                  <a @click.prevent="goToOffCanvas('/')">{{ $t('24') }}</a>
                </li>
                <li>
                  <a @click.prevent="goToOffCanvas('/buy')">{{ $t('250') }}</a>
                </li>
                <li>
                  <a @click.prevent="goToOffCanvas('/recharge')">{{ $t('26') }}</a>
                </li>
                <li>
                  <a @click.prevent="goToOffCanvas('/transfer')">{{ $t('25') }}</a>
                </li>
                <li>
                  <a @click.prevent="goToOffCanvas('/laundry')">{{ $t('296') }}</a>
                </li>
                <li>
                  <a @click.prevent="goToOffCanvas('/donate')">{{ $t('uc_share') }}</a>
                </li>
                <li>
                  <a @click.prevent="goToOffCanvas('/account')">{{ $t('27') }}</a>
                </li>
                <li>
                  <a @click.prevent="goToOffCanvas('/help')">{{ $t('295') }}</a>
                </li>
              </ul>
              <p class="menu-label">
                {{ $t('50') }}
              </p>
              <ul class="menu-list">
                <li>
                  <a @click.prevent="doLogout">{{ $t('51') }}</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="main-content">
          <div class="inner">
            <slot name="breadcrumb"></slot>
            <div v-if="title && hasTitle" class="panel page-head">
              <h1 class="page-title">
                {{ title }}
              </h1>
              <p v-if="subtitle" class="subtitle has-text-weight-normal">
                {{ subtitle }}
              </p>
            </div>
            <slot name="content"></slot>
          </div>
        </div>
        <div v-if="hasSidebar" class="main-sidebar">
          <div class="inner">
            <slot name="sidebar"></slot>
          </div>
        </div>
      </div>
    </div>
    <HomeToolbar />
    <slot name="modals"></slot>
    <div class="offcanvas" :class="{ 'is-active': $store.state.showOffcanvas }">
      <div class="inner">
        <div class="brand">
          <div class="logo-sasuc">
            <img :src="assetsPath + 'img/logo-sasuc.png'" />
          </div>
          <div class="app-icon">
            <img :src="assetsPath + 'img/logo-app.png'" />
          </div>
        </div>
        <div class="menu">
          <p class="menu-label">
            {{ $t('28') }}
          </p>
          <ul class="menu-list">
            <li>
              <a @click.prevent="goToOffCanvas('/')">{{ $t('24') }}</a>
            </li>
            <li>
              <a @click.prevent="goToOffCanvas('/buy')">{{ $t('250') }}</a>
            </li>
            <li>
              <a @click.prevent="goToOffCanvas('/recharge')">{{ $t('26') }}</a>
            </li>
            <li>
              <a @click.prevent="goToOffCanvas('/transfer')">{{ $t('25') }}</a>
            </li>
            <li>
              <a @click.prevent="goToOffCanvas('/laundry')">{{ $t('296') }}</a>
            </li>
            <li>
              <a @click.prevent="goToOffCanvas('/donate')">{{ $t('uc_share') }}</a>
            </li>
            <li>
              <a @click.prevent="goToOffCanvas('/account')">{{ $t('27') }}</a>
            </li>
            <li>
              <a @click.prevent="goToOffCanvas('/help')">{{ $t('295') }}</a>
            </li>
          </ul>
          <p class="menu-label">
            {{ $t('50') }}
          </p>
          <ul class="menu-list">
            <li>
              <a @click.prevent="doLogout">{{ $t('51') }}</a>
            </li>
          </ul>
        </div>
      </div>
      <div class="outside" @click="closeOffCanvas"></div>
    </div>
  </div>
</template>

<script>
import BackButton from '@/components/utilities/BackButton'
import Header from '@/components/Header'
import HomeToolbar from '@/components/toolbars/HomeToolbar'

export default {
  components: {
    BackButton,
    Header,
    HomeToolbar,
  },
  props: {
    backToLabel: {
      default: null,
      type: String,
    },
    backTo: {
      default: null,
      type: String,
    },
    hasBackTo: {
      default: true,
      type: Boolean,
    },
    backToHistory: {
      default: false,
      type: Boolean,
    },
    title: {
      default: null,
      type: String,
    },
    shortTitle: {
      default: null,
      type: String,
    },
    subtitle: {
      default: null,
      type: String,
    },
    hasTitle: {
      default: true,
      type: Boolean,
    },
    hasHeader: {
      default: true,
      type: Boolean,
    },
    hasMenu: {
      default: true,
      type: Boolean,
    },
    hasSidebar: {
      default: true,
      type: Boolean,
    },
    hasNotificationIcon: {
      default: true,
      type: Boolean,
    },
  },
  data() {
    return {
      assetsPath: process.env.VUE_APP_ROUTER_BASE,
    }
  },
  computed: {
    isIdle() {
      if (this.$store.state.idleVue.isIdle) {
        this.doLogout()
        return true
      }
      return false
    },
  },
  methods: {
    async doLogout() {
      this.$store.dispatch('clearCart')
      this.$store.dispatch('clearBreadcrumb')
      await this.$store.dispatch('remoteLogout') //Added (wait before deleting cookies locally)
      this.$store.dispatch('logout')
      this.$router.push('/login/pin')
    },
    goToOffCanvas(path) {
      this.$store.state.showOffcanvas = false
      if (this.$router.currentRoute.path != path) {
        this.$router.push(path)
      }
    },
    closeOffCanvas() {
      this.$store.state.showOffcanvas = false
    },
  },
}
</script>
