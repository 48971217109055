<template>
  <div class="record" :class="{ disabled: item.anulado != 'F' }">
    <div class="record-icon">
      <icon v-if="item.tipo_mov == 'C'" name="icon-euro-plus" class="has-text-primary"></icon>
      <icon v-else name="icon-euro-minus" class="has-text-danger has-text-weight-medium"></icon>
    </div>
    <div class="record-content">
      <div class="record-title is-flex is-flex-space-between">
        <span class="has-margin-right has-text-weight-medium">
          {{ item.descricao }}
          <span v-if="item.descricao" class="has-text-small"><br />{{ item.info_adic }}</span>
        </span>
        <span class="has-text-weight-bold" :class="{ 'has-text-danger': item.tipo_mov == 'D' }">
          {{ item.tipo_mov == 'C' ? '+' : '-' }}{{ item.valor | currency }}
        </span>
      </div>
      <div class="record-meta is-flex is-flex-space-between">
        <div>{{ item.data }}</div>
        <div>{{ item.saldo | currency }}</div>
      </div>
      <div v-if="item.id_doc || item.anulado != 'F'" class="record-footer has-margin-top-small">
        <div v-if="item.id_doc">
          <a :href="itemFileUrl" class="is-small" @click.stop.prevent="downloadDocument">
            <faicon icon="file-download"></faicon> Download {{ item.id_doc }}
          </a>
        </div>
        <div v-if="item.anulado != 'F'" class="has-text-weight-bold">Anulado</div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseService from '@/services/BaseService'

export default {
  props: {
    item: {
      type: Object,
    },
  },
  computed: {
    itemFileUrl() {
      return this.item.id_doc ? BaseService.helpers.getMovementFileUrl(this.item.id_doc) : null
    },
  },
  methods: {
    downloadDocument() {
      BaseService.getMovementFile(this.item.id_doc)
    },
  },
}
</script>
