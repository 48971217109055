<template>
  <div class="error-layout">
    <div class="main">
      <div class="inner">
        <div class="main-content">
          <div class="inner">
            <slot name="content"></slot>
          </div>
        </div>
        <div class="main-footer">
          <FooterCopyright />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FooterCopyright from '@/components/FooterCopyright'

export default {
  components: {
    FooterCopyright,
  },
}
</script>
